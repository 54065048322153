import { createSelectItemContext } from '@/context/SelectComponent/Base';
import {
  Excises,
  GetExcisesResponse,
  getGetExcisesQueryKey,
  useGetExcisesInfinite,
} from 'ap-openapi';
import {
  FC,
  PropsWithChildren,
  createContext,
  memo,
  useContext,
  useEffect,
  useState,
} from 'react';
import {
  ReversedMappingType,
  convertToReductionTaxRate,
} from './hooks/convertToReductionTaxRate';

export const {
  useFetchPer: useExcisePer,
  useItems: useExcises,
  useSetItems: useSetExcises,
  useCacheKey: useExciseCacheKey,
  useIsLoading: useIsLoadingExcises,
  useIsFetching: useIsFetchingExcises,
  useFindById: useFindExciseById,
  useFindByName: useFindExciseByName,
  Provider: BaseProvider,
} = createSelectItemContext<
  Excises,
  GetExcisesResponse,
  'id',
  'long_name',
  'long_name',
  'id'
>(
  100,
  useGetExcisesInfinite,
  'excises',
  'id',
  {
    key: 'id',
    return: 'long_name',
    defaultVal: '',
  },
  {
    key: 'long_name',
    return: 'id',
    defaultVal: '',
  },
  getGetExcisesQueryKey
);

const reversedMappingContext = createContext<ReversedMappingType>({});
const ReversedMappingProvider: FC<PropsWithChildren> = memo(({ children }) => {
  const isLoading = useIsLoadingExcises();
  const items = useExcises();
  const [mappings, setMappings] = useState<ReversedMappingType>({});
  useEffect(() => {
    if (!isLoading) {
      setMappings(convertToReductionTaxRate(items));
    }
  }, [isLoading, items, setMappings]);
  return (
    <reversedMappingContext.Provider value={mappings}>
      {children}
    </reversedMappingContext.Provider>
  );
});
ReversedMappingProvider.displayName = 'ReversedMappingProvider';

export const ExciseProvider: FC<PropsWithChildren> = memo(({ children }) => {
  return (
    <BaseProvider>
      <ReversedMappingProvider>{children}</ReversedMappingProvider>
    </BaseProvider>
  );
});

ExciseProvider.displayName = 'ExciseProvider';
export const useReversedMappingContext = () =>
  useContext(reversedMappingContext);
