import { LoginInfo } from '@/components/LoginInfo/type';
import { useLoginInfoSuspense } from '@/context/services/graphql/login/login.service';
import { useRollbarPerson } from '@rollbar/react';
import { DATE_FORMAT_WITH_NO_SLASH, format } from 'date-util';
import { LocaleProvider } from 'i18n';
import {
  FC,
  PropsWithChildren,
  Suspense,
  createContext,
  memo,
  useContext,
  useMemo,
} from 'react';

const context = createContext<LoginInfo>({});

const InnerLoginInfoProvider: FC<PropsWithChildren> = memo(({ children }) => {
  const { currentUser, currentOffice, currentOfficeMember } =
    useLoginInfoSuspense();
  const info: LoginInfo = useMemo(() => {
    return {
      currentUser: currentUser
        ? {
            id: currentUser.id,
            identificationCode: currentUser.identificationCode,
          }
        : undefined,
      currentOffice: currentOffice
        ? {
            id: currentOffice.id,
            identificationCode: currentOffice.identificationCode,
            isActive: currentOffice.isActive,
            officeName: currentOffice.officeName,
            isMoreThanTeamPlan: currentOffice.isMoreThanTeamPlan,
            createdAt: format(
              currentOffice.createdAt,
              DATE_FORMAT_WITH_NO_SLASH
            ),
            eDoc: {
              isEnabled: currentOffice.eDocPlan.isEnabled,
              isReceiptTypeEnabled: currentOffice.eDocPlan.isReceiptTypeEnabled,
            },
          }
        : undefined,
      currentOfficeMember: currentOfficeMember
        ? {
            id: currentOfficeMember.id,
            identificationCode: currentOfficeMember.identificationCode,
            idType: currentOfficeMember.idType,
            userName: currentOfficeMember.name,
            number: currentOfficeMember.number,
            officeMemberSetting: {
              id: currentOfficeMember.officeMemberSetting?.id,
              role: currentOfficeMember.officeMemberSetting?.role,
            },
          }
        : undefined,
    };
  }, [currentOffice, currentOfficeMember, currentUser]);
  // const lang = useMemo(() => {
  //   if (activeLanguage === 'en') {
  //     return 'en';
  //   }
  //   return 'ja';
  // }, [activeLanguage]);
  return (
    <LocaleProvider value='ja'>
      <context.Provider value={info}>
        <ErrorTrackingPerson>{children}</ErrorTrackingPerson>
      </context.Provider>
    </LocaleProvider>
  );
});
InnerLoginInfoProvider.displayName = 'InnerLoginInfoProvider';

export const LoginInfoProvider: FC<PropsWithChildren> = memo(({ children }) => {
  return (
    <Suspense fallback={<></>}>
      <InnerLoginInfoProvider>{children}</InnerLoginInfoProvider>
    </Suspense>
  );
});
LoginInfoProvider.displayName = 'InnerLoginInfoProvider';

export const useLoginInfo = () => useContext(context);

const ErrorTrackingPerson: FC<PropsWithChildren> = memo(({ children }) => {
  const info = useLoginInfo();
  const errorTrackingPerson = useMemo(
    () => ({
      user: {
        ...info.currentUser,
      },
      office: {
        id: info.currentOffice?.id,
        identificationCode: info.currentOffice?.identificationCode,
      },
      officeMember: {
        id: info.currentOfficeMember?.id,
        identificationCode: info.currentOfficeMember?.identificationCode,
        idType: info.currentOfficeMember?.idType,
        number: info.currentOfficeMember?.number,
      },
    }),
    [
      info.currentOffice?.id,
      info.currentOffice?.identificationCode,
      info.currentOfficeMember?.id,
      info.currentOfficeMember?.idType,
      info.currentOfficeMember?.identificationCode,
      info.currentOfficeMember?.number,
      info.currentUser,
    ]
  );
  useRollbarPerson(errorTrackingPerson);
  return children;
});
ErrorTrackingPerson.displayName = 'ErrorTrackingPerson';
