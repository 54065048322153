import {
  useCrSubItemCacheKey,
  useCrSubItemPer,
  useCrSubItems,
} from '@/components/CrSubItemsSelect/Provider';
import { useModelsToOptionsWithData } from '@/hooks/useTypeToOptions';
import { useTranslation } from '@/i18n';
import { getPageData } from '@/utils/getPageData';
import {
  FormSelect,
  FormSelectProps,
  SelectProps,
} from '@moneyforward/ap-frontend-components';
import {
  CrItems as CrItemsModel,
  useGetCrSubItemsByCrItemSuspenseInfinite,
} from 'ap-openapi';
import { memo, startTransition, useCallback, useMemo, useState } from 'react';
import { FieldValues } from 'react-hook-form';

type Options = {
  label: string;
  value: string;
};

export type Props<TFieldValues extends FieldValues> = {
  selectProps?: Omit<
    FormSelectProps<TFieldValues>['selectProps'],
    'options' | 'comboBox'
  >;
  query?: string;
  crItemId?: string;
  per?: number;
  validateMaxCount?: number;
  defaultOptions?: Options[];
  comboBox?: boolean;
} & Omit<FormSelectProps<TFieldValues>, 'selectProps' | 'label'>;

export const _CrSubItemsSelect = <TFieldValues extends FieldValues>({
  selectProps: _selectProps,
  defaultOptions: _defaultOptions,
  crItemId,
  per = 25,
  comboBox = true,
  ...rest
}: Props<TFieldValues>) => {
  const defaultOptions = useMemo(
    () => _defaultOptions ?? [],
    [_defaultOptions]
  );
  const { t } = useTranslation();
  const [query, setQuery] = useState<string | undefined>(undefined);

  const ctxData = useCrSubItems();
  const ctxPer = useCrSubItemPer();
  const ctxCacheKey = useCrSubItemCacheKey(query);
  const {
    data: apiData,
    isLoading,
    hasNextPage,
    fetchNextPage,
  } = useGetCrSubItemsByCrItemSuspenseInfinite(
    {
      query,
      cr_item_id: crItemId,
      per: ctxPer ?? per,
    },
    {
      query: {
        getNextPageParam: (page) => {
          const url = new URL(page.data.next ?? '/', location.href);
          if (!url.searchParams.has('page')) return undefined;
          return Number(url.searchParams.get('page'));
        },
        queryKey: ctxCacheKey,
      },
    }
  );
  const data = useMemo(
    () => [...(!query ? ctxData : []), ...getPageData(apiData, 'cr_sub_items')],
    [apiData, ctxData, query]
  );
  const options = useModelsToOptionsWithData<CrItemsModel>(
    data,
    query,
    defaultOptions
  );
  const fetchMore = useCallback(
    async (callback: () => void) => {
      if (hasNextPage) {
        await fetchNextPage();
      }
      callback();
    },
    [fetchNextPage, hasNextPage]
  );
  const onSearch = useCallback((search: string) => {
    startTransition(() => {
      setQuery(search);
    });
  }, []);
  const selectProps: SelectProps = useMemo(
    () => ({
      onSearchDebounceWait: 600,
      comboBox: comboBox,
      placeholder: t('cr_sub_items_placeholder'),
      inputPlaceholder: t('cr_sub_items_input_placeholder'),
      ..._selectProps,
      options: options,
      loading: isLoading,
      onSearch: onSearch,
      fetchMore: fetchMore,
      mode: 'single',
      selectAll: undefined,
      selectAllLabel: undefined,
    }),
    [comboBox, t, _selectProps, options, isLoading, onSearch, fetchMore]
  );

  return <FormSelect selectProps={selectProps} {...rest} />;
};
_CrSubItemsSelect.displayName = '_CrSubItemsSelect';

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export const CrSubItemsSelect = memo((props) => (
  <_CrSubItemsSelect {...props} />
)) as typeof _CrSubItemsSelect;
CrSubItemsSelect.displayName = 'CrSubItemsSelect';
