import { useTranslation } from '@/i18n';
import {
  FormCheckboxGroup,
  FormCheckboxGroupProps,
} from '@moneyforward/ap-frontend-components';
import classnames from 'classnames/bind';
import { ReactNode, memo, useMemo } from 'react';
import { FieldValues } from 'react-hook-form';
import styles from './CheckboxWithOtherInput.module.scss';

const cx = classnames.bind(styles);

export type Props<TFieldValues extends FieldValues> = {
  required: boolean;
  inputId?: string;
  otherInput?: ReactNode;
  isOtherInputChecked?: boolean;
} & FormCheckboxGroupProps<TFieldValues>;

const _CheckboxWithOtherInput = <TFieldValues extends FieldValues>({
  options,
  otherInput,
  required,
  disabled,
  ...rest
}: Props<TFieldValues>) => {
  const { t } = useTranslation();
  const rules = useMemo(() => {
    return {
      required: !disabled && required ? t('required') : false,
    };
  }, [disabled, required, t]);

  return (
    <div className={cx(styles['any-select-wrapper'])}>
      <FormCheckboxGroup<TFieldValues>
        {...rest}
        vertical
        rules={rules}
        name={rest.name}
        control={rest.control}
        options={options}
        disabled={disabled}
      />
      {otherInput}
    </div>
  );
};
_CheckboxWithOtherInput.displayName = '_CheckboxWithOtherInput';

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export const CheckboxWithOtherInput = memo((props) => (
  <_CheckboxWithOtherInput {...props} />
)) as typeof _CheckboxWithOtherInput;
CheckboxWithOtherInput.displayName = 'CheckboxWithOtherInput';
