import { useCallback, useMemo, useState } from 'react';

export type EditFile = {
  default: boolean;
  change: boolean;
};

type HandleChangeFile<T = File> = (value: T | null) => void;

type UseChangeFileReturn<T = File> = {
  isEditFile: EditFile;
  defaultFile: T | null;
  file: T | null;
  handleRestoreDefaultFile: VoidFunction;
  handleDeleteDefaultFile: VoidFunction;
  handleChangeFile: HandleChangeFile<T>;
};

export const useChangeFile = <T = File>(
  defaultValue: T | null,
  onChange: (value: T | null) => void
): UseChangeFileReturn<T> => {
  const [changeFile, setChangeFile] = useState(false);
  const [defaultFile, setDefaultFile] = useState<T | null>(defaultValue);
  const [file, setFile] = useState<T | null>(defaultValue);
  const handleChangeFile = useCallback(
    (value: T | null) => {
      setChangeFile(true);
      setFile(value);
      onChange(value);
    },
    [onChange]
  );
  const handleRestoreDefaultFile = useCallback(() => {
    setDefaultFile(defaultValue);
    handleChangeFile(defaultValue);
    setChangeFile(false);
  }, [defaultValue, handleChangeFile]);
  const handleDeleteDefaultFile = useCallback(() => {
    setDefaultFile(null);
    handleChangeFile(null);
  }, [handleChangeFile]);
  const isEditFile = useMemo(
    () => ({
      default: !changeFile,
      change: changeFile,
    }),
    [changeFile]
  );

  return {
    isEditFile,
    defaultFile,
    file,
    handleRestoreDefaultFile,
    handleDeleteDefaultFile,
    handleChangeFile,
  };
};
