import { typeGuardSelectedInvoice } from '@/components/ReportForm/ApReportFormInputInvoiceFileField/type';
import { useConvertPayeeFormToRequestForm } from '@/context/services/reportsType/invoiceReports/useConvertApiDataAndPayeeForm';
import { useConvertSpecialExceptionToRequestForm } from '@/context/services/reportsType/invoiceReports/useConvertApiDataAndSpecialExceptionForm';
import { useInvoiceFormTransformers } from '@/context/services/reportsType/invoiceReports/useInvoiceFormTransformers';
import { isSuccess } from '@/context/services/type';
import { PaymentRequestForm } from '@/features/InvoiceReport/Edit/type';
import { useTranslation } from '@/i18n';
import { isCommonError } from '@/libs/typeguard/isError';
import {
  CommonError,
  CustomUpdateReportRequest,
  FormInputIdMap,
  InvoiceReportDetailFormInputsResponse,
  useBulkUpdateInvoiceTransactions,
  useGetInvoiceReportSuspense,
  useGetInvoiceTransactionsSuspense,
  useUpdateReport,
} from 'ap-openapi';
import { useCallback } from 'react';

export type InvoiceReportStatus = [];

export const useGetInvoiceReport = (invoiceReportsId: string) => {
  const { data, error } = useGetInvoiceReportSuspense(invoiceReportsId);
  if (error) throw error;
  return data.data;
};

export const useGetInvoiceTransaction = (invoiceReportsId: string) => {
  const { data, error } = useGetInvoiceTransactionsSuspense(invoiceReportsId);
  if (error) throw error;
  return data.data?.invoice_transactions ?? [];
};

type TUpdateReportMutateAsync = ReturnType<
  typeof useUpdateReport
>['mutateAsync'];

type UpdateInvoiceReportMutateArgs = Omit<
  Parameters<TUpdateReportMutateAsync>['0'],
  'data'
> & {
  data: CustomUpdateReportRequest;
};

type UpdateInvoiceReportMutateOption =
  Parameters<TUpdateReportMutateAsync>['1'] & TFetchQueries;
type UpdateReportInvoiceFormSuccess = {
  status: 'success';
};
type UpdateReportInvoiceFormCommonError = {
  status: 'error';
  errors: CommonError;
};
type UpdateReportInvoiceFormResult =
  | UpdateReportInvoiceFormSuccess
  | UpdateReportInvoiceFormCommonError;
const typeGuardUpdateReportRequest = (
  body: unknown
): body is Parameters<TUpdateReportMutateAsync>['0'] => true;

export const useUpdateReportInvoiceForm = () => {
  const { t } = useTranslation();
  const { mutateAsync: _mutateAsync } = useUpdateReport();
  const mutateAsync = useCallback(
    async (
      args: UpdateInvoiceReportMutateArgs,
      options?: UpdateInvoiceReportMutateOption
    ): Promise<UpdateReportInvoiceFormResult> => {
      // NOTE: ファーム変換時に配列情報が失われるのでカスタム型を作成して変換しているのでダミーのタイプガードで騙す
      if (typeGuardUpdateReportRequest(args)) {
        const resp = await _mutateAsync(args, options);
        if (resp.status !== 204) {
          return {
            status: 'error',
            errors: isCommonError(
              // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
              resp?.data as unknown as Record<string, unknown>
            )
              ? // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
                (resp.data as unknown as CommonError)
              : { messages: [t('system_error')] },
          };
        }
      }
      return {
        status: 'success',
      };
    },
    [_mutateAsync, t]
  );
  return { mutateAsync };
};

type TBulkUpdateReportMutateAsync = ReturnType<
  typeof useBulkUpdateInvoiceTransactions
>['mutateAsync'];
type BulkUpdateInvoiceReportMutateArgs =
  Parameters<TBulkUpdateReportMutateAsync>['0'];
type BulkUpdateInvoiceReportMutateOption =
  Parameters<TBulkUpdateReportMutateAsync>['1'] & TFetchQueries;
type BulkUpdateReportInvoiceTransactionsSuccess = {
  status: 'success';
};
type BulkUpdateReportInvoiceTransactionsCommonError = {
  status: 'error';
  errors: CommonError;
};
type BulkUpdateReportInvoiceTransactionsResult =
  | BulkUpdateReportInvoiceTransactionsSuccess
  | BulkUpdateReportInvoiceTransactionsCommonError;
export const useBulkUpdateReportInvoiceTransactions = () => {
  const { t } = useTranslation();
  const { mutateAsync: _mutateAsync } = useBulkUpdateInvoiceTransactions();
  const mutateAsync = useCallback(
    async (
      args: BulkUpdateInvoiceReportMutateArgs,
      options?: BulkUpdateInvoiceReportMutateOption
    ): Promise<BulkUpdateReportInvoiceTransactionsResult> => {
      const resp = await _mutateAsync(args, options);
      if (isSuccess(resp.status)) {
        return {
          status: 'success',
        };
      }
      return {
        status: 'error',
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        errors: isCommonError(resp?.data as unknown as Record<string, unknown>)
          ? // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
            (resp.data as unknown as CommonError)
          : { messages: [t('system_error')] },
      };
    },
    [_mutateAsync, t]
  );
  return { mutateAsync };
};

export const useSubmitInvoiceReport = (
  formInputIdMap: FormInputIdMap,
  invoicePayeeTypeInfo: InvoiceReportDetailFormInputsResponse,
  invoiceFileId: string
) => {
  const { mutateAsync: updateInvoiceReport } = useUpdateReportInvoiceForm();
  const { mutateAsync: bulkUpdateInvoiceTransactions } =
    useBulkUpdateReportInvoiceTransactions();
  const { transformReportForm, transformInvoiceTransactions } =
    useInvoiceFormTransformers(formInputIdMap);
  const convertPayeeFormRequest = useConvertPayeeFormToRequestForm();
  const convertSpecialExceptionFormRequest =
    useConvertSpecialExceptionToRequestForm();
  return useCallback(
    async (values: PaymentRequestForm) => {
      const reportFormData = transformReportForm(values.reportForm);
      const payeeFormData = convertPayeeFormRequest(
        values.payee,
        invoicePayeeTypeInfo,
        Object.values(formInputIdMap).find(
          (input) => input.type === 'ApReportFormInputInvoiceInstantPayee'
        )
      );

      const specialExceptionData = convertSpecialExceptionFormRequest(
        values.specialException
      );
      const reportFormInputValues = [
        ...reportFormData,
        ...payeeFormData,
        ...specialExceptionData,
      ].map((item) => {
        if (item.report_form_input_id === invoiceFileId) {
          if (typeGuardSelectedInvoice(values.invoice_file)) {
            return { ...item, value: values.invoice_file.mfFileId };
          } else if (values.invoice_file instanceof File) {
            return { ...item, file: values.invoice_file, value: undefined };
          } else if (typeof values.invoice_file === 'string') {
            return { ...item, value: values.invoice_file };
          }
        }
        return item;
      });
      const reportFormInputValuesAttributes = reportFormInputValues.sort(
        (a, b) =>
          (formInputIdMap[a.report_form_input_id ?? '']?.priority ?? 0) -
          (formInputIdMap[b.report_form_input_id ?? '']?.priority ?? 0)
      );
      const invoiceReportResponse = await updateInvoiceReport({
        id: values.id,
        data: {
          report: {
            title: values.title,
            report_form_input_values_attributes:
              reportFormInputValuesAttributes,
          },
        },
      });

      const invoiceTransactionResponse = await bulkUpdateInvoiceTransactions({
        id: values.id,
        data: {
          invoice_transactions: transformInvoiceTransactions(
            values.invoiceTransactions,
            values.deleteInvoiceTransactions
          ),
        },
      });
      return { invoiceReportResponse, invoiceTransactionResponse };
    },
    [
      transformReportForm,
      convertPayeeFormRequest,
      convertSpecialExceptionFormRequest,
      updateInvoiceReport,
      bulkUpdateInvoiceTransactions,
      transformInvoiceTransactions,
      invoicePayeeTypeInfo,
      invoiceFileId,
      formInputIdMap,
    ]
  );
};
