import { useTranslation } from '@/i18n';
import classnames from 'classnames/bind';
import { FC, memo } from 'react';
import styles from './reportFormStatus.module.scss';

const cx = classnames.bind(styles);

export type Props = {
  invoiceId?: string;
};

export const ReportFormStatusNumber: FC<Props> = memo(({ invoiceId }) => {
  const { t } = useTranslation();

  if (!invoiceId) {
    return;
  }
  return (
    <div className={cx(styles['report-form-status-text-wrapper'])}>
      <p className={cx(styles['report-form-status-text-label'])}>
        {t('invoice_id')}
      </p>
      <p className={cx(styles['report-form-status-text-value'])}>{invoiceId}</p>
    </div>
  );
});
ReportFormStatusNumber.displayName = 'ReportForm';
