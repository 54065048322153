import { Layout } from '@/Layout';
import { useLoginInfo } from '@/components/LoginInfo/provider';
import { ApReportFormInputTextField } from '@/components/ReportForm';
import { useGetBookDateByPayeeType } from '@/components/ReportForm/ApReportFormInputInvoiceBookDateField/hooks';
import { invoiceUploadEvent } from '@/components/ReportForm/ApReportFormInputInvoiceFileField/hooks/event';
import { ReportFormStatusContainer } from '@/components/ReportForm/ReportFormStatusContainer';
import { ErrorBoundary } from '@/components/Rollbar';
import { useGetEDocPlan } from '@/context/services/e_doc_plan/EDocPlan.service';
import { INVOICE_NUMBER_UNSAVED } from '@/context/services/reportsType/invoiceReports/type';
import { useConvertApiDataToPayeeFormName } from '@/context/services/reportsType/invoiceReports/useConvertApiDataAndPayeeForm';
import { useConvertApiDataToReportForm } from '@/context/services/reportsType/invoiceReports/useConvertApiDataAndReportForm';
import { useConvertApiDataToSpecialExceptionName } from '@/context/services/reportsType/invoiceReports/useConvertApiDataAndSpecialExceptionForm';
import { useFormInputIdMapping } from '@/context/services/reportsType/invoiceReports/useFormInputIdMapping';
import { EDocMetadataProps } from '@/features/InvoiceReport/Components/EDocMetadata';
import {
  LoadingPdfViewer,
  PdfViewer,
} from '@/features/InvoiceReport/Components/PdfViewer/PdfViewer';
import { useInvoiceFileValue } from '@/features/InvoiceReport/Edit/components/Context/API';
import { useIsLoadingWithAllApi } from '@/features/InvoiceReport/Edit/components/Context/ComponentAPI';
import { useVirtualItem } from '@/features/InvoiceReport/Edit/components/Context/VirtualItems';
import {
  useInvoiceTransactionHelper,
  useUpdateInvoiceTransaction,
  useValidationRules,
} from '@/features/InvoiceReport/Edit/components/InvoiceTransaction/Columns/hooks';
import { useCheckEvent } from '@/features/InvoiceReport/Edit/components/InvoiceTransaction/hooks/useCheckEvent';
import { useDetailsSize } from '@/features/InvoiceReport/Edit/components/InvoiceTransaction/hooks/useDetailsSize';
import { WrapReportsForm } from '@/features/InvoiceReport/Edit/components/Loadings';
import { ReportFormInputs } from '@/features/InvoiceReport/Edit/components/ReportFormInputs/ReportFormInputs';
import { useSubmitPaymentReport } from '@/features/InvoiceReport/Edit/components/hooks/hooks';
import {
  useFindInvoiceFileId,
  useFindInvoicePayeeId,
} from '@/features/InvoiceReport/Edit/components/hooks/useFindReportFormInputTypes';
import {
  ApiErrors,
  ErrorFields,
} from '@/features/InvoiceReport/Edit/components/type';
import {
  typeGardeApReportStatus,
  useReportStatusLabel,
} from '@/hooks/invoice_reports/useReportStatusLabel';
import { useDisplayInlineNotification } from '@/hooks/useDisplayInlineNotification';
import {
  useDocumentPictureInPicture,
  useSupportDocumentPictureInPicture,
} from '@/hooks/useDocumentPictureInPicture';
import { useStateRef } from '@/hooks/useStateRef';
import { useTranslation } from '@/i18n';
import yup from '@/libs/validation';
import { isWarn, parseErrorSchema, traverseErrors } from '@/utils/errors';
import { useGlobalContainerRef } from '@/wc/helper/ref';
import {
  ButtonGroup,
  ButtonV2,
  Form,
  IconInfo,
  InlineNotification,
  Loading,
  useBoundingClientRect,
  useWindowResize,
} from '@moneyforward/ap-frontend-components';
import classnames from 'classnames/bind';
import { stringToDayjs } from 'date-util';
import parser from 'html-react-parser';
import {
  CSSProperties,
  FC,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  FieldError,
  FieldErrors,
  FieldPath,
  FormProvider,
  useFieldArray,
  useForm,
} from 'react-hook-form';
import { ValidationError } from 'yup';
import { InvoiceTransaction, PaymentRequestForm } from '../type.d';
import { useDetailValue, useFormValue } from './Context';
import { Details } from './Details';
import { mappingTypeValue, valueOrValues } from './form_value_mappings';
import styles from './index.module.scss';

const cx = classnames.bind(styles);

type Props = {
  invoiceReportsId: string;
};

export const InvoiceReportEdit: FC<Props> = memo(({}) => {
  const { currentOffice } = useLoginInfo();
  const { apReceiptTypeAlertType } = useGetEDocPlan();
  const { t } = useTranslation();
  const apiData = useFormValue();
  const detailData = useDetailValue();
  const convertApiDataToReportForm = useConvertApiDataToReportForm();
  const reportFormInputFormNames: Record<
    string,
    string | string[] | undefined
  > = useMemo(() => {
    return (
      convertApiDataToReportForm(apiData?.report_form_inputs).reduce(
        (prev, cur) => {
          let value = (
            mappingTypeValue[cur.type] ||
            ((cur) =>
              valueOrValues(cur.input_values?.map((item) => item.value)) ??
              cur.default_value ??
              undefined)
          )(cur);
          /**
           * NOTE: 空文字列の場合選択系コンポーネントで空文字で選択されるので明示的に `undefined` をセットする様にする
           * If it is an empty string, it will be selected with an empty string in the selection component, so explicitly set it to `undefined`.
           */
          value = typeof value === 'string' && value === '' ? undefined : value;
          const options = cur.input_options?.map((item) => item.value) ?? [];
          const formValue = (Array.isArray(value) ? value : [value]).filter(
            Boolean
          );
          const isOtherValue =
            (cur.has_other_value &&
              formValue.length > 0 &&
              formValue.some((value) => options.every((v) => v !== value))) ||
            false;
          const hasOtherInput = cur.has_other_value
            ? {
                [`${cur.id}_other`]: isOtherValue ? value : undefined,
                [`${cur.id}_is_other_checked`]: isOtherValue,
              }
            : {};
          return {
            [cur.id]: isOtherValue ? undefined : value,
            ...hasOtherInput,
            ...prev,
          };
        },
        {}
      ) ?? {}
    );
  }, [apiData?.report_form_inputs, convertApiDataToReportForm]);
  const payeeForm = useConvertApiDataToPayeeFormName(
    apiData.report_form_inputs
  );
  const specialExceptionForm = useConvertApiDataToSpecialExceptionName(
    apiData.report_form_inputs
  );
  const invoiceFileValue = useInvoiceFileValue();
  const apiInvoiceFile = useMemo(() => {
    if (apiData.invoice_file && invoiceFileValue) {
      const file = new File([invoiceFileValue], apiData.invoice_file.name!);
      return {
        file,
        mfFileId: apiData.invoice_file.id!,
        invoiceId: '',
      };
    }
    return undefined;
  }, [apiData.invoice_file, invoiceFileValue]);

  const invoiceTransactions = useMemo(
    () =>
      detailData.map((item, idx): InvoiceTransaction => {
        return {
          id: item.id!,
          number: item.number ?? INVOICE_NUMBER_UNSAVED,
          dealDate: stringToDayjs(item.deal_date),
          name: item.name,
          exItemId: item.ex_item?.id,
          unit: item.unit_value,
          quantity: item.quantity,
          totalValue: item.total_value,
          drExciseId: item.dr_excise?.id,
          invoiceKind: item.invoice_kind,
          exciseValue: item.excise_value,
          hasWithholdingIncomeTax: item.has_withholding_income_tax,
          withholdingIncomeTax: item.withholding_income_tax_value,
          memo: item.memo,
          deptId: item.dept?.id,
          projectCodeId: item.project_code?.id,
          currency: item.currency,
          useCustomJPYRate: item.use_custom_jpy_rate,
          jpyRate: item.jpyrate,
          crItemId: item.cr_item?.id,
          crSubItemId: item.cr_sub_item?.id,
          taxIncludedTotalValue: 0,
          taxIncludedUnit: 0,
          rowKey: `invoice-transaction_${idx}`,
        };
      }),
    [detailData]
  );

  const defaultValues = useMemo(
    () => ({
      id: apiData.id,
      title: apiData.title,
      invoice_file: apiInvoiceFile,
      payee: payeeForm,
      specialException: specialExceptionForm,
      reportForm: reportFormInputFormNames,
      invoiceTransactions: invoiceTransactions,
      deleteInvoiceTransactions: [],
    }),
    [
      apiData.id,
      apiData.title,
      apiInvoiceFile,
      invoiceTransactions,
      payeeForm,
      reportFormInputFormNames,
      specialExceptionForm,
    ]
  );
  // TODO: calc canDisplayDrExcise
  const [canDisplayDrExcise] = useState(false);
  const { checked: includeTax, onChange: onChangeIncludeTax } =
    useCheckEvent(false);
  const { checked: showColumnDetails, onChange: onChangeShowColumnDetails } =
    useCheckEvent(false);

  const methods = useForm<PaymentRequestForm>({
    defaultValues: defaultValues,
    mode: 'all',
  });
  const { control, setFocus, watch, setError, resetField, getValues } = methods;

  const container = useRef<HTMLDivElement | null>(null);
  const { fields, append, replace, remove, insert } = useFieldArray<
    PaymentRequestForm,
    'invoiceTransactions'
  >({
    control: control,
    name: 'invoiceTransactions',
  });
  const helper = useInvoiceTransactionHelper(
    includeTax,
    apiData.permit_minus_transaction,
    canDisplayDrExcise,
    false
  );
  const invoiceTransactionsSupporter = useUpdateInvoiceTransaction(helper);
  useEffect(() => {
    const values = invoiceTransactionsSupporter(invoiceTransactions);
    resetField('invoiceTransactions', { defaultValue: values });
  }, [invoiceTransactions, invoiceTransactionsSupporter, resetField]);

  const getInvoiceTransactionIndex = useCallback((name: string) => {
    // invoiceTransactions.index.xxx or invoiceTransactions[index].xxx
    const regexp = /.*(?:\[(?<index1>[0-9].*)\]|\.(?<index2>[0-9].*))\..*/gm;
    const { index1, index2 } = regexp.exec(name)?.groups ?? {};
    try {
      const index = index1 || index2;
      if (index) {
        return parseInt(index);
      }
      return undefined;
    } catch (error) {
      return undefined;
    }
  }, []);
  const { virtualParentRef, rowVirtualizer, handleInvalidScroll } =
    useVirtualItem({ fields, getIndex: getInvoiceTransactionIndex });
  const rules = useValidationRules(
    includeTax,
    apiData.permit_minus_transaction,
    showColumnDetails
  );
  const { yupSchema } = rules;
  const schema: yup.ObjectSchema<{
    invoiceTransactions: Partial<InvoiceTransaction>[];
  }> = useMemo(
    () =>
      yup.object().shape({
        invoiceTransactions: yup.array().of(yupSchema).default([]),
      }),
    [yupSchema]
  );

  const [errors, setErrors] = useState<ApiErrors>({
    invoiceReport: undefined,
    invoiceTransactions: undefined,
    invoiceTransactionsAlert: undefined,
  });
  const handleInlineNotificationErrors = useCallback(
    (
      errFields: ErrorFields | FieldErrors<PaymentRequestForm> | ApiErrors
    ): boolean => {
      const typeGuardApiErrors = (
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        errFields: any
      ): errFields is ApiErrors => {
        return [
          'invoiceReport',
          'invoiceTransactions',
          'invoiceTransactionsAlert',
        ].every((name) => Object.keys(errFields).includes(name));
      };
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const typeGuardErrorField = (value: any): value is FieldError => {
        return 'type' in value && 'message' in value;
      };
      if (typeGuardApiErrors(errFields)) {
        setErrors(errFields);
        return !errFields.invoiceReport || !errFields.invoiceTransactions;
      } else {
        const errMsgs: string[] = [];
        const alertMsgs: string[] = [];
        (function parseErrorFields(
          fields: ErrorFields | FieldErrors<PaymentRequestForm> | ApiErrors
        ) {
          Object.entries(fields).forEach(([, value]) => {
            if (typeGuardErrorField(value)) {
              if (value.message) {
                if (isWarn(value.type)) {
                  alertMsgs.push(value.message);
                } else {
                  errMsgs.push(value.message);
                }
              }
            } else {
              parseErrorFields(value);
            }
          });
        })(errFields);
        setErrors({
          invoiceTransactions: Array.from(new Set(errMsgs)).join('<br />'),
          invoiceTransactionsAlert: Array.from(new Set(alertMsgs)).join(
            '<br />'
          ),
          invoiceReport: undefined,
        });
        return errMsgs.length > 0;
      }
    },
    []
  );
  const onInvalid = useCallback(
    async (errors: FieldErrors<PaymentRequestForm>) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      let err: Record<string, any> = errors;
      try {
        const invoiceTransactions = getValues('invoiceTransactions');
        await schema.validate(
          {
            invoiceTransactions,
          },
          {
            abortEarly: false,
          }
        );
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (e: any) {
        if (!(e instanceof ValidationError)) {
          throw e;
        }
        const errFields = parseErrorSchema(e, true);
        err = {
          ...err,
          ...errFields,
        };
        Object.keys(errFields).forEach((key) => {
          const field = key.split('.').slice(-1)[0];
          if (field && field in err) {
            delete err[field];
          }
        });
      }
      handleInlineNotificationErrors(err);
      const errFields = traverseErrors(errors);
      if (errFields.length > 0 && container.current) {
        const nameControls = container.current.querySelectorAll('[name]');
        const firstErrorControl = Array.from(nameControls).find((item) =>
          errFields.includes(item.getAttribute('name') ?? '')
        );
        if (firstErrorControl) {
          firstErrorControl.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
          });
          // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
          const name = (firstErrorControl.getAttribute('name') ??
            '') as FieldPath<PaymentRequestForm>;
          setFocus(name);
        } else {
          handleInvalidScroll(container.current, errFields[0] ?? '');
        }
      }
    },
    [
      getValues,
      handleInlineNotificationErrors,
      handleInvalidScroll,
      schema,
      setFocus,
    ]
  );

  const { zoom, zoomUpdate, minimum, minimumUpdate, Icon } = useDetailsSize();
  const isPiPSupport = useSupportDocumentPictureInPicture();
  const isDraggable = !isPiPSupport && zoom;
  const { innerHeight } = useWindowResize();
  const detailHeight = !minimum ? innerHeight * 0.36 : 92; // 36%
  const [, setMainContainerRef, wrapMainContainerRef] =
    useStateRef<HTMLDivElement | null>();
  const size = useBoundingClientRect(wrapMainContainerRef);
  const { top: mainTop } = size;
  const mainContainerHeight = innerHeight - (detailHeight + mainTop);
  const mainContainerStyle: CSSProperties = useMemo(
    () => ({ maxHeight: mainContainerHeight, minHeight: mainContainerHeight }),
    [mainContainerHeight]
  );
  const detailContainerStyle: CSSProperties = useMemo(
    () => ({
      minHeight: detailHeight,
      maxHeight: detailHeight,
    }),
    [detailHeight]
  );
  const pdfViewerAreaRef = useRef<HTMLDivElement | null>(null);
  const pdfViewerContainerRef = useRef<HTMLDivElement | null>(null);
  const onPictureInPicture = useDocumentPictureInPicture(pdfViewerContainerRef);
  const isLoading = useIsLoadingWithAllApi();
  const reportStatusLabel = useReportStatusLabel();
  const file = watch('invoice_file');
  const invoiceFile = useMemo(() => {
    if (file) {
      if (file instanceof File) {
        return file;
      }
      return file.file;
    }
    return undefined;
  }, [file]);
  const onChangeFile = useCallback((file: File) => {
    invoiceUploadEvent.Upload(file);
  }, []);
  const onWrapPictureInPicture = useCallback(
    async (value: boolean) => {
      if (invoiceFile) {
        await onPictureInPicture(value);
      }
    },
    [invoiceFile, onPictureInPicture]
  );
  const formInputIdMap = useFormInputIdMapping(apiData.report_form_inputs);
  const invoicePayeeTypeInfo = useFindInvoicePayeeId(
    apiData.report_form_inputs
  );
  const invoiceFileTypeId = useFindInvoiceFileId(apiData.report_form_inputs);
  const bookDate = useGetBookDateByPayeeType({
    control,
    originalBookDate: payeeForm.bookDate,
  });
  const {
    onSubmit,
    onDeleteSubmit,
    DeleteForm,
    ConfirmModal,
    isLoading: isSubmitLoading,
  } = useSubmitPaymentReport(
    schema,
    formInputIdMap,
    invoicePayeeTypeInfo,
    invoiceFileTypeId,
    setError,
    handleInlineNotificationErrors,
    handleInvalidScroll
  );
  const { visible, setVisible } = useDisplayInlineNotification(
    errors.invoiceReport
  );
  const scanDecision = useMemo(
    () =>
      !apiData.is_e_doc_ap_receipt_type_enabled && apReceiptTypeAlertType
        ? undefined
        : apiData.invoice_file?.e_doc_meta_datum
            ?.is_satisfy_depth_requirements &&
          apiData.invoice_file?.e_doc_meta_datum
            ?.is_satisfy_resolution_requirement
        ? 'ok'
        : apReceiptTypeAlertType,
    [
      apReceiptTypeAlertType,
      apiData.invoice_file?.e_doc_meta_datum?.is_satisfy_depth_requirements,
      apiData.invoice_file?.e_doc_meta_datum?.is_satisfy_resolution_requirement,
      apiData.is_e_doc_ap_receipt_type_enabled,
    ]
  );
  const eDocMetadata: EDocMetadataProps | undefined = useMemo(() => {
    if (apiData?.invoice_file?.e_doc_meta_datum) {
      const value = apiData.invoice_file.e_doc_meta_datum;
      return {
        dpi: value.dpi_text ?? '',
        pixelNumber: value.pixel_number_text ?? '',
        imageDepth: '',
        type:
          scanDecision === 'warning' || scanDecision === 'error'
            ? scanDecision
            : undefined,
      } satisfies EDocMetadataProps;
    }
    return undefined;
  }, [apiData?.invoice_file?.e_doc_meta_datum, scanDecision]);
  const containerRef = useGlobalContainerRef();
  return (
    <>
      {/* z-indexの調整をしているのでモーダルにして先頭になる様にする / I'm adjusting the z-index so that it will be modal and appear at the top. */}
      <Loading
        getContainer={containerRef}
        open={isSubmitLoading}
        isDialogMode
        size='xlarge'
        color='white'
      />
      <ErrorBoundary fallback={() => <div>Loading...</div>}>
        <FormProvider {...methods}>
          <Form<PaymentRequestForm>
            formMethod={methods}
            onSubmit={onSubmit}
            onInvalid={onInvalid}
            className={cx(styles['parent'])}
            action='?'
          >
            <Layout
              key={`申請番号:${apiData.number ?? ''}_${isLoading}`}
              title={`申請番号:${apiData.number ?? ''}`}
              reportStatus={
                typeGardeApReportStatus(apiData.status)
                  ? reportStatusLabel(apiData.status)
                  : ''
              }
              headerRight={
                <ButtonGroup>
                  <ButtonV2
                    isTransparent
                    color='danger'
                    size='sm'
                    onClick={onDeleteSubmit}
                  >
                    {t('payment_request_edit_btn_delete')}
                  </ButtonV2>
                  <ButtonV2
                    isSecondary
                    color='primary'
                    size='sm'
                    type='submit'
                    formAction='draft'
                  >
                    {t('payment_request_edit_btn_tmpsubmit')}
                  </ButtonV2>
                  <ButtonV2
                    color='primary'
                    size='sm'
                    type='submit'
                    formAction='next'
                  >
                    {t('payment_request_edit_btn_submit')}
                  </ButtonV2>
                </ButtonGroup>
              }
              showHeaderBorderBottom
              hideContentPadding
              isLoading={isLoading}
            >
              <div className={cx(styles['container'])} ref={container}>
                <div
                  ref={setMainContainerRef}
                  className={cx(styles['main-container'])}
                  style={mainContainerStyle}
                >
                  <div
                    className={cx(styles['report-form-container'], {
                      [styles['hidden']]: zoom,
                    })}
                  >
                    <WrapReportsForm>
                      <div className={cx(styles['report-form-container'])}>
                        {!!errors.invoiceReport && (
                          <div className={cx(styles['errors-invoice-report'])}>
                            <InlineNotification
                              type='error'
                              message={parser(errors.invoiceReport)}
                              icon={<IconInfo size={19} />}
                              visible={visible}
                              onClick={() => setVisible(false)}
                            />
                          </div>
                        )}
                        <div className={cx(styles['information-container'])}>
                          <ReportFormStatusContainer
                            invoiceId={apiData.received_invoice?.invoice_number}
                            officeMember={apiData.office_member?.display_name}
                            agentApplicantOfficeMember={
                              apiData.proxy_office_member?.display_name
                            }
                          />
                        </div>
                        <div className={cx(styles['input-container'])}>
                          <ApReportFormInputTextField<PaymentRequestForm>
                            name='title'
                            control={methods.control}
                            inputId='title-id'
                            label='申請タイトル'
                            required
                          />
                          {apiData.report_form_inputs.map((item) => {
                            return (
                              <ReportFormInputs
                                key={`${item.id}_${isLoading}`}
                                item={item}
                                control={methods.control}
                                isInstantPayeeEnabled={
                                  apiData.is_instant_payee_enabled ?? false
                                }
                              />
                            );
                          })}
                        </div>
                      </div>
                    </WrapReportsForm>
                  </div>
                  {isLoading ? (
                    <LoadingPdfViewer />
                  ) : (
                    <div
                      className={cx(styles['pdf-viewer-container'], {
                        [styles['draggable-pdf-viewer-container']]:
                          isDraggable && zoom,
                      })}
                      ref={pdfViewerContainerRef}
                    >
                      <PdfViewer
                        title={apiData?.invoice_file?.name ?? ''}
                        onChangeFile={onChangeFile}
                        draggable={isDraggable}
                        bounds={pdfViewerAreaRef.current!}
                        file={invoiceFile}
                        timestamp={
                          currentOffice?.eDoc.isEnabled &&
                          apiData.invoice_file?.e_doc_meta_datum !== undefined
                        }
                        scanner={scanDecision}
                        eDocMeta={eDocMetadata}
                      />
                    </div>
                  )}
                </div>
                <div
                  className={cx(styles['detail-container'])}
                  style={detailContainerStyle}
                >
                  <Details
                    control={control}
                    fields={fields}
                    append={append}
                    remove={remove}
                    replace={replace}
                    insert={insert}
                    isDetailMinimum={minimum}
                    setIsDetailMinimum={minimumUpdate}
                    isDetailZoom={zoom}
                    setIsDetailZoom={zoomUpdate}
                    WindowIcon={Icon}
                    viewerContainerRef={pdfViewerAreaRef}
                    onZoom={onWrapPictureInPicture}
                    bookDate={bookDate}
                    isPermitMinusTransaction={apiData.permit_minus_transaction}
                    includeTax={includeTax}
                    onChangeIncludeTax={onChangeIncludeTax}
                    showColumnDetails={showColumnDetails}
                    onChangeShowColumnDetails={onChangeShowColumnDetails}
                    canDisplayDrExcise={canDisplayDrExcise}
                    invoiceTransactionErrors={errors.invoiceTransactions}
                    invoiceTransactionAlert={errors.invoiceTransactionsAlert}
                    virtualContainerRef={virtualParentRef}
                    rowVirtualizer={rowVirtualizer}
                  />
                </div>
              </div>
            </Layout>
          </Form>
        </FormProvider>
        <DeleteForm />
        <ConfirmModal />
      </ErrorBoundary>
    </>
  );
});
InvoiceReportEdit.displayName = 'InvoiceReportEdit';
