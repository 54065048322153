import classnames from 'classnames/bind';
import { FC, memo } from 'react';
import {
  ReportFormStatusApplicant,
  Props as ReportFormStatusApplicantProps,
} from './ReportFormStatusApplicant';
import {
  ReportFormStatusNumber,
  Props as ReportFormStatusNumberProps,
} from './ReportFormStatusNumber';
import styles from './reportFormStatus.module.scss';

const cx = classnames.bind(styles);

export type Props = {
  className?: string;
};

export type ReportFormStatusContainerProps = Props &
  ReportFormStatusNumberProps &
  ReportFormStatusApplicantProps;

export const ReportFormStatusContainer: FC<ReportFormStatusContainerProps> =
  memo(({ className, ...rest }) => {
    return (
      <div className={cx(styles['report-form-status-container'], className)}>
        <div className={cx(styles['report-form-status-row'])}>
          <ReportFormStatusApplicant {...rest} />
        </div>
        <div className={cx(styles['report-form-status-row'])}>
          <ReportFormStatusNumber {...rest} />
        </div>
      </div>
    );
  });
ReportFormStatusContainer.displayName = 'ReportForm';
