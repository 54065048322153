import { useEffect, useState } from 'react';

export const useDisplayInlineNotification = (message: string | undefined) => {
  const [visible, setVisible] = useState(false);
  useEffect(() => {
    if (message) {
      setVisible(true);
    } else {
      setVisible(false);
    }
  }, [message]);
  return {
    visible,
    setVisible,
  };
};
