import { useCallback } from 'react';
import { Control, useFieldArray, useFormContext } from 'react-hook-form';
import type { InvoiceTransaction, PaymentRequestForm } from '../../../../type';
import { InvoiceTransactionHelper } from './useInvoiceTransactionHelper';

export const useInvoiceTransactionSupporters = (
  control: Control<PaymentRequestForm>,
  {
    dealDateHelper,
    drExciseIdHelper,
    exciseValueHelper,
    exItemIdHelper,
    quantityHelper,
    totalValueHelper,
    unitHelper,
    withholdingIncomeTaxHelper,
  }: InvoiceTransactionHelper
) => {
  const { trigger, getValues } = useFormContext<PaymentRequestForm>();
  const { update } = useFieldArray<PaymentRequestForm, 'invoiceTransactions'>({
    control,
    name: 'invoiceTransactions',
  });
  const getInvoiceTransaction = useCallback(
    (index: number) => getValues(`invoiceTransactions.${index}`),
    [getValues]
  );
  const updateInvoiceTransaction = useCallback(
    (index: number, invoice: InvoiceTransaction) => {
      update(index, invoice);
      trigger(`invoiceTransactions.${index}`);
    },
    [trigger, update]
  );

  const dealDateSupporter = useCallback(
    (index: number) => {
      updateInvoiceTransaction(
        index,
        dealDateHelper(getInvoiceTransaction(index))
      );
    },
    [dealDateHelper, getInvoiceTransaction, updateInvoiceTransaction]
  );
  const drExciseIdSupporter = useCallback(
    (index: number, value: string | undefined = undefined) => {
      updateInvoiceTransaction(
        index,
        drExciseIdHelper(getInvoiceTransaction(index), value)
      );
    },
    [drExciseIdHelper, getInvoiceTransaction, updateInvoiceTransaction]
  );
  const exciseValueSupporter = useCallback(
    (index: number) => {
      updateInvoiceTransaction(
        index,
        exciseValueHelper(getInvoiceTransaction(index))
      );
    },
    [exciseValueHelper, getInvoiceTransaction, updateInvoiceTransaction]
  );
  const exItemIdSupporter = useCallback(
    (index: number) => {
      updateInvoiceTransaction(
        index,
        exItemIdHelper(getInvoiceTransaction(index))
      );
    },
    [exItemIdHelper, getInvoiceTransaction, updateInvoiceTransaction]
  );
  const quantitySupporter = useCallback(
    (index: number) => {
      updateInvoiceTransaction(
        index,
        quantityHelper(getInvoiceTransaction(index))
      );
    },
    [getInvoiceTransaction, quantityHelper, updateInvoiceTransaction]
  );
  const totalValueSupporter = useCallback(
    (index: number) => {
      updateInvoiceTransaction(
        index,
        totalValueHelper(getInvoiceTransaction(index))
      );
    },
    [getInvoiceTransaction, totalValueHelper, updateInvoiceTransaction]
  );
  const unitSupporter = useCallback(
    (index: number) => {
      updateInvoiceTransaction(index, unitHelper(getInvoiceTransaction(index)));
    },
    [getInvoiceTransaction, unitHelper, updateInvoiceTransaction]
  );

  const withholdingIncomeTaxSupporter = useCallback(
    (index: number) => {
      updateInvoiceTransaction(
        index,
        withholdingIncomeTaxHelper(getInvoiceTransaction(index))
      );
    },
    [
      getInvoiceTransaction,
      updateInvoiceTransaction,
      withholdingIncomeTaxHelper,
    ]
  );

  return {
    dealDateSupporter,
    drExciseIdSupporter,
    exItemIdSupporter,
    exciseValueSupporter,
    quantitySupporter,
    totalValueSupporter,
    unitSupporter,
    withholdingIncomeTaxSupporter,
  };
};
