import { InputBase, LabelProps } from '@/components/ReportForm/InputBase';
import { InputBaseType } from '@/components/ReportForm/InputBase/types';
import { useTranslation } from '@/i18n';
import {
  FormDatepicker,
  FormDatepickerProps,
} from '@moneyforward/ap-frontend-components';
import { memo, useMemo } from 'react';
import { FieldValues } from 'react-hook-form';

export type Props<TFieldValues extends FieldValues> =
  InputBaseType<TFieldValues> & FormDatepickerProps<TFieldValues>;

const InnerApReportFormInputInvoiceBookDateField = <
  TFieldValues extends FieldValues
>({
  control,
  name,
  inputId,
  label,
  tooltip,
  hidden,
  caption,
  required = false,
  ...rest
}: Props<TFieldValues>) => {
  const { t } = useTranslation();
  const labelProps: LabelProps = useMemo(
    () => ({ required, children: label, tooltip }),
    [required, label, tooltip]
  );
  const rules: { required: string | boolean } = useMemo(
    () => ({ required: required ? t('required') : false }),
    [required, t]
  );

  const datepickerProps: FormDatepickerProps<TFieldValues>['datepickerProps'] =
    useMemo(
      () => ({
        placeholder: t('ap-report-form-book-date-input-placeholder'),
        inputReadOnly: false,
      }),
      [t]
    );

  return (
    <InputBase labelProps={labelProps} description={caption} hidden={hidden}>
      <FormDatepicker<TFieldValues>
        datepickerProps={datepickerProps}
        control={control}
        name={name}
        inputId={inputId}
        single
        rules={rules}
        {...rest}
      />
    </InputBase>
  );
};

InnerApReportFormInputInvoiceBookDateField.displayName =
  'InnerApReportFormInputInvoiceBookDateField';

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export const ApReportFormInputInvoiceBookDateField = memo((props) => (
  <InnerApReportFormInputInvoiceBookDateField {...props} />
)) as typeof InnerApReportFormInputInvoiceBookDateField;
ApReportFormInputInvoiceBookDateField.displayName =
  'ApReportFormInputInvoiceBookDateField';
