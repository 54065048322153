import { useTranslation } from '@/i18n';
import classnames from 'classnames/bind';
import { FC, memo } from 'react';
import styles from './reportFormStatus.module.scss';

const cx = classnames.bind(styles);

export type Props = {
  officeMember?: string;
  agentApplicantOfficeMember?: string;
};

export const ReportFormStatusApplicant: FC<Props> = memo(
  ({ officeMember, agentApplicantOfficeMember }) => {
    const { t } = useTranslation();
    return (
      <>
        <div className={cx(styles['report-form-status-text-wrapper'])}>
          <p className={cx(styles['report-form-status-text-label'])}>
            {t('applicant_office_member')}
          </p>
          {officeMember && (
            <p className={cx(styles['report-form-status-text-value'])}>
              {officeMember}
            </p>
          )}
        </div>
        {agentApplicantOfficeMember && (
          <div className={cx(styles['report-form-status-text-wrapper'])}>
            <p>{t('agent_applicant_office_member')}</p>
            <p className={cx(styles['report-form-status-text-value'])}>
              {agentApplicantOfficeMember}
            </p>
          </div>
        )}
      </>
    );
  }
);
ReportFormStatusApplicant.displayName = 'ReportForm';
