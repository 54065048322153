import { EditTemplate as InvoiceReportEditTemplate } from '@/features/InvoiceReport/Edit';
import { useParams } from '@/router';
import { FC, memo } from 'react';

const Page: FC = memo(() => {
  const { report_id } = useParams(
    '/report_types/:report_type_id/admin_invoice_reports/:report_id/edit'
  );
  return <InvoiceReportEditTemplate invoiceReportsId={report_id} />;
});
Page.displayName = 'Page';

export default Page;
