import { useCallback, useMemo, useState } from 'react';
import { Control, FieldPath, FieldValues, useWatch } from 'react-hook-form';

export const useCellWatch = <TFieldValues extends FieldValues>(
  control: Control<TFieldValues>,
  name: FieldPath<TFieldValues>,
  id: string,
  index: number,
  onBlur?: VoidFunction
) => {
  const [focus, setFocus] = useState(false);
  const onFocus = useCallback(() => {
    setFocus(true);
  }, []);
  const componentProps = useMemo(() => ({ onFocus }), [onFocus]);
  const onWrapBlur = useCallback(() => {
    onBlur?.();
    setFocus(false);
  }, [onBlur]);
  const value = useWatch<TFieldValues>({ control, name });
  const key = useMemo(
    () => (focus ? `${id}_${index}` : `${id}_${value}_${index}`),
    [focus, id, index, value]
  );
  return {
    value,
    key,
    onFocus,
    onBlur: onWrapBlur,
    componentProps,
  };
};
