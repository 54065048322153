import { useTranslation } from '@/i18n';
import {
  Checkbox,
  FormInput,
  InputProps,
} from '@moneyforward/ap-frontend-components';
import classnames from 'classnames/bind';
import { memo, useMemo } from 'react';
import { Control, FieldPath, FieldValues } from 'react-hook-form';
import styles from './otherInput.module.scss';

const cx = classnames.bind(styles);

export type OtherInputProps<TFieldValues extends FieldValues> = {
  name: FieldPath<TFieldValues>;
  control: Control<TFieldValues>;
  inputId: string;
  inputProps: InputProps;
  otherValueChecked: boolean;
  onChange: () => void;
};

const _OtherInput = <TFieldValues extends FieldValues>({
  control,
  name,
  inputId,
  inputProps,
  otherValueChecked,
  onChange,
}: OtherInputProps<TFieldValues>) => {
  const { t } = useTranslation();
  const rules = useMemo(() => {
    return { required: otherValueChecked ? t('required') : false };
  }, [otherValueChecked, t]);
  const memorizedInputProps = useMemo(
    () => ({ ...inputProps, disabled: !otherValueChecked }),
    [inputProps, otherValueChecked]
  );
  return (
    <div className={cx(styles['additional-input-wrapper'])}>
      <Checkbox
        label={t('other_value_label')}
        checked={otherValueChecked}
        onChange={onChange}
      />
      <FormInput<TFieldValues>
        inputProps={memorizedInputProps}
        control={control}
        inputId={inputId}
        rules={rules}
        name={name}
      />
    </div>
  );
};

_OtherInput.displayName = '_OtherInput';

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export const OtherInput = memo((props) => (
  <_OtherInput {...props} />
)) as typeof _OtherInput;

OtherInput.displayName = 'OtherInput';
