import { useEffect } from 'react';
import { Invoice } from '../type';
import { invoiceUploadEvent } from './event';

type EventHandle = (invoice: Invoice) => void;

export const useInvoiceEvent = (handler: EventHandle) => {
  useEffect(() => {
    const f = (invoice: Invoice) => {
      handler(invoice);
    };
    invoiceUploadEvent.on(invoiceUploadEvent.EventNames.Upload, f);
    return () => {
      invoiceUploadEvent.off(invoiceUploadEvent.EventNames.Upload, f);
    };
  }, [handler]);
};
