import { InputBase, LabelProps } from '@/components/ReportForm/InputBase';
import { InputBaseType } from '@/components/ReportForm/InputBase/types';
import { useTranslation } from '@/i18n';
import {
  FormDatepicker,
  FormDatepickerProps,
} from '@moneyforward/ap-frontend-components';
import { memo, useMemo } from 'react';
import { FieldValues } from 'react-hook-form';

export type Props<TFieldValues extends FieldValues> =
  InputBaseType<TFieldValues> & FormDatepickerProps<TFieldValues>;

const InnerApReportFormInputDateField = <TFieldValues extends FieldValues>({
  control,
  name,
  inputId,
  label,
  tooltip,
  caption,
  required = false,
  hidden,
  ...rest
}: Props<TFieldValues>) => {
  const labelProps: LabelProps = useMemo(
    () => ({ required, children: label, tooltip }),
    [required, label, tooltip]
  );
  const { t } = useTranslation();
  const rules: { required: string | boolean } = useMemo(
    () => ({ required: required ? t('required') : false }),
    [required, t]
  );
  return (
    <InputBase labelProps={labelProps} description={caption} hidden={hidden}>
      <FormDatepicker<TFieldValues>
        control={control}
        name={name}
        inputId={inputId}
        single
        rules={rules}
        {...rest}
      />
    </InputBase>
  );
};

InnerApReportFormInputDateField.displayName = 'InnerApReportFormInputDateField';

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export const ApReportFormInputDateField = memo((props) => (
  <InnerApReportFormInputDateField {...props} />
)) as typeof InnerApReportFormInputDateField;
ApReportFormInputDateField.displayName = 'ApReportFormInputDateField';
