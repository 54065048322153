import { createSelectItemContext } from '@/context/SelectComponent/Base';
import {
  Currencies,
  GetCurrenciesListResponse,
  getGetCurrencyUnitsQueryKey,
  useGetCurrencyUnitsInfinite,
} from 'ap-openapi';
import { FC, PropsWithChildren, memo } from 'react';

export const {
  useFetchPer: useCurrencyPer,
  useItems: useCurrencies,
  useSetItems: useSetCurrencies,
  useCacheKey: useCurrenciesCacheKey,
  useIsLoading: useIsLoadingCurrencies,
  useIsFetching: useIsFetchingCurrencies,
  useFindById: useFindCurrencyById,
  useFindByName: useFindCurrencyByName,
  Provider: BaseCurrenciesProvider,
} = createSelectItemContext<
  Currencies,
  GetCurrenciesListResponse,
  'id',
  'name',
  'name',
  'id'
>(
  100,
  useGetCurrencyUnitsInfinite,
  'currencies',
  'id',
  {
    key: 'id',
    return: 'name',
    defaultVal: '',
  },
  {
    key: 'name',
    return: 'id',
    defaultVal: '',
  },
  getGetCurrencyUnitsQueryKey
);

export const CurrenciesProvider: FC<PropsWithChildren> = memo(
  ({ children }) => {
    return <BaseCurrenciesProvider>{children}</BaseCurrenciesProvider>;
  }
);
CurrenciesProvider.displayName = 'CurrenciesProvider';
