// Generouted, changes to this file will be overriden
/* eslint-disable */

import { components, hooks, utils } from '@generouted/react-router/client'

export type Path =
  | `/`
  | `/approving_invoice_reports/:report_id/edit`
  | `/business_documents`
  | `/debug`
  | `/foreign_currency_settings`
  | `/foreign_currency_settings/:currency`
  | `/notification_setting`
  | `/received_invoices`
  | `/report_types/:report_type_id/admin_invoice_reports/:report_id/edit`
  | `/report_types/:report_type_id/invoice_reports/:report_id/edit`

export type Params = {
  '/approving_invoice_reports/:report_id/edit': { report_id: string }
  '/foreign_currency_settings/:currency': { currency: string }
  '/report_types/:report_type_id/admin_invoice_reports/:report_id/edit': { report_type_id: string; report_id: string }
  '/report_types/:report_type_id/invoice_reports/:report_id/edit': { report_type_id: string; report_id: string }
}

export type ModalPath = never

export const { Link, Navigate } = components<Path, Params>()
export const { useModals, useNavigate, useParams } = hooks<Path, Params, ModalPath>()
export const { redirect } = utils<Path, Params>()
