import { InfiniteData } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';

type ExtractResponse<T> = T extends InfiniteData<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  AxiosResponse<infer R, any>,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  any
>
  ? R
  : never;

export const getPageData = <
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Data extends InfiniteData<AxiosResponse<any, any>, any> | undefined,
  K extends keyof ExtractResponse<Data>
>(
  data: Data,
  key: K
): NonNullable<ExtractResponse<Data>[K]> => {
  type Model = ExtractResponse<Data>;
  const value =
    data?.pages.flatMap((page) => {
      const pageData: Model | undefined = page.data;
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      return (pageData?.[key] as ExtractResponse<Data>[K]) || [];
    }) ?? [];
  return value;
};
