import { useTranslation } from '@/i18n';
import {
  FormInput,
  FormInputProps,
} from '@moneyforward/ap-frontend-components';
import { memo, useMemo } from 'react';
import { FieldPath, FieldValues } from 'react-hook-form';
import { InstantPayeeInputBase } from '../InstantPayeeInputBase/InstantPayeeInputBase';

export type Props<TFieldValues extends FieldValues> = {
  required?: boolean;
  bankAccountNameInputId: string;
  bankAccountNameName: FieldPath<TFieldValues>;
} & Omit<Omit<FormInputProps<TFieldValues>, 'name'>, 'inputId'>;

const InnerInstantPayeeBankAccountNameForm = <
  TFieldValues extends FieldValues
>({
  control,
  bankAccountNameInputId,
  bankAccountNameName,
  required = false,
  ...rest
}: Props<TFieldValues>) => {
  const { t } = useTranslation();

  const rules: { required: string | boolean } = useMemo(
    () => ({ required: required ? t('required') : false }),
    [required, t]
  );
  const inputProps = useMemo(
    () => ({ ...rest.inputProps, placeholder: t('payee_name_placeholder') }),
    [rest.inputProps, t]
  );
  const labelProps = useMemo(() => ({ children: '支払先名' }), []);
  return (
    <InstantPayeeInputBase labelProps={labelProps}>
      <FormInput<TFieldValues>
        inputId={bankAccountNameInputId}
        rules={rules}
        name={bankAccountNameName}
        control={control}
        inputProps={inputProps}
        {...rest}
      />
    </InstantPayeeInputBase>
  );
};

InnerInstantPayeeBankAccountNameForm.displayName =
  'InnerInstantPayeeBankAccountNameForm';

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export const InstantPayeeBankAccountNameForm = memo((props) => (
  <InnerInstantPayeeBankAccountNameForm {...props} />
)) as typeof InnerInstantPayeeBankAccountNameForm;
InstantPayeeBankAccountNameForm.displayName = 'InstantPayeeBankAccountNameForm';
