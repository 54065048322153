import { FC, memo } from 'react';

export const IconMaximize: FC = memo(() => (
  <svg
    width='17'
    height='16'
    viewBox='0 0 17 16'
    fill='currentColor'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g id='icon/maximize'>
      <path
        id='Vector'
        d='M2.66699 3.33333V2H13.3337V3.33333H2.66699Z'
        fill='#777777'
      />
    </g>
  </svg>
));
IconMaximize.displayName = 'IconMaximize';
