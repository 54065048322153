import {
  CustomUpdateReportBodyParamsFormInputValue,
  InvoiceReportDetailFormInputsResponse,
  ReportSpecialExceptionStatus,
} from 'ap-openapi';
import { useCallback, useMemo } from 'react';
import { SpecialExceptionForm } from './type';
import { findReportFormInputType } from './useConvertApiDataAndPayeeForm';

export const useConvertApiDataToSpecialExceptionName = (
  reportFormInputs: InvoiceReportDetailFormInputsResponse[]
): SpecialExceptionForm => {
  const selectedSpecialExceptionInput: {
    id: string | undefined;
    value: string | undefined;
    options: {
      addressOrLocation: ReportSpecialExceptionStatus['address_or_location'];
    };
  } = useMemo(() => {
    const specialExceptionInput = findReportFormInputType(
      reportFormInputs,
      'ApReportFormInputSpecialExceptionStatus'
    );
    const addressOrLocation: ReportSpecialExceptionStatus | undefined =
      specialExceptionInput?.input_additional_values?.[0];

    return {
      id: specialExceptionInput?.input_values?.[0]?.id,
      value: addressOrLocation?.ap_special_exception_status?.id,
      options: { addressOrLocation: addressOrLocation?.address_or_location },
    };
  }, [reportFormInputs]);

  const specialExceptionInput = findReportFormInputType(
    reportFormInputs,
    'ApReportFormInputSpecialExceptionStatus'
  );

  return useMemo(
    () =>
      ({
        ...selectedSpecialExceptionInput,
        reportForm: {
          specialException: {
            reportFormInputId: specialExceptionInput?.id,
            inputValueId: specialExceptionInput?.input_values?.[0]?.id,
          },
        },
      } satisfies SpecialExceptionForm),
    [
      selectedSpecialExceptionInput,
      specialExceptionInput?.input_values,
      specialExceptionInput?.id,
    ]
  );
};

export const useConvertSpecialExceptionToRequestForm = () => {
  return useCallback(
    (
      input: SpecialExceptionForm
    ): CustomUpdateReportBodyParamsFormInputValue[] => {
      const specialException: CustomUpdateReportBodyParamsFormInputValue = {
        report_form_input_id:
          input.reportForm.specialException.reportFormInputId,
        id: input.reportForm.specialException.inputValueId,
        value: input.value,
        options: {
          address_or_location: input?.options.addressOrLocation,
        },
        hasValueWithOptions: true,
      };

      return [specialException];
    },
    []
  );
};
