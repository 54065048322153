import {
  UpdateReportBodyParamsFormInputValue,
  UpdateReportRequest,
} from '../../../openapi/model';

// ref: https://github.com/moneyforward/ex_web/blob/92fb3658bc42bf59e69ec07711afbb004791ea64/app/models/ap_report_type.rb#L92-L109
export const reportFormInputType = {
  ApReportFormInputTextField: 'ApReportFormInputTextField',
  ApReportFormInputTextArea: 'ApReportFormInputTextArea',
  ApReportFormInputNumberField: 'ApReportFormInputNumberField',
  ApReportFormInputNumberFieldForBranch:
    'ApReportFormInputNumberFieldForBranch',
  ApReportFormInputDateField: 'ApReportFormInputDateField',
  ApReportFormInputTimeField: 'ApReportFormInputTimeField',
  ApReportFormInputRadioButton: 'ApReportFormInputRadioButton',
  ApReportFormInputCheckBox: 'ApReportFormInputCheckBox',
  ApReportFormInputSelect: 'ApReportFormInputSelect',
  ApReportFormInputFileField: 'ApReportFormInputFileField',
  ApReportFormInputOfficeMemberSelect: 'ApReportFormInputOfficeMemberSelect',
  ApReportFormInputExItemSelect: 'ApReportFormInputExItemSelect',
  ApReportFormInputDeptSelect: 'ApReportFormInputDeptSelect',
  ApReportFormInputProjectSelect: 'ApReportFormInputProjectSelect',
  ApReportFormInputPayeeSelect: 'ApReportFormInputPayeeSelect',
  ApReportFormInputReportNumberForExpenseField:
    'ApReportFormInputReportNumberForExpenseField',
  // NOTE: https://github.com/moneyforward/ex_web/blob/92fb3658bc42bf59e69ec07711afbb004791ea64/app/models/ap_report_type.rb#L112-L113
  // ref: https://github.com/moneyforward/ex_web/pull/42044
  // ApReportFormInputExpenseDeptSelectForBranch: "ApReportFormInputExpenseDeptSelectForBranch",
  ApReportFormInputContractNumberField: 'ApReportFormInputContractNumberField',
  ApReportFormInputSpecialExceptionStatus:
    'ApReportFormInputSpecialExceptionStatus',
  ApReportFormInputInvoicePayeeType: 'ApReportFormInputInvoicePayeeType',
  ApReportFormInputInvoiceInstantPayee: 'ApReportFormInputInvoiceInstantPayee',
  ApReportFormInputReceiptType: 'ApReportFormInputReceiptType',
  ApReportFormInputInvoiceDueDateField: 'ApReportFormInputInvoiceDueDateField',
  ApReportFormInputInvoiceBookDateField:
    'ApReportFormInputInvoiceBookDateField',
  ApReportFormInputInvoicePayeeSelect: 'ApReportFormInputInvoicePayeeSelect',
  ApReportFormInputInvoiceFileField: 'ApReportFormInputInvoiceFileField',
  ApReportFormInputBusinessDocumentsField:
    'ApReportFormInputBusinessDocumentsField',
  ApReportFormInputInvoiceRegistrationNumberField:
    'ApReportFormInputInvoiceRegistrationNumberField',
} as const;

export type ReportFormInputType = keyof typeof reportFormInputType | '';

export const typeGuardReportFormInputType = (
  value: string
): value is ReportFormInputType => {
  if (value in reportFormInputType) return true;
  throw new Error(`unknown report form input type: ${value}`);
};

export type InputOption = {
  // input_values.0.id
  inputId: string | undefined;
  // input_values.0.value
  inputValue: string | undefined;
  // report_form_inputs.priority
  priority: number;
  // report_form_inputs.type
  type: ReportFormInputType;
  // [value][] の形成が必要かどうか
  isMultipleValue: boolean;
  hasOther: boolean;
  key: string;
};

// key = report_form_inputs.id
export type FormInputIdMap = Record<string, InputOption>;

export type CustomUpdateReportBodyParamsFormInputValue = Omit<
  UpdateReportBodyParamsFormInputValue,
  'value'
> & {
  value?: string | string[];
  hasOther?: boolean;
  isOtherValueChecked?: boolean;
  hasValueWithOptions?: boolean;
};

export type CustomUpdateReportRequest = Omit<UpdateReportRequest, 'report'> & {
  report: Omit<
    UpdateReportRequest['report'],
    'report_form_input_values_attributes'
  > & {
    report_form_input_values_attributes: CustomUpdateReportBodyParamsFormInputValue[];
  };
};

export const convertRequestBodyToFormData = (
  body: CustomUpdateReportRequest,
  formData: FormData
) => {
  formData.append('_method', 'patch');
  formData.append('report[title]', body.report.title ?? '');
  const attributes = body.report.report_form_input_values_attributes ?? [];
  attributes?.forEach((value, idx) => {
    if (value?.report_form_input_id) {
      formData.append(
        `report[report_form_input_values_attributes][${idx}][report_form_input_id]`,
        value?.report_form_input_id
      );
      if (value.id) {
        formData.append(
          `report[report_form_input_values_attributes][${idx}][id]`,
          value?.id
        );
      }
      const options = value?.options ?? {};
      if (Object.keys(options).length === 0 || value.hasValueWithOptions) {
        const valueKey = `report[report_form_input_values_attributes][${idx}][value]`;
        if (Array.isArray(value.value)) {
          value.value?.map((v) => {
            formData.append(`${valueKey}[]`, v ?? '');
          });
        } else {
          const formValue = value.isOtherValueChecked ? '' : value.value ?? '';
          const formOtherValue = value.isOtherValueChecked
            ? value.value ?? ''
            : '';
          formData.append(valueKey, formValue);
          if (value.hasOther) {
            formData.append(
              `report[report_form_input_values_attributes][${idx}][other_value]`,
              formOtherValue
            );
          }
        }
        if (value.file) {
          formData.append(
            `report[report_form_input_values_attributes][${idx}][file]`,
            value.file
          );
        }
      }
      for (const [k, v] of Object.entries(options ?? {})) {
        if (v) {
          formData.append(
            `report[report_form_input_values_attributes][${idx}][options][${k}]`,
            typeof v === 'boolean' ? v.toString() : v
          );
        }
      }
    }
  });
};
