import { useInvoiceEvent } from '@/components/ReportForm/ApReportFormInputInvoiceFileField/hooks/useInvoiceEvent';
import { useChangeFile } from '@/components/ReportForm/hooks/useChangeFile';
import { useFileDownload } from '@/components/ReportForm/hooks/useFileDonwload';
import { useGetFormValue } from '@/components/ReportForm/hooks/useFormValue';
import { useDeleteMfFile } from '@/context/services/mf_file/MfFile.service';
import { useCallback, useMemo } from 'react';
import { FieldPath, FieldValues } from 'react-hook-form';
import { useConfirm } from './hooks/useConfirm';
import { Invoice, typeGuardSelectedInvoice } from './type';

type UseInvoiceArgs<TFieldValues extends FieldValues> = {
  name: FieldPath<TFieldValues>;
  canDelete: boolean;
};
export const useInvoice = <TFieldValues extends FieldValues>({
  name,
  canDelete,
}: UseInvoiceArgs<TFieldValues>) => {
  const { defaultValue, onChange } = useGetFormValue<TFieldValues, Invoice>(
    name
  );
  const {
    isEditFile: isEditInvoice,
    defaultFile: defaultInvoice,
    file: invoice,
    handleRestoreDefaultFile: handleRestoreDefaultInvoice,
    handleDeleteDefaultFile: handleDeleteDefaultInvoice,
    handleChangeFile: handleChangeInvoice,
  } = useChangeFile<Invoice>(defaultValue, onChange);
  useInvoiceEvent(handleChangeInvoice);

  const deleteMfFile = useDeleteMfFile();
  const { confirm, ConfirmModal } = useConfirm();
  const onDelete = useCallback(async () => {
    // 削除できるかつ初期データ(mf_file)でかつ初期表示から変更されていない場合
    if (
      canDelete &&
      typeGuardSelectedInvoice(defaultInvoice) &&
      isEditInvoice.default
    ) {
      if (await confirm()) {
        await deleteMfFile(defaultInvoice.mfFileId);
        handleDeleteDefaultInvoice();
      }
    } else {
      if (defaultInvoice) {
        handleRestoreDefaultInvoice();
      } else {
        handleChangeInvoice(null);
      }
    }
  }, [
    canDelete,
    defaultInvoice,
    isEditInvoice.default,
    confirm,
    deleteMfFile,
    handleDeleteDefaultInvoice,
    handleRestoreDefaultInvoice,
    handleChangeInvoice,
  ]);

  return useMemo(
    () => ({
      invoice,
      onSelected: handleChangeInvoice,
      onDelete,
      ConfirmModal,
      isEditInvoice,
    }),
    [ConfirmModal, handleChangeInvoice, invoice, isEditInvoice, onDelete]
  );
};

export const useInvoiceDownload = (invoice: Invoice) => {
  const memorizedInvoice = useMemo(
    () => (!invoice ? null : invoice instanceof File ? invoice : invoice.file),
    [invoice]
  );
  return useFileDownload(memorizedInvoice);
};
