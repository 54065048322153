import {
  BusinessDocumentActionMenu,
  BusinessDocumentActionMenuProps,
} from '@/components/RelationActionMenu';
import { SelectedBusinessDocument } from '@/components/RelationActionMenu/BusinessDocument/type';
import { InputBase, LabelProps } from '@/components/ReportForm/InputBase';
import { CardFileList } from '@/components/ReportForm/InputBase/CardFileList/CardFileList';
import { InputBaseType } from '@/components/ReportForm/InputBase/types';
import { useTranslation } from '@/i18n';
import {
  FormController,
  useToastAPI,
} from '@moneyforward/ap-frontend-components';
import { memo, useCallback, useMemo, useState } from 'react';
import { ControllerRenderProps, FieldValues, Path } from 'react-hook-form';
import { Drawer } from './Drawer';

export type Props<TFieldValues extends FieldValues> =
  InputBaseType<TFieldValues>;

const InnerApReportFormInputBusinessDocumentsField = <
  TFieldValues extends FieldValues
>({
  control,
  name,
  inputId,
  label,
  tooltip,
  caption,
  required = false,
  hidden,
  ...rest
}: Props<TFieldValues>) => {
  const { t } = useTranslation();
  const notify = useToastAPI();
  const memorizedLabel: LabelProps = useMemo(
    () => ({ required, children: label, tooltip }),
    [label, required, tooltip]
  );
  const rules: { required: string | boolean } = useMemo(
    () => ({ required: required ? t('required') : false }),
    [required, t]
  );
  const [files, setFiles] = useState<SelectedBusinessDocument[]>([]);
  const [selectedId, setSelectedId] = useState<string>('');
  const onDeleteLink = useCallback(
    (id: string) => {
      let nextPreviewFile: SelectedBusinessDocument | undefined;
      setFiles((files) => {
        const nowIdx = files.findIndex(
          (file) => file.businessDocumentId === id
        );
        if (nowIdx === files.length - 1) {
          nextPreviewFile = files.find((_, idx) => idx === nowIdx - 1);
        } else {
          nextPreviewFile = files.find((_, idx) => idx === nowIdx + 1);
        }
        return files.filter((file) => file.businessDocumentId !== id);
      });
      setSelectedId((prev) => {
        if (prev) {
          return nextPreviewFile?.businessDocumentId ?? '';
        }
        return prev;
      });
      notify.success({
        title: t('business_document_unlink'),
        duration: 5,
      });
    },
    [notify, t]
  );
  const onDeleteClick = useCallback(
    (id: string) => {
      return () => {
        onDeleteLink(id);
      };
    },
    [onDeleteLink]
  );
  const onUnlink = useCallback(() => {
    onDeleteLink(selectedId);
  }, [onDeleteLink, selectedId]);
  const onSelectedFile = useCallback((id: string) => {
    return () => {
      setSelectedId(id);
    };
  }, []);
  const onCloseDrawer = useCallback(() => {
    setSelectedId('');
  }, []);

  return (
    <>
      <InputBase
        hidden={hidden}
        labelProps={memorizedLabel}
        description={caption}
        fileList={files.map((file) => {
          return (
            <CardFileList
              size='xs'
              tag='タグ'
              title={file.documentTitle}
              key={`${file.businessDocumentId}-${file.mfFileId}`}
              selected={selectedId === file.businessDocumentId}
              onButtonClick={onDeleteClick(file.businessDocumentId)}
              onCardClick={onSelectedFile(file.businessDocumentId)}
            />
          );
        })}
      >
        <FormController
          control={control}
          name={name}
          inputId={inputId}
          rules={rules}
          {...rest}
        >
          {(fields) => {
            return (
              <WrapBizDocActionMenu<TFieldValues>
                fields={fields}
                inputId={inputId}
                onSelectedItems={setFiles}
                selectedItems={files}
              />
            );
          }}
        </FormController>
      </InputBase>
      {selectedId && (
        // eslint-disable-next-line @moneyforward/account-payable/literals-in-props
        <div style={{ position: 'absolute' }}>
          <Drawer
            open={!!selectedId}
            id={selectedId}
            onClose={onCloseDrawer}
            onUnlink={onUnlink}
          />
        </div>
      )}
    </>
  );
};
InnerApReportFormInputBusinessDocumentsField.displayName =
  'InnerApReportFormInputBusinessDocumentsField';

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export const ApReportFormInputBusinessDocumentsField = memo(
  InnerApReportFormInputBusinessDocumentsField
) as typeof InnerApReportFormInputBusinessDocumentsField;

type WrapBizDocProps<TFieldValues extends FieldValues> = {
  fields: ControllerRenderProps<TFieldValues, Path<TFieldValues>>;
  inputId: string;
} & Omit<BusinessDocumentActionMenuProps, 'container'>;

const WrapBizDocActionMenu = <TFieldValues extends FieldValues>({
  fields,
  inputId,
  onSelectedItems,
  selectedItems,
}: WrapBizDocProps<TFieldValues>) => {
  return (
    <>
      <BusinessDocumentActionMenu
        container={null}
        onSelectedItems={onSelectedItems}
        selectedItems={selectedItems}
      />
      <input hidden id={inputId} {...fields} />
    </>
  );
};
