import { InputBase, LabelProps } from '@/components/ReportForm/InputBase';
import { InputBaseType } from '@/components/ReportForm/InputBase/types';
import { useTranslation } from '@/i18n';
import {
  FormInputInvoiceRegistrationNumber,
  FormInputInvoiceRegistrationNumberProps,
} from '@moneyforward/ap-frontend-components';
import { memo, useMemo } from 'react';
import { FieldValues } from 'react-hook-form';

export type Props<TFieldValues extends FieldValues> = {
  isDisabled: boolean;
} & InputBaseType<TFieldValues> &
  FormInputInvoiceRegistrationNumberProps<TFieldValues>;

const InnerApReportFormInputInvoiceRegistrationNumberField = <
  TFieldValues extends FieldValues
>({
  control,
  name,
  inputId,
  label,
  tooltip,
  hidden,
  caption,
  required = false,
  callbackOnInput13Text,
  isDisabled,
  ...rest
}: Props<TFieldValues>) => {
  const labelProps: LabelProps = useMemo(
    () => ({ required, children: label, tooltip }),
    [required, label, tooltip]
  );
  const { t } = useTranslation();
  const inputProps = useMemo(
    () => ({
      disabled: isDisabled,
      placeholder: t('enter_13_digit_number'),
    }),
    [isDisabled, t]
  );
  const rules: { required: string | boolean } = useMemo(
    () => ({ required: required ? t('required') : false }),
    [required, t]
  );
  return (
    <InputBase labelProps={labelProps} description={caption} hidden={hidden}>
      <FormInputInvoiceRegistrationNumber<TFieldValues>
        control={control}
        name={name}
        inputId={inputId}
        withPopover
        callbackOnInput13Text={callbackOnInput13Text}
        inputProps={inputProps}
        rules={rules}
        {...rest}
      />
    </InputBase>
  );
};

InnerApReportFormInputInvoiceRegistrationNumberField.displayName =
  'InnerApReportFormInputInvoiceRegistrationNumberField';

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export const ApReportFormInputInvoiceRegistrationNumberField = memo((props) => (
  <InnerApReportFormInputInvoiceRegistrationNumberField {...props} />
)) as typeof InnerApReportFormInputInvoiceRegistrationNumberField;
ApReportFormInputInvoiceRegistrationNumberField.displayName =
  'ApReportFormInputInvoiceRegistrationNumberField';
