import { useCallback, useMemo } from 'react';
import { useLocation, useParams } from 'react-router-dom';

const useGetInvoiceReportPath = () => {
  const location = useLocation();
  const { pathname } = location;
  const adminOrIndexParams = useParams<{
    report_id: string;
    report_type_id: string;
  }>();

  const approvalParams = useParams<{ report_id: string }>();

  return useMemo(() => {
    const adminPattern = /admin_invoice_reports/;
    const approvalPattern = /approving_invoice_reports/;

    if (adminPattern.test(pathname)) {
      return {
        currentPathRole: 'admin',
        index: '/admin_invoice_reports',
        show: `/report_types/${adminOrIndexParams.report_type_id}/admin_invoice_reports/${adminOrIndexParams.report_id}`,
        selectApprover: `/reports/${adminOrIndexParams.report_id}/route/edit`,
      };
    }

    if (approvalPattern.test(pathname)) {
      return {
        currentPathRole: 'approval',
        index: '/approving_invoice_reports',
        show: `/approving_invoice_reports/${approvalParams.report_id}`,
        selectApprover: `/reports/${approvalParams.report_id}/route/edit`,
      };
    }

    return {
      currentPathRole: 'application',
      index: '/invoice_reports',
      show: `/report_types/${adminOrIndexParams.report_type_id}/invoice_reports/${adminOrIndexParams.report_id}`,
      selectApprover: `/reports/${adminOrIndexParams.report_id}/route/edit`,
    };
  }, [pathname, adminOrIndexParams, approvalParams]);
};

export const useInvoiceReportNavigation = () => {
  const paths = useGetInvoiceReportPath();

  const goToInvoiceReportIndex = useCallback(() => {
    window.location.href = paths.index;
  }, [paths.index]);

  const goToInvoiceReportShow = useCallback(() => {
    window.location.href = paths.show;
  }, [paths.show]);

  const goToInvoiceReportSelectApprover = useCallback(() => {
    window.location.href = paths.selectApprover;
  }, [paths.selectApprover]);

  const isApproverRoleFromPath = useMemo(() => {
    return (
      paths.currentPathRole === 'admin' || paths.currentPathRole === 'approval'
    );
  }, [paths.currentPathRole]);

  return {
    goToInvoiceReportIndex,
    goToInvoiceReportShow,
    goToInvoiceReportSelectApprover,
    isApproverRoleFromPath,
  };
};
