import { useTranslation } from '@/i18n';
import {
  FormSelect,
  FormSelectProps,
  SelectProps,
} from '@moneyforward/ap-frontend-components';
import { useGetGeneralReportsSuspenseInfinite } from 'ap-openapi';
import { memo, startTransition, useCallback, useMemo, useState } from 'react';
import { FieldValues } from 'react-hook-form';

type Options = {
  label: string;
  value: string;
};

export type Props<TFieldValues extends FieldValues> = {
  selectProps?: Omit<FormSelectProps<TFieldValues>['selectProps'], 'options'>;
  per?: number;
  searchableNumber?: string;
  validateMaxCount?: number;
  defaultOptions?: Options[];
} & Omit<FormSelectProps<TFieldValues>, 'selectProps' | 'label'>;

const _GeneralReportsSelect = <TFieldValues extends FieldValues>({
  selectProps: _selectProps,
  defaultOptions: _defaultOptions,
  per = 50,
  searchableNumber,
  ...rest
}: Props<TFieldValues>) => {
  const defaultOptions = useMemo(
    () => _defaultOptions ?? [],
    [_defaultOptions]
  );

  const { t } = useTranslation();
  const [query, setQuery] = useState<string | undefined>(undefined);
  const { data, error, isLoading, hasNextPage, fetchNextPage } =
    useGetGeneralReportsSuspenseInfinite(
      {
        query,
        status: 'approved',
        per: per.toString(),
        number: searchableNumber,
      },
      {
        query: {
          getNextPageParam: (page) => {
            const url = new URL(page.data.next ?? '/', location.href);
            if (!url.searchParams.has('page')) return undefined;
            return url.searchParams.get('page');
          },
        },
      }
    );
  if (error) throw error;
  const options = useMemo(() => {
    if (!data) return defaultOptions;
    return data.pages.flatMap(
      (page) =>
        page.data.general_reports?.map((item) => ({
          label: item.title || '',
          value: item.number || '',
        })) ?? []
    );
  }, [data, defaultOptions]);
  const fetchMore = useCallback(
    async (callback: () => void) => {
      if (hasNextPage) {
        await fetchNextPage();
      }
      callback();
    },
    [fetchNextPage, hasNextPage]
  );
  const onSearch = useCallback((value: string) => {
    startTransition(() => {
      setQuery(value);
    });
  }, []);
  const selectProps: SelectProps = useMemo(() => {
    return {
      onSearchDebounceWait: 600,
      comboBox: true,
      placeholder: t('general_reports_placeholder'),
      inputPlaceholder: t('general_reports_input_placeholder'),
      ..._selectProps,
      options: options,
      onSearch: onSearch,
      fetchMore: fetchMore,
      loading: isLoading,
      selectAll: undefined,
      selectAllLabel: undefined,
      mode: 'multiple',
      additionalOption: true,
    };
  }, [_selectProps, isLoading, options, t, onSearch, fetchMore]);

  return <FormSelect<TFieldValues> selectProps={selectProps} {...rest} />;
};
_GeneralReportsSelect.displayName = '_GeneralReportsSelect';

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export const GeneralReportsSelect = memo((props) => (
  <_GeneralReportsSelect {...props} />
)) as typeof _GeneralReportsSelect;
GeneralReportsSelect.displayName = 'GeneralReportsSelect';
