import { useCallback, useState } from 'react';

export const useOtherValueChecked = (hasOtherInput: boolean) => {
  const [isCheck, setIsCheck] = useState(hasOtherInput ?? false);
  const onChange = useCallback(() => {
    setIsCheck((prev) => !prev);
  }, []);
  return {
    isOtherValueChecked: isCheck,
    onChangeOtherValueChecked: onChange,
  };
};
