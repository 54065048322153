import { InputBase, LabelProps } from '@/components/ReportForm/InputBase';
import { InputBaseType } from '@/components/ReportForm/InputBase/types';
import { useTranslation } from '@/i18n';
import {
  FormInputNumber,
  FormInputNumberProps,
  InputNumberProps,
} from '@moneyforward/ap-frontend-components';
import { memo, useMemo } from 'react';
import { FieldValues } from 'react-hook-form';

export type Props<TFieldValues extends FieldValues> =
  InputBaseType<TFieldValues> & FormInputNumberProps<TFieldValues>;

const InnerApReportFormInputNumberFieldForBranch = <
  TFieldValues extends FieldValues
>({
  control,
  name,
  inputId,
  label,
  tooltip,
  hidden,
  caption,
  required = false,
  ...rest
}: Props<TFieldValues>) => {
  const { t } = useTranslation();
  const labelProps: LabelProps = useMemo(
    () => ({ required, children: label, tooltip }),
    [required, label, tooltip]
  );

  const rules: { required: string | boolean } = useMemo(
    () => ({ required: required ? t('required') : false }),
    [required, t]
  );

  const inputProps: { format: InputNumberProps['format'] } = useMemo(
    () => ({ format: 'unit_with_empty' }),
    []
  );

  return (
    <InputBase labelProps={labelProps} description={caption} hidden={hidden}>
      <FormInputNumber<TFieldValues>
        inputProps={inputProps}
        inputId={inputId}
        rules={rules}
        name={name}
        control={control}
        {...rest}
      />
    </InputBase>
  );
};

InnerApReportFormInputNumberFieldForBranch.displayName =
  'InnerApReportFormInputNumberFieldForBranch';

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export const ApReportFormInputNumberFieldForBranch = memo((props) => (
  <InnerApReportFormInputNumberFieldForBranch {...props} />
)) as typeof InnerApReportFormInputNumberFieldForBranch;
ApReportFormInputNumberFieldForBranch.displayName =
  'ApReportFormInputNumberFieldForBranch';
