import {
  FormDatepicker,
  FormDatepickerProps,
} from '@moneyforward/ap-frontend-components';
import { DATE_FORMAT } from 'date-util';
import { memo } from 'react';
import { Control, FieldValues, RegisterOptions } from 'react-hook-form';
import { useCellWatch } from './hooks/useCellWatch';
import { CellProps } from './type';

export type Props<TFieldValues extends FieldValues> = {
  control: Control<TFieldValues, unknown>;
  inputId: string;
  rules: RegisterOptions<TFieldValues>;
  containerRef: () => HTMLDivElement;
} & FormDatepickerProps<TFieldValues> &
  CellProps;

export const InnerDealDate = <TFieldValues extends FieldValues>({
  control,
  name,
  inputId,
  rules,
  containerRef,
  onBlur,
  onChange,
  row,
  index,
  ...rest
}: Props<TFieldValues>) => {
  const { key } = useCellWatch(control, name, row.id, index);
  return (
    <FormDatepicker<TFieldValues>
      key={key}
      control={control}
      inputId={inputId}
      name={name}
      single
      // eslint-disable-next-line @moneyforward/account-payable/literals-in-props
      datepickerProps={{
        inputReadOnly: false,
        getPopupContainer: containerRef,
      }}
      format={DATE_FORMAT}
      rules={rules}
      onBlur={onBlur}
      onChange={onChange}
      {...rest}
    />
  );
};

InnerDealDate.displayName = 'InnerDealDate';

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export const DealDate = memo((props) => (
  <InnerDealDate {...props} />
)) as typeof InnerDealDate;
DealDate.displayName = 'DealDate';
