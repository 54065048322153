import { useCallback } from 'react';
import type { InvoiceTransaction } from '../../../../type';
import { InvoiceTransactionHelper } from './useInvoiceTransactionHelper';

export const useUpdateInvoiceTransaction = ({
  dealDateHelper,
  drExciseIdHelper,
  exciseValueHelper,
  exItemIdHelper,
  quantityHelper,
  totalValueHelper,
  unitHelper,
}: InvoiceTransactionHelper) => {
  return useCallback(
    (items: InvoiceTransaction[]) =>
      items.map((invoice) => {
        invoice = dealDateHelper(invoice);
        invoice = drExciseIdHelper(invoice);
        invoice = exciseValueHelper(invoice);
        invoice = exItemIdHelper(invoice);
        invoice = quantityHelper(invoice);
        invoice = totalValueHelper(invoice);
        invoice = unitHelper(invoice);
        return invoice;
      }),
    [
      dealDateHelper,
      drExciseIdHelper,
      exItemIdHelper,
      exciseValueHelper,
      quantityHelper,
      totalValueHelper,
      unitHelper,
    ]
  );
};
