import { useOtherValueChecked } from '@/components/OtherInput/hooks';
import { useEffect } from 'react';
import { FieldPath, FieldValues, useFormContext } from 'react-hook-form';

export const useOtherInput = <TFieldValues extends FieldValues>(
  name: FieldPath<TFieldValues>,
  otherInputName: FieldPath<TFieldValues> | undefined,
  withOtherInput: boolean | undefined
) => {
  const { watch, setValue } = useFormContext();
  const otherInputValue = otherInputName ? watch(otherInputName) : undefined;
  const isOtherValue = Boolean(withOtherInput && otherInputValue) || false;
  const { isOtherValueChecked, onChangeOtherValueChecked } =
    useOtherValueChecked(isOtherValue);
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions, @typescript-eslint/no-explicit-any
    setValue(`${name}_is_other_checked`, isOtherValueChecked as any);
  }, [isOtherValueChecked, name, setValue]);
  return {
    isOtherValue,
    isOtherValueChecked,
    onChangeOtherValueChecked,
  };
};
