import {
  FC,
  PropsWithChildren,
  createContext,
  memo,
  useCallback,
  useContext,
  useRef,
} from 'react';

const getPopupContainer = createContext<() => HTMLElement>(() => document.body);

export const PopupContainerProvider: FC<PropsWithChildren> = memo(
  ({ children }) => {
    const ref = useRef<HTMLDivElement>(null);
    const getContainer = useCallback(() => ref.current!, []);
    return (
      <getPopupContainer.Provider value={getContainer}>
        {children}
        <div ref={ref} />
      </getPopupContainer.Provider>
    );
  }
);
PopupContainerProvider.displayName = 'PopupContainerProvider';

export const useGetPopupContainer = () => useContext(getPopupContainer);
