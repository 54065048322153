import {
  InvoiceReportDetailFormInputsResponse,
  ReportSpecialExceptionStatus,
  UpdateReportBodyParamsFormInputValue,
} from 'ap-openapi';
import { Dayjs } from 'dayjs';

export type InstantPayeeForm = {
  id: string | undefined;
  name: string | undefined;
  bankId: string | undefined;
  bankName: string | undefined;
  bankBranchId: string | undefined;
  bankBranchName: string | undefined;
  accountType: string | undefined;
  number: string | undefined;
  holderNameKana: string | undefined;
};

type FormInfo = {
  reportFormInputId?: string;
  inputValueId?: string;
};

export type PayeeForm = {
  id: string | undefined;
  value: string | undefined;
  instantPayee: InstantPayeeForm | undefined;
  bookDate: string | Dayjs | undefined;
  dueDate: string | Dayjs | undefined;
  reportForm: {
    payee: FormInfo;
    bookDate: FormInfo;
    dueDate: FormInfo;
  };
};

export type SpecialExceptionForm = {
  id: string | undefined;
  value: string | undefined;
  options: {
    addressOrLocation:
      | ReportSpecialExceptionStatus['address_or_location']
      | undefined;
  };
  reportForm: {
    specialException: FormInfo;
  };
};

export type TInvoiceReportDetailFormInputsResponse =
  InvoiceReportDetailFormInputsResponse;

export type ReportBodyParamsFormInputValue = Omit<
  UpdateReportBodyParamsFormInputValue,
  'value'
> & { value: string | string[] | undefined };

//未保存状態を表す特殊な InvoiceTransaction.number の値
//サーバ側で永続化されてないことを表す
export const INVOICE_NUMBER_UNSAVED = '-1';
