import type { ModernPaginationProps } from '@moneyforward/ap-frontend-components';
import {
  ReceivedInvoiceDetailAllOfInvoiceReport as _ReceivedInvoiceDetailAllOfInvoiceReport,
  ReceivedInvoiceOperationStatus as _ReceivedInvoiceOperationStatus,
  ReceivedInvoiceReceiptType as _ReceivedInvoiceReceiptType,
  ReceivedInvoiceUploadType as _ReceivedInvoiceUploadType,
  GetReceivedInvoiceResponse,
  UpdateReceivedInvoiceErrorResponse as InvoiceUpdateErrorResponse,
  ReceivedInvoice,
  ApReport as ReceivedInvoiceApReport,
  ApReportStatus as ReceivedInvoiceApReportStatus,
  ReceivedInvoiceDetailAllOf,
  SearchReceivedInvoicesQuery,
  useGetReceivedInvoiceSuspense,
  useSearchReceivedInvoicesSuspense,
} from 'ap-openapi';
import { Dayjs } from 'dayjs';
import { Control, FieldPath } from 'react-hook-form';

export type ReceivedInvoiceDetail = ReceivedInvoice &
  ReceivedInvoiceDetailAllOf;
export type ApReportStatus = ReceivedInvoiceApReportStatus;
export const ApReportStatus = ReceivedInvoiceApReportStatus;
export type ApReport = ReceivedInvoiceApReport;
export type ReceivedInvoiceReceiptType = _ReceivedInvoiceReceiptType;
export const ReceivedInvoiceReceiptType = _ReceivedInvoiceReceiptType;
export type ReceivedInvoiceUploadType = _ReceivedInvoiceUploadType;
export const ReceivedInvoiceUploadType = _ReceivedInvoiceUploadType;
export type ReceivedInvoiceOperationStatus = _ReceivedInvoiceOperationStatus;
export const ReceivedInvoiceOperationStatus = _ReceivedInvoiceOperationStatus;
export type UpdateReceivedInvoiceErrorResponse = InvoiceUpdateErrorResponse;
export type ReceivedInvoiceDetailAllOfInvoiceReport =
  _ReceivedInvoiceDetailAllOfInvoiceReport;

export type ReceivedInvoicesSearchRefetch = ReturnType<
  typeof useSearchReceivedInvoicesSuspense
>['refetch'];
export type ReceivedInvoicesQuery = SearchReceivedInvoicesQuery;

export type FetchReceivedInvoicesModernResult = {
  pagination: ModernPaginationProps;
  data: InvoiceColumn[];
  refetch: ReceivedInvoicesSearchRefetch;
};

export type FetchReceivedInvoicesSearchWithReportableAndWithIncludeMfFileResult =
  {
    pagination: ModernPaginationProps;
    data: InvoiceColumnWithMfFile[];
    refetch: ReceivedInvoicesSearchRefetch;
    isLoading: boolean;
    isError: boolean;
  };

export type FetchReceivedInvoicesArgs = {
  page?: number;
  perPage?: number;
  query?: ReceivedInvoicesQuery;
  onPageChange?: (page: number) => void;
};

type FetchReturnType<ModernPagination, Args = {}> = (
  args: Args
) => ModernPagination;

export type FetchReceivedInvoicesHooks = FetchReturnType<
  FetchReceivedInvoicesModernResult,
  FetchReceivedInvoicesArgs
>;

export type ReceivedInvoiceSearchRefetch = ReturnType<
  typeof useGetReceivedInvoiceSuspense
>['refetch'];
export type FetchReceivedInvoiceResult = {
  data: GetReceivedInvoiceResponse;
  refetch: ReceivedInvoiceSearchRefetch;
};
export type FetchReceivedInvoiceHooks = (
  invoiceId: string
) => FetchReceivedInvoiceResult;

export type Pic = {
  id: string | undefined;
  name: string | undefined;
};

export const mpUploadType = {
  agent_receive: 'agent_receive',
  supplier_upload: 'supplier_upload',
  manual: 'manual',
  email: 'email',
  peppol_gateway: 'peppol_gateway',
  external_api: 'external_api',
} as const;

export type UploadType = keyof typeof mpUploadType;

type TUploadLabel = {
  [key in UploadType]: string;
};

export const UploadLabel: TUploadLabel = {
  agent_receive: '受領代行',
  supplier_upload: 'Web',
  manual: '手動',
  email: 'メール',
  peppol_gateway: 'デジタルインボイス',
  external_api: 'API連携',
};

export const mpOperationStatus = {
  ocr_processing: 'ocr_processing',
  not_started: 'not_started',
  applying: 'applying',
  applied: 'applied',
  archived: 'archived',
} as const;

export type OperationStatus = keyof typeof mpOperationStatus;

export const OperationStatusLabel: { [key: string]: string } = {
  ocr_processing: 'OCR読取り中',
  not_started: '未着手',
  applying: '編集中',
  applied: '申請済',
  archived: 'ゴミ箱',
};

export type InvoiceColumn = {
  id: string; // インボイスID
  fileName: string | undefined; // ファイル名
  payeeCode: string | undefined; // 支払先コード
  payeeName: string | undefined; // 支払先名
  pics: Pic[]; // 請求書担当者
  uploadedAt: string | undefined; // アップロード時刻
  uploadType: UploadType | undefined; // 受領方法
  operationStatus: OperationStatus | undefined; // 作業ステータス
  memo: string | undefined; // メモ

  searchId: string;
};

export type ReceivedInvoicesSearchQuery = SearchReceivedInvoicesQuery & {
  page?: number;
};

export type MfFile = {
  id: string;
  name: string;
  contentType?: string;
  contentByteSize?: number;
  content?: Blob;
};

export type InvoiceColumnWithMfFile = InvoiceColumn & {
  mfFile: MfFile;
};

export type ExistsInvoiceResult =
  | {
      data: InvoiceColumnWithMfFile | undefined;
      error?: string;
    }
  | undefined;

export type TReceivedInvoiceCondition = Omit<
  SearchReceivedInvoicesQuery,
  'reportable' | 'uploaded_at_gteq' | 'uploaded_at_lteq' | 'with_mf_file'
> & {
  uploaded_date?: (string | Dayjs)[];
  page?: number;
};

export type TSetupConditionProps = {
  control: Control<TReceivedInvoiceCondition>;
  name: FieldPath<TReceivedInvoiceCondition>;
};
