import { INVOICE_NUMBER_UNSAVED } from '@/context/services/reportsType/invoiceReports/type';
import { PaymentRequestForm } from '@/features/InvoiceReport/Edit/type';
import {
  CustomUpdateReportBodyParamsFormInputValue,
  FormInputIdMap,
  UpdateInvoiceTransactionBodyParam,
  UpdateInvoiceTransactionBodyParamActionType,
  UpdateInvoiceTransactionBodyParamAttributesInvoiceKind,
} from 'ap-openapi';
import { toISODateString } from 'date-util';
import { isDayjs } from 'dayjs';
import { useMemo } from 'react';
import { typeGuardUploadFileAndReportFormInputType } from './type-guard';

const isInvoiceKind = (
  value: string
): value is UpdateInvoiceTransactionBodyParamAttributesInvoiceKind => {
  return value in UpdateInvoiceTransactionBodyParamAttributesInvoiceKind;
};

function assertInvoiceKind(
  kind: string
): UpdateInvoiceTransactionBodyParamAttributesInvoiceKind {
  if (!isInvoiceKind(kind)) {
    throw new Error(`Invalid invoice kind`);
  }
  return kind;
}

export const useInvoiceFormTransformers = (formInputIdMap: FormInputIdMap) => {
  return useMemo(
    () => ({
      transformReportForm: (
        values: PaymentRequestForm['reportForm']
      ): CustomUpdateReportBodyParamsFormInputValue[] => {
        if (!Object.keys(values).length) return [];

        return Object.entries(formInputIdMap)
          .filter(([key]) => key in values)
          .map(([key, formInfoValue]) => {
            const _value = values[key];
            const otherValue = values[`${key}_other`];
            // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
            const isOtherValueChecked = values[
              `${key}_is_other_checked`
            ] as unknown as boolean; // 決めうち
            const value = isOtherValueChecked ? otherValue : _value;
            let data:
              | { value: string | string[] | undefined }
              | { file: Blob | undefined }
              | undefined = undefined;

            if (
              typeGuardUploadFileAndReportFormInputType(
                formInfoValue.type,
                value
              )
            ) {
              const file = Array.isArray(value) ? value[0] : value;
              data = {
                file: file ? file.originFileObj : file,
              };
            } else {
              let v: string | string[] | undefined = undefined;
              if (value && Array.isArray(value)) {
                const values = Array.isArray(value) ? value : [value];
                v = values.map((item) =>
                  isDayjs(item) ? toISODateString(item) : item
                );
              } else {
                v = isDayjs(value) ? toISODateString(value) : value;
              }
              // 複数選択可能じゃないのに配列になっている(selectコンポーネントとか)
              if (!formInfoValue.isMultipleValue && Array.isArray(v)) {
                v = v[0];
              }
              data = {
                value: v,
              };
            }
            return {
              report_form_input_id: key,
              id: formInfoValue.inputId,
              isOtherValueChecked: isOtherValueChecked,
              hasOther: formInfoValue.hasOther,
              ...data,
            } satisfies CustomUpdateReportBodyParamsFormInputValue;
          });
      },
      transformInvoiceTransactions: (
        transactions: PaymentRequestForm['invoiceTransactions'],
        deleteTransactions: PaymentRequestForm['deleteInvoiceTransactions']
      ): UpdateInvoiceTransactionBodyParam[] =>
        transactions
          .map(
            (item): UpdateInvoiceTransactionBodyParam => ({
              id: item.id,
              action_type:
                item.number === INVOICE_NUMBER_UNSAVED
                  ? 'create'
                  : ('update' satisfies UpdateInvoiceTransactionBodyParamActionType),
              attributes: {
                cr_item_id: item.crItemId,
                cr_sub_item_id: item.crSubItemId,
                currency: item.currency,
                deal_date: item.dealDate?.toISOString(),
                dept_id: item.deptId,
                dr_excise_id: item.drExciseId,
                ex_item_id: item.exItemId,
                excise_value: item.exciseValue,
                has_withholding_income_tax: item.hasWithholdingIncomeTax,
                invoice_kind: item.invoiceKind
                  ? assertInvoiceKind(item.invoiceKind)
                  : undefined,
                jpyrate: item.jpyRate,
                memo: item.memo,
                name: item.name,
                project_code_id: item.projectCodeId,
                quantity: item.quantity,
                total_value: item.totalValue,
                unit_value: item.unit,
                use_custom_jpy_rate: item.useCustomJPYRate,
                withholding_income_tax_value: item.withholdingIncomeTax,
              },
            })
          )
          .concat(
            deleteTransactions?.map(
              (item): UpdateInvoiceTransactionBodyParam =>
                ({
                  id: item.id,
                  action_type:
                    'delete' satisfies UpdateInvoiceTransactionBodyParamActionType,
                  attributes: {},
                } satisfies UpdateInvoiceTransactionBodyParam)
            ) || []
          ),
    }),
    [formInputIdMap]
  );
};
