import { FileNameSeparatorWithExtension } from '@/components/FileNameSeparatorWithExtension';
import { useMergeState } from '@/hooks/useMergeState';
import { useTranslation } from '@/i18n';
import {
  ButtonGroup,
  IconButton,
  Tooltip,
} from '@moneyforward/ap-frontend-components';
import classNames from 'classnames';
import React, {
  FC,
  PropsWithChildren,
  memo,
  useCallback,
  useState,
} from 'react';
import styles from './DraggablePaymentRequest.module.scss';
import { IconMaximize } from './IconMaximize';
import { IconMinimize } from './IconMinimize';

const cx = classNames.bind(styles);

type Props = {
  title: string;
  hidden?: boolean;
  onClick?: (value: boolean) => void;
  className?: string;
};

export const DraggablePaymentRequest: FC<PropsWithChildren<Props>> = memo(
  ({
    children,
    title,
    className,
    onClick: _onClick,
    hidden: _hidden = false,
  }) => {
    const { t } = useTranslation();
    const [hidden, setHidden] = useMergeState(_hidden);
    const [tooltipOpen, setTooltipOpen] = useState(false);

    const onClick = useCallback(
      (e: React.MouseEvent) => {
        setTooltipOpen(false);
        setHidden((prev) => {
          const v = !prev;
          _onClick?.(v);
          return v;
        });
        e.preventDefault();
        e.stopPropagation();
      },
      [_onClick, setHidden]
    );

    return (
      <div
        className={cx(
          styles['container'],
          {
            [styles['bottom-absolute']]: hidden,
          },
          className
        )}
      >
        <div className={cx(styles['header'])}>
          <div className={cx(styles['title'])}>
            <FileNameSeparatorWithExtension>
              {title}
            </FileNameSeparatorWithExtension>
          </div>
          <div className={cx(styles['button-container'])}>
            <ButtonGroup>
              <Tooltip
                title={
                  hidden
                    ? t('invoice_transaction_maximize_draggable')
                    : t('invoice_transaction_minimize')
                }
                open={tooltipOpen}
                onOpenChange={setTooltipOpen}
              >
                <IconButton
                  onClick={onClick}
                  size='sm'
                  className={cx(styles['button'])}
                >
                  {hidden ? <IconMaximize /> : <IconMinimize />}
                </IconButton>
              </Tooltip>
            </ButtonGroup>
          </div>
        </div>
        <div className={cx(styles['preview'], { [styles['hidden']]: hidden })}>
          {children}
        </div>
      </div>
    );
  }
);
DraggablePaymentRequest.displayName = 'DraggablePaymentRequest';
