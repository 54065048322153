import { useTranslation } from '@/i18n';
import {
  Bottom,
  Caption,
  Left,
  ListView,
  Right,
  Top,
} from '@moneyforward/ap-frontend-components';
import { FC, memo } from 'react';

export type Props = {
  dpi: string;
  pixelNumber: string;
  imageDepth: string;
  type?: 'warning' | 'error';
};

export const EDocMetadata: FC<Props> = memo(
  ({ dpi, pixelNumber, imageDepth, type }) => {
    const { t } = useTranslation();
    return (
      <ListView>
        <Top>
          <ListItem label={t('dpi_label')} text={dpi} type={type} />
        </Top>
        <Top>
          <ListItem
            label={t('pixel_number_label')}
            text={pixelNumber}
            type={type}
          />
        </Top>
        <Top>
          <ListItem
            label={t('image_depth_label')}
            text={imageDepth}
            type={type}
          />
        </Top>
        <Bottom>
          <ListItem label={t('timestamp')} text='付与済み' />
        </Bottom>
      </ListView>
    );
  }
);
EDocMetadata.displayName = 'EDocMetadata';

type ListItemProps = {
  label: string;
  text: string;
  type?: 'warning' | 'error';
};

const ListItem: FC<ListItemProps> = memo(({ label, text, type }) => {
  const { t } = useTranslation();
  return (
    <>
      <Left>{label}</Left>
      <Right>
        <div>
          <div>{text}</div>
          <div>
            {type && (
              <Caption icon type={type === 'warning' ? 'alert' : type}>
                {type === 'warning'
                  ? t('e_doc_plan_alert_label')
                  : t('e_doc_plan_error_label')}
              </Caption>
            )}
          </div>
        </div>
      </Right>
    </>
  );
});
ListItem.displayName = 'ListItem';
