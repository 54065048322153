import { IconMaximize } from '@/components/pdfViewer/Headers/DraggablePaymentRequest/IconMaximize';
import { IconMinimize } from '@/components/pdfViewer/Headers/DraggablePaymentRequest/IconMinimize';
import { useTranslation } from '@/i18n';
import {
  ButtonGroup,
  IconButton,
  Tooltip,
} from '@moneyforward/ap-frontend-components';

import classNames from 'classnames';
import { FC, ForwardedRef, forwardRef, memo, useState } from 'react';
import { DetailsSize } from '../hooks/useDetailsSize';
import styles from './Header.module.scss';

const cx = classNames.bind(styles);

export type Props = Pick<DetailsSize, 'Icon' | 'zoom'> & {
  onZoom: VoidFunction;
  onMinimum: VoidFunction;
  isMinimized: boolean;
};

const InnerHeader: FC<Props> = forwardRef<HTMLDivElement, Props>(
  ({ onZoom, onMinimum, zoom, Icon, isMinimized }, ref) => {
    const { t } = useTranslation();
    const [minimizeTooltipOpen, setMinimizeTooltipOpen] = useState(false);
    const [zoomTooltipOpen, setZoomTooltipOpen] = useState(false);

    return (
      <div
        className={cx(styles['detail-header'], { [styles['zoom']]: zoom })}
        ref={ref}
      >
        <div className={cx(styles['text'])}>支払明細</div>
        <ButtonGroup>
          <Tooltip
            title={
              isMinimized
                ? t('invoice_transaction_maximize')
                : t('invoice_transaction_minimize')
            }
            open={minimizeTooltipOpen}
            onOpenChange={setMinimizeTooltipOpen}
          >
            <IconButton
              size='sm'
              className={cx(styles['detail-buttons'])}
              onClick={() => {
                setMinimizeTooltipOpen(false);
                onMinimum();
              }}
            >
              {isMinimized ? <IconMaximize /> : <IconMinimize />}
            </IconButton>
          </Tooltip>
          <Tooltip
            title={
              zoom
                ? t('invoice_transaction_zoom_out')
                : t('invoice_transaction_zoom_in')
            }
            open={zoomTooltipOpen}
            onOpenChange={setZoomTooltipOpen}
          >
            <IconButton
              size='sm'
              onClick={() => {
                setZoomTooltipOpen(false);
                onZoom();
              }}
              className={cx(styles['detail-buttons'])}
            >
              {Icon}
            </IconButton>
          </Tooltip>
        </ButtonGroup>
      </div>
    );
  }
);
InnerHeader.displayName = 'InnerHeader';

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export const Header = memo(InnerHeader) as (
  props: Props & { ref?: ForwardedRef<HTMLDivElement> }
) => ReturnType<typeof InnerHeader>;
