import { OtherInput } from '@/components/OtherInput';
import { RadioGroupWithOtherInput } from '@/components/RadioGroupWithOtherInput';
import { InputBase, LabelProps } from '@/components/ReportForm/InputBase';
import { InputBaseType } from '@/components/ReportForm/InputBase/types';
import { useOtherInput } from '@/components/ReportForm/hooks/useOtherValue';
import { UnionOtherInputProps } from '@/components/ReportForm/type';
import {
  FormRadioButtonProps,
  InputProps,
} from '@moneyforward/ap-frontend-components';
import { memo, useMemo } from 'react';
import { FieldValues } from 'react-hook-form';

export type Props<TFieldValues extends FieldValues> =
  InputBaseType<TFieldValues> & {
    options: FormRadioButtonProps<TFieldValues>['options'];
  } & UnionOtherInputProps<TFieldValues>;

const InnerApReportFormInputRadioButton = <TFieldValues extends FieldValues>({
  control,
  name,
  inputId,
  label,
  caption,
  required = false,
  options,
  withOtherInput,
  otherInputName,
  otherInputTestId,
  hidden,
}: Props<TFieldValues>) => {
  const labelProps: LabelProps = useMemo(
    () => ({ required, children: label }),
    [required, label]
  );
  const { isOtherValueChecked, onChangeOtherValueChecked } =
    useOtherInput<TFieldValues>(name, otherInputName, withOtherInput);
  const inputProps: InputProps = useMemo(() => {
    return {
      testId: otherInputTestId,
      placeholder: '',
      disabled: !isOtherValueChecked,
    };
  }, [isOtherValueChecked, otherInputTestId]);

  const memorizedOtherInput = useMemo(
    () =>
      withOtherInput ? (
        <OtherInput
          inputProps={inputProps}
          onChange={onChangeOtherValueChecked}
          otherValueChecked={isOtherValueChecked}
          control={control}
          inputId={otherInputName}
          name={otherInputName}
        />
      ) : undefined,
    [
      withOtherInput,
      inputProps,
      onChangeOtherValueChecked,
      isOtherValueChecked,
      control,
      otherInputName,
    ]
  );
  return (
    <InputBase labelProps={labelProps} description={caption} hidden={hidden}>
      <RadioGroupWithOtherInput<TFieldValues>
        options={options}
        control={control}
        name={name}
        inputId={inputId}
        otherInput={memorizedOtherInput}
        required={required}
        disabled={isOtherValueChecked}
      />
    </InputBase>
  );
};

InnerApReportFormInputRadioButton.displayName =
  'InnerApReportFormInputRadioButton';

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export const ApReportFormInputRadioButton = memo((props) => (
  <InnerApReportFormInputRadioButton {...props} />
)) as typeof InnerApReportFormInputRadioButton;
ApReportFormInputRadioButton.displayName = 'ApReportFormInputRadioButton';
