import { OtherInput } from '@/components/OtherInput';
import { InputBase, LabelProps } from '@/components/ReportForm/InputBase';
import { InputBaseType } from '@/components/ReportForm/InputBase/types';
import { useOtherInput } from '@/components/ReportForm/hooks/useOtherValue';
import { UnionOtherInputProps } from '@/components/ReportForm/type';
import { InputProps } from 'antd';
import { memo, useMemo } from 'react';
import { FieldValues } from 'react-hook-form';
import {
  CheckboxWithOtherInput,
  Props as CheckboxWithOtherInputProps,
} from './CheckboxWithOtherInput';

export type Props<TFieldValues extends FieldValues> =
  InputBaseType<TFieldValues> &
    CheckboxWithOtherInputProps<TFieldValues> &
    UnionOtherInputProps<TFieldValues>;

const InnerApReportFormInputCheckBox = <TFieldValues extends FieldValues>({
  control,
  name,
  inputId,
  label,
  caption,
  options,
  withOtherInput,
  otherInputName,
  otherInputTestId,
  required = false,
  hidden,
}: Props<TFieldValues>) => {
  const labelProps: LabelProps = useMemo(
    () => ({ required, children: label }),
    [required, label]
  );
  const { isOtherValueChecked, onChangeOtherValueChecked } =
    useOtherInput<TFieldValues>(name, otherInputName, withOtherInput);
  const inputProps: InputProps = useMemo(() => {
    return {
      testId: otherInputTestId,
      placeholder: '',
      disabled: !isOtherValueChecked,
    };
  }, [isOtherValueChecked, otherInputTestId]);

  const memorizedOtherInput = useMemo(
    () =>
      withOtherInput ? (
        <OtherInput
          inputProps={inputProps}
          onChange={onChangeOtherValueChecked}
          otherValueChecked={isOtherValueChecked}
          control={control}
          inputId={otherInputName}
          name={otherInputName}
        />
      ) : undefined,
    [
      withOtherInput,
      inputProps,
      onChangeOtherValueChecked,
      isOtherValueChecked,
      control,
      otherInputName,
    ]
  );

  return (
    <InputBase labelProps={labelProps} description={caption} hidden={hidden}>
      <CheckboxWithOtherInput<TFieldValues>
        options={options}
        control={control}
        name={name}
        inputId={inputId}
        required={required}
        otherInput={memorizedOtherInput}
        disabled={isOtherValueChecked}
      />
    </InputBase>
  );
};

InnerApReportFormInputCheckBox.displayName = 'InnerApReportFormInputCheckBox';

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export const ApReportFormInputCheckBox = memo((props) => (
  <InnerApReportFormInputCheckBox {...props} />
)) as typeof InnerApReportFormInputCheckBox;
ApReportFormInputCheckBox.displayName = 'ApReportFormInputCheckBox';
