import { useTranslation } from '@/i18n';
import { useGlobalContainerRef } from '@/wc/helper/ref';
import {
  FormSelect,
  FormSelectProps,
  SelectProps,
} from '@moneyforward/ap-frontend-components';
import { memo, useEffect, useMemo } from 'react';
import { FieldValues, PathValue, useFormContext } from 'react-hook-form';
import { InstantPayeeInputBase } from '../InstantPayeeInputBase/InstantPayeeInputBase';

export type Props<TFieldValues extends FieldValues> = {
  required?: boolean;
  className?: string;
} & Omit<FormSelectProps<TFieldValues>, 'selectProps'>;

type Options = {
  label: string;
  value: string;
};

const InnerInstantPayeeBankTypeForm = <TFieldValues extends FieldValues>({
  control,
  name,
  inputId,
  required = false,
  ...rest
}: Props<TFieldValues>) => {
  const containerRef = useGlobalContainerRef();
  const { t } = useTranslation();
  const { getValues, setValue } = useFormContext<TFieldValues>();

  const rules: { required: string | boolean } = useMemo(
    () => ({ required: required ? t('required') : false }),
    [required, t]
  );

  const options: Options[] = useMemo(
    () => [
      {
        value: 'ordinary',
        label: t('instant_payee_bank_type_label_ordinary'),
      },
      {
        value: 'checking',
        label: t('instant_payee_bank_type_label_checking'),
      },
      {
        value: 'saving',
        label: t('instant_payee_bank_type_label_saving'),
      },
      {
        value: 'other',
        label: t('instant_payee_bank_type_label_other'),
      },
    ],
    [t]
  );

  const memorizedSelectProps: SelectProps = useMemo(
    () => ({
      options,
      getPopupContainer: containerRef,
      defaultValue: 'ordinary',
    }),
    [containerRef, options]
  );

  useEffect(() => {
    const currentValue = getValues(name);
    if (
      currentValue === undefined ||
      currentValue === null ||
      currentValue === ''
    ) {
      setValue(
        name,
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        'ordinary' as unknown as PathValue<TFieldValues, typeof name>
      );
    }
  }, [getValues, setValue, name]);

  const labelProps = useMemo(() => ({ children: '口座種別' }), []);

  return (
    <InstantPayeeInputBase labelProps={labelProps}>
      <FormSelect<TFieldValues>
        {...rest}
        inputId={inputId}
        rules={rules}
        name={name}
        control={control}
        selectProps={memorizedSelectProps}
      />
    </InstantPayeeInputBase>
  );
};

InnerInstantPayeeBankTypeForm.displayName = 'InnerInstantPayeeBankTypeForm';

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export const InstantPayeeBankTypeForm = memo((props) => (
  <InnerInstantPayeeBankTypeForm {...props} />
)) as typeof InnerInstantPayeeBankTypeForm;
InstantPayeeBankTypeForm.displayName = 'InstantPayeeBankTypeForm';
