import { ExItemSelect } from '@/components/ExItemSelect';
import { useGetPopupContainer } from '@/components/ReportForm/Container/PopupContainer';
import { InputBase, LabelProps } from '@/components/ReportForm/InputBase';
import { InputBaseType } from '@/components/ReportForm/InputBase/types';
import { useTranslation } from '@/i18n';
import { FormSelectProps } from '@moneyforward/ap-frontend-components';
import { memo, useMemo } from 'react';
import { FieldValues } from 'react-hook-form';

export type Props<TFieldValues extends FieldValues> =
  InputBaseType<TFieldValues>;

const InnerApReportFormInputExItemSelect = <TFieldValues extends FieldValues>({
  control,
  name,
  inputId,
  label,
  tooltip,
  caption,
  required = false,
  hidden,
}: Props<TFieldValues>) => {
  const labelProps: LabelProps = useMemo(
    () => ({ required, children: label, tooltip }),
    [required, label, tooltip]
  );
  const { t } = useTranslation();
  const rules: { required: string | boolean } = useMemo(
    () => ({ required: required ? t('required') : false }),
    [required, t]
  );
  const containerRef = useGetPopupContainer();
  const memorizedSelectProps = useMemo(
    (): Omit<
      FormSelectProps<TFieldValues>['selectProps'],
      'options' | 'comboBox'
    > => ({
      getPopupContainer: containerRef,
    }),
    [containerRef]
  );
  return (
    <InputBase labelProps={labelProps} description={caption} hidden={hidden}>
      <ExItemSelect<TFieldValues>
        control={control}
        name={name}
        inputId={inputId}
        rules={rules}
        selectProps={memorizedSelectProps}
      />
    </InputBase>
  );
};

InnerApReportFormInputExItemSelect.displayName =
  'InnerApReportFormInputExItemSelect';

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export const ApReportFormInputExItemSelect = memo((props) => (
  <InnerApReportFormInputExItemSelect {...props} />
)) as typeof InnerApReportFormInputExItemSelect;
ApReportFormInputExItemSelect.displayName = 'ApReportFormInputExItemSelect';
