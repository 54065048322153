import { EventEmitter } from 'events';
import { Invoice } from '../type';

class InvoiceUpload extends EventEmitter {
  EventNames = {
    Upload: 'upload',
  };
  /**
   * Upload
   */
  public Upload(invoice: Invoice) {
    this.emit(this.EventNames.Upload, invoice);
  }
}

export const invoiceUploadEvent = new InvoiceUpload();
