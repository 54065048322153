import { InputBase, LabelProps } from '@/components/ReportForm/InputBase';
import { InputBaseType } from '@/components/ReportForm/InputBase/types';
import { useTranslation } from '@/i18n';
import { FormRadioButton } from '@moneyforward/ap-frontend-components';
import { memo, useMemo } from 'react';
import { FieldPath, FieldValues, useWatch } from 'react-hook-form';

type Options = {
  id: string;
  label: string;
  value: string;
};

export type Props<TFieldValues extends FieldValues> =
  InputBaseType<TFieldValues> & {
    options: Options[];
    invoiceFileName: FieldPath<TFieldValues>;
  };

const InnerApReportFormInputReceiptType = <TFieldValues extends FieldValues>({
  control,
  name,
  inputId,
  label,
  caption,
  invoiceFileName,
  options,
  hidden,
}: Props<TFieldValues>) => {
  const { t } = useTranslation();
  const value = useWatch<TFieldValues>({ control, name: invoiceFileName });
  const disabled = useMemo(() => !value, [value]);
  const rules = useMemo(() => {
    return {
      required: !disabled && !hidden ? t('required') : false,
    };
  }, [disabled, hidden, t]);
  const labelProps: LabelProps = useMemo(
    () => ({
      required: !disabled,
      children: label,
      disabled: disabled,
    }),
    [label, disabled]
  );
  return (
    <InputBase labelProps={labelProps} description={caption} hidden={hidden}>
      <FormRadioButton<TFieldValues>
        disabled={disabled}
        vertical
        rules={rules}
        name={name}
        inputId={inputId}
        control={control}
        options={options}
      />
    </InputBase>
  );
};

InnerApReportFormInputReceiptType.displayName =
  'InnerApReportFormInputReceiptType';

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export const ApReportFormInputReceiptType = memo((props) => (
  <InnerApReportFormInputReceiptType {...props} />
)) as typeof InnerApReportFormInputReceiptType;
ApReportFormInputReceiptType.displayName = 'ApReportFormInputReceiptType';
