import { ApPayeeRef } from '@/components/ApPayee';
import { updateSelectedApPayee } from '@/components/ApPayee/useChangeApPayee';
import { Props as InstantPayeeFormProps } from '@/components/InstantPayeeForm';
import { ApReportFormInputInvoiceInstantPayee } from '@/components/ReportForm/ApReportFormInputInvoiceInstantPayee';
import { ApReportFormInputPayeeSelect } from '@/components/ReportForm/ApReportFormInputPayeeSelect';
import { InputBaseType } from '@/components/ReportForm/InputBase/types';
import { useChangePayeeActions } from '@/components/ReportForm/InstantPayeeSwitcher/hooks/useChangePayeeActions';
import { useChangePayeeValue } from '@/components/ReportForm/InstantPayeeSwitcher/hooks/useChangePayeeValue';
import { useIsActiveInstantPayee } from '@/components/ReportForm/InstantPayeeSwitcher/hooks/useIsActiveInstantPayee';
import { ChangeApPayeeDialog } from '@/components/ReportForm/Modals/ChangeXXX';
import { memo, useCallback, useEffect, useMemo, useRef } from 'react';
import {
  Control,
  FieldPath,
  FieldValues,
  useFormContext,
  useFormState,
  useWatch,
} from 'react-hook-form';

export type Props<TFieldValues extends FieldValues> = Omit<
  InputBaseType<TFieldValues>,
  'required'
> & {
  control: Control<TFieldValues>;
  className?: string;
  bookDateName: FieldPath<TFieldValues>;
  dueDateName: FieldPath<TFieldValues>;
  isInstantPayeeEnabled: boolean;
} & InstantPayeeFormProps<TFieldValues>;

const InnerInstantPayeeSwitcher = <TFieldValues extends FieldValues>({
  control,
  instantPayeeFieldNames,
  description: instantPayeeFieldCaption,
  name,
  label,
  inputId,
  caption,
  bookDateName,
  dueDateName,
  isInstantPayeeEnabled,
}: Props<TFieldValues>) => {
  const { clearErrors } = useFormContext();
  const { errors } = useFormState({ control, name });
  const isActiveInstantPayee = useIsActiveInstantPayee<TFieldValues>({
    control,
    name,
  });
  const InstantPayeeForm = useMemo(() => {
    if (isActiveInstantPayee && isInstantPayeeEnabled) {
      return (
        <ApReportFormInputInvoiceInstantPayee<TFieldValues>
          required
          instantPayeeFieldNames={instantPayeeFieldNames}
          description={instantPayeeFieldCaption}
          control={control}
        />
      );
    } else {
      return null;
    }
  }, [
    control,
    instantPayeeFieldCaption,
    instantPayeeFieldNames,
    isActiveInstantPayee,
    isInstantPayeeEnabled,
  ]);
  useEffect(() => {
    if (!isActiveInstantPayee && Object.keys(errors).length) {
      Object.values(instantPayeeFieldNames).forEach((fieldName) => {
        clearErrors(fieldName);
      });
    }
  }, [errors, clearErrors, instantPayeeFieldNames, isActiveInstantPayee]);
  const inputRef = useRef<ApPayeeRef | null>(null);
  // NOTE: apiが正常になれば↓に変えたい
  // const fetchApPayeeDetail = useLazyGetApPayeeDetail();
  const onUpdateSelectedApPayee = useCallback(
    async (selectedValue: string | undefined) => {
      if (!selectedValue) {
        updateSelectedApPayee(undefined);
      } else {
        const apPayee = await inputRef.current?.findDetails(selectedValue);
        updateSelectedApPayee(apPayee ?? undefined);
      }
    },
    []
  );
  const payeeId = useWatch<TFieldValues>({ control, name });
  useEffect(() => {
    onUpdateSelectedApPayee(payeeId);
  }, [onUpdateSelectedApPayee, payeeId]);

  const { open, onClose, onDialogClick } = useChangePayeeActions<TFieldValues>({
    control,
    name,
    bookDateName,
    dueDateName,
  });
  useChangePayeeValue({ bookDateName, dueDateName });
  return (
    <>
      <ApReportFormInputPayeeSelect<TFieldValues>
        control={control}
        name={name}
        inputId={inputId}
        label={label}
        caption={caption}
        required
        inputRef={inputRef}
        instantPayeeActivated={isInstantPayeeEnabled}
      />
      {InstantPayeeForm}
      <ChangeApPayeeDialog
        open={open}
        onClose={onClose}
        onClick={onDialogClick}
      />
    </>
  );
};

InnerInstantPayeeSwitcher.displayName = 'InnerInstantPayeeSwitcher';

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export const InstantPayeeSwitcher = memo((props) => (
  <InnerInstantPayeeSwitcher {...props} />
)) as typeof InnerInstantPayeeSwitcher;
InstantPayeeSwitcher.displayName = 'InstantPayeeSwitcher';
