import {
  Label as BaseLabel,
  LabelProps,
} from '@moneyforward/ap-frontend-components';
import classnames from 'classnames/bind';
import { FC, memo } from 'react';
import styles from './Label.module.scss';

const cx = classnames.bind(styles);

export type Props = Omit<LabelProps, 'text' | 'className'>;

export const Label: FC<Props> = memo(({ children, ...rest }) => {
  return (
    <BaseLabel
      {...rest}
      containerClassName={cx(styles['container'])}
      labelClassName={cx(styles['labelContent'])}
    >
      {children}
    </BaseLabel>
  );
});
Label.displayName = 'Label';
