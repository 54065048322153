import { useSelectedApPayee } from '@/components/ApPayee/useChangeApPayee';
import {
  InvoiceActionMenu,
  InvoiceActionMenuProps,
} from '@/components/RelationActionMenu';
import { useInvoice } from '@/components/ReportForm/ApReportFormInputInvoiceFileField/hooks';
import { InputBase, LabelProps } from '@/components/ReportForm/InputBase';
import { InputBaseType } from '@/components/ReportForm/InputBase/types';
import { DownloadObject } from '@/hooks/useDownload';
import { useTranslation } from '@/i18n';
import { FormController } from '@moneyforward/ap-frontend-components';
import { memo, useCallback, useMemo } from 'react';
import { ControllerRenderProps, FieldValues, Path } from 'react-hook-form';

export type Props<TFieldValues extends FieldValues> =
  InputBaseType<TFieldValues> & {
    canDelete: boolean;
  };

const InnerApReportFormInputInvoiceFileField = <
  TFieldValues extends FieldValues
>({
  control,
  name,
  inputId,
  label,
  tooltip,
  hidden,
  caption,
  canDelete,
  required = false,
  ...rest
}: Props<TFieldValues>) => {
  const { t } = useTranslation();
  const memorizedLabel: LabelProps = useMemo(
    () => ({ required, children: label, tooltip }),
    [label, required, tooltip]
  );
  const rules: { required: string | boolean } = useMemo(
    () => ({ required: required ? t('required') : false }),
    [required, t]
  );
  const { invoice, onSelected, onDelete, ConfirmModal, isEditInvoice } =
    useInvoice<TFieldValues>({
      name,
      canDelete,
    });
  const onWrapDelete = useCallback(() => {
    return onDelete;
  }, [onDelete]);
  const files: DownloadObject[] = useMemo(() => {
    if (!invoice) return [];
    if (invoice instanceof File) {
      return [invoice];
    }
    return [invoice.file];
  }, [invoice]);

  const visibleDeleteButton = useMemo(
    () => canDelete || isEditInvoice.change,
    [canDelete, isEditInvoice]
  );
  const selectedPayee = useSelectedApPayee();
  const payee = useMemo(() => {
    if (selectedPayee && selectedPayee.id && selectedPayee.name) {
      return {
        id: selectedPayee.id,
        name: selectedPayee.name,
      };
    }
    return undefined;
  }, [selectedPayee]);

  return (
    <>
      <InputBase
        labelProps={memorizedLabel}
        description={caption}
        files={files}
        onDelete={onWrapDelete}
        visibleDeleteButton={visibleDeleteButton}
        hidden={hidden}
      >
        <FormController
          control={control}
          name={name}
          inputId={inputId}
          rules={rules}
          {...rest}
        >
          {(fields) => {
            return (
              <WrapInvoiceActionMenu<TFieldValues>
                fields={fields}
                onSelected={onSelected}
                inputId={inputId}
                payee={payee}
              />
            );
          }}
        </FormController>
      </InputBase>
      <ConfirmModal />
    </>
  );
};
InnerApReportFormInputInvoiceFileField.displayName =
  'InnerApReportFormInputInvoiceFileField';

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export const ApReportFormInputInvoiceFileField = memo(
  InnerApReportFormInputInvoiceFileField
) as typeof InnerApReportFormInputInvoiceFileField;

type WrapInvoiceActionMenuProps<TFieldValues extends FieldValues> = {
  fields: ControllerRenderProps<TFieldValues, Path<TFieldValues>>;
  inputId: string;
} & Omit<InvoiceActionMenuProps, 'container'>;

const WrapInvoiceActionMenu = <TFieldValues extends FieldValues>({
  fields,
  inputId,
  onSelected,
  ...rest
}: WrapInvoiceActionMenuProps<TFieldValues>) => {
  return (
    <>
      <InvoiceActionMenu container={null} onSelected={onSelected} {...rest} />
      <input hidden id={inputId} {...fields} />
    </>
  );
};
