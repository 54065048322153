import {
  EditFile,
  useChangeFile,
} from '@/components/ReportForm/hooks/useChangeFile';
import { useGetFormValue } from '@/components/ReportForm/hooks/useFormValue';
import {
  useDeleteMfFile,
  useGetMfFile,
} from '@/context/services/mf_file/MfFile.service';
import { typeGuardUploadFile } from '@/context/services/reportsType/invoiceReports/type-guard';
import { InvoiceReportMfFile } from 'ap-openapi';
import { useCallback, useMemo } from 'react';
import { Control, FieldPath, FieldValues, useWatch } from 'react-hook-form';

type UseFileArgs<TFieldValues extends FieldValues> = {
  name: FieldPath<TFieldValues>;
  control: Control<TFieldValues>;
  canDelete: boolean;
  mfFile: InvoiceReportMfFile | undefined;
};

type MfFile = {
  file: File | null;
  mfFileId?: string;
};

const typeGuardMfFile = (value: unknown): value is MfFile => {
  if (!value) return false;
  return Object.hasOwn(value, 'mfFileId');
};

type ReturnUseFile = {
  file: File | MfFile | null;
  onSelected: (value: MfFile | File | null) => void;
  onDelete: () => Promise<void>;
  isEditFile: EditFile;
};

export const useFile = <TFieldValues extends FieldValues>({
  name,
  control,
  canDelete,
  mfFile,
}: UseFileArgs<TFieldValues>): ReturnUseFile => {
  const { defaultValue, onChange } = useGetFormValue<
    TFieldValues,
    File | string | null
  >(name);
  const onWrapChange = useCallback(
    (value: MfFile | File | null) => {
      if (typeGuardMfFile(value)) {
        onChange(value.mfFileId!);
      } else {
        onChange(value);
      }
    },
    [onChange]
  );
  const formValue = useWatch({ name, control });
  const mfFileId = useMemo(
    () => (typeof defaultValue === 'string' ? defaultValue : undefined),
    [defaultValue]
  );
  const fileBlob = useGetMfFile(mfFileId, Boolean(mfFileId));
  const wrapDefaultValue: MfFile | null = useMemo(() => {
    if (typeof defaultValue === 'string') {
      if (mfFile) {
        const oriFile: MfFile = {
          file: new File([fileBlob], mfFile.name ?? '', {
            type: mfFile.content_type,
          }),
          mfFileId: mfFile.id,
        };
        return oriFile;
      }
    }
    return null;
  }, [defaultValue, mfFile, fileBlob]);
  const {
    isEditFile,
    defaultFile,
    handleRestoreDefaultFile,
    handleDeleteDefaultFile,
    handleChangeFile,
  } = useChangeFile<MfFile | File | null>(wrapDefaultValue, onWrapChange);

  const deleteMfFile = useDeleteMfFile();
  const onDelete = useCallback(async () => {
    if (!formValue) return;
    // 削除できるかつ初期データ(mf_file)でかつ初期表示から変更されていない場合
    if (canDelete && defaultValue && defaultValue === formValue) {
      if (confirm()) {
        if (mfFileId) {
          await deleteMfFile(mfFileId);
        }
        handleDeleteDefaultFile();
      }
    } else {
      if (defaultFile) {
        handleRestoreDefaultFile();
      } else {
        handleChangeFile(null);
      }
    }
  }, [
    formValue,
    canDelete,
    defaultValue,
    mfFileId,
    handleDeleteDefaultFile,
    deleteMfFile,
    defaultFile,
    handleRestoreDefaultFile,
    handleChangeFile,
  ]);
  const file: File | null = useMemo(() => {
    const value: unknown =
      typeof formValue === 'string' ? wrapDefaultValue : formValue;
    if (typeGuardUploadFile(value)) {
      const file = value;
      const v = (Array.isArray(file) ? file[0] : file)?.originFileObj;
      return v ? (v satisfies File) : null;
    } else if (typeGuardMfFile(value)) {
      return value.file;
    }
    return null;
  }, [formValue, wrapDefaultValue]);
  return useMemo(
    () =>
      ({
        file: file,
        onSelected: handleChangeFile,
        onDelete,
        isEditFile,
      } satisfies ReturnUseFile),
    [file, handleChangeFile, isEditFile, onDelete]
  );
};
