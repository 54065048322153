import { useGetExItem } from '@/features/InvoiceReport/Edit/components/InvoiceTransaction/hooks/useGetExItem';
import {
  InvoiceTransaction,
  PaymentRequestForm,
} from '@/features/InvoiceReport/Edit/type';
import { useTranslation } from '@/i18n';
import yup from '@/libs/validation';
import { isWarn, parseErrorSchema } from '@/utils/errors';
import { ExItemSettingAlertKindEnum } from 'ap-openapi';
import { Dayjs } from 'dayjs';
import { useCallback, useMemo } from 'react';
import { FieldError, RegisterOptions } from 'react-hook-form';
import { ValidationError } from 'yup';

// InvoiceTable内の少数入力フォームの最小値
// 0以上を入力させたいので0.001にしている
const MIN_WHEN_DECIMALS = 0.001;
type DepsExItem =
  | 'deptId'
  | 'projectCodeId'
  | 'memo'
  | 'hasWithholdingIncomeTax';
const typeGuardDepsExItem = (
  value: string | undefined
): value is DepsExItem => {
  if (value === undefined) return false;
  return [
    'deptId',
    'projectCodeId',
    'memo',
    'hasWithholdingIncomeTax',
  ].includes(value);
};

type TestContext = {
  createError: (
    params?: yup.CreateErrorOptions | undefined
  ) => yup.ValidationError;
  parent: InvoiceTransaction;
  path: string;
};

// react-hook-formに用意されていない、自作のバリデーション関数
export const customValidation = {
  nonZero: (value: string) => Number.parseFloat(value) !== 0,
  integer: (value: string) => Number.isInteger(Number.parseFloat(value)),
  positive: (value: string) => Number.parseFloat(value) > 0,
  nonZeroInteger: (value: string) =>
    customValidation.nonZero(value) && customValidation.integer(value),
};

export const useValidationRules = (
  isTaxIncluded: boolean,
  isPermitMinusTransaction: boolean,
  canDisplayTransactionDetail: boolean
) => {
  const { t } = useTranslation();
  const { getAlertKind } = useGetExItem();
  const byExItemAlert = useCallback(
    (
      value: string | number | null | undefined,
      invoiceTransaction: InvoiceTransaction,
      formName: string
    ): { type: 'error' | 'warn'; message: string } | undefined => {
      const { exItemId } = invoiceTransaction;
      const invoiceTransactionsNameRegExp =
        /invoiceTransactions\[\d.*\]\.(?<name>.*)/gm;
      const { name } = invoiceTransactionsNameRegExp.exec(formName)?.groups ?? {
        name: formName,
      };
      if (!typeGuardDepsExItem(name)) {
        return undefined;
      }
      const type = name;
      const alertKind = getAlertKind(exItemId);
      const warningMessage = {
        deptId: t('invoice_transaction_validation_warning_message_dept_id'),
        projectCodeId: t(
          'invoice_transaction_validation_warning_message_project_code_id'
        ),
        memo: t('invoice_transaction_validation_warning_message_memo'),
        hasWithholdingIncomeTax: t(
          'invoice_transaction_validation_warning_message_has_withholding_income_tax'
        ),
      };
      const requiredMessage = {
        deptId: t('invoice_transaction_validation_required_message_dept_id'),
        projectCodeId: t(
          'invoice_transaction_validation_required_message_project_code_id'
        ),
        memo: t('invoice_transaction_validation_required_message_memo'),
        hasWithholdingIncomeTax: t(
          'invoice_transaction_validation_required_has_withholding_income_tax'
        ),
      };
      if (!alertKind) return undefined;
      switch (alertKind[type]) {
        case ExItemSettingAlertKindEnum.error: {
          if (value) {
            return undefined;
          } else {
            return {
              type: 'error',
              message: requiredMessage[type],
            };
          }
        }
        case ExItemSettingAlertKindEnum.warning: {
          return !value || value === ''
            ? {
                type: 'warn',
                message: warningMessage[type],
              }
            : undefined;
        }
        case ExItemSettingAlertKindEnum.ignored: {
          return undefined;
        }
        default:
          return undefined;
      }
    },
    [getAlertKind, t]
  );
  const byExItemTest = useMemo(
    () =>
      [
        'byExItemAlert',
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        function method<V extends string | number | null | undefined>(
          value: V,
          { createError, parent, path }: TestContext
        ) {
          const result = byExItemAlert(value, parent, path);
          if (result !== undefined) {
            return createError({
              path,
              message: result.message,
              type: result.type,
            });
          }
          return true;
        },
      ] as const,
    [byExItemAlert]
  );
  const validationSchema: Record<
    keyof InvoiceTransaction,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    yup.ISchema<any, any, any, any>
  > = useMemo(
    () => ({
      id: yup.string().default(''),
      number: yup.string().default(''),
      dealDate: yup.mixed<Dayjs>().required(
        t('required_select', {
          name: t('invoice_transaction_deal_date'),
        })
      ),
      name: yup
        .string()
        .required(t('required_input', { name: t('invoice_transaction_name') }))
        .max(
          100,
          t('invoice_reports.validation.message.maxLength', {
            name: t('invoice_transaction_name'),
            value: 100,
          })
        ),
      exItemId: yup.string().required(
        t('required_select', {
          name: t('invoice_transaction_expense_account'),
        })
      ),
      unit: yup.number().when('canDisplay', {
        is: () => canDisplayTransactionDetail,
        then: (schema) =>
          schema
            .when('isRequired', {
              is: () => !isTaxIncluded,
              then: (schema) => schema.required(t('required')),
              otherwise: (schema) =>
                schema
                  .nullable()
                  .transform((value, originalValue) =>
                    String(originalValue).trim() === '' ? null : value
                  ),
            })
            .when('isPermitMinusTransaction', {
              is: () => isPermitMinusTransaction,
              then: (schema) =>
                schema.nonZero(t, t('invoice_transaction_unit')),
            })
            .when('isPermitMinusTransaction', {
              is: () => !isPermitMinusTransaction,
              then: (schema) =>
                schema.positive(t, t('invoice_transaction_unit')),
            }),
      }),
      taxIncludedUnit: yup.number().when('canDisplay', {
        is: () => canDisplayTransactionDetail,
        then: (schema) =>
          schema
            .when('isRequired', {
              is: () => isTaxIncluded,
              then: (schema) => schema.required(t('required')),
              otherwise: (schema) =>
                schema
                  .nullable()
                  .transform((value, originalValue) =>
                    String(originalValue).trim() === '' ? null : value
                  ),
            })
            .when('isPermitMinusTransaction', {
              is: () => isPermitMinusTransaction,
              then: (schema) =>
                schema.nonZero(t, t('invoice_transaction_unit_tax_include')),
            })
            .when('isPermitMinusTransaction', {
              is: () => !isPermitMinusTransaction,
              then: (schema) =>
                schema.positive(t, t('invoice_transaction_unit_tax_include')),
            }),
      }),
      quantity: yup
        .number()
        .default(1)
        .when('canDisplay', {
          is: () => canDisplayTransactionDetail,
          then: (schema) =>
            schema
              .when('isRequired', {
                is: () => canDisplayTransactionDetail,
                then: (schema) => schema.required(t('required')),
                otherwise: (schema) =>
                  schema
                    .nullable()
                    .transform((value, originalValue) =>
                      String(originalValue).trim() === '' ? null : value
                    ),
              })
              .min(
                MIN_WHEN_DECIMALS,
                t('invoice_reports.validation.message.min', {
                  name: t('invoice_transaction_quantity'),
                  value: MIN_WHEN_DECIMALS,
                })
              ),
        }),
      totalValue: yup
        .number()
        .default(0)
        .when('canDisplay', {
          is: () => !isTaxIncluded,
          then: (schema) =>
            schema
              .required(t('required'))
              .when('isPermitMinusTransaction', {
                is: () => isPermitMinusTransaction,
                then: (schema) =>
                  schema.nonZeroInteger(
                    t,
                    t('invoice_transaction_total_value')
                  ),
              })
              .when('isPermitMinusTransaction', {
                is: () => !isPermitMinusTransaction,
                then: (schema) =>
                  schema
                    .positive(t, t('invoice_transaction_total_value'))
                    .integer(t, t('invoice_transaction_total_value')),
              }),
        }),
      taxIncludedTotalValue: yup
        .number()
        .default(0)
        .when('canDisplay', {
          is: () => isTaxIncluded,
          then: (schema) =>
            schema
              .required(t('required'))
              .when('isPermitMinusTransaction', {
                is: () => isPermitMinusTransaction,
                then: (schema) =>
                  schema.nonZeroInteger(
                    t,
                    t('invoice_transaction_total_value_tax_include')
                  ),
              })
              .when('isPermitMinusTransaction', {
                is: () => !isPermitMinusTransaction,
                then: (schema) =>
                  schema
                    .positive(
                      t,
                      t('invoice_transaction_total_value_tax_include')
                    )
                    .integer(
                      t,
                      t('invoice_transaction_total_value_tax_include')
                    ),
              }),
        }),
      drExciseId: yup.string().nullable(),
      invoiceKind: yup.string().nullable(),
      exciseValue: yup
        .number()
        .nullable()
        .transform((value, originalValue) =>
          String(originalValue).trim() === '' ? null : value
        ),
      hasWithholdingIncomeTax: yup
        .boolean()
        .nullable()
        .test(
          'byExItemTest',
          function method(value, { createError, parent, path }) {
            if (value) {
              const { withholdingIncomeTax } = parent;
              const result = byExItemAlert(withholdingIncomeTax, parent, path);
              if (result !== undefined) {
                return createError({
                  path,
                  message: result.message,
                  type: result.type,
                });
              }
            }
            return true;
          }
        ),
      withholdingIncomeTax: yup.number().nullable(),
      memo: yup
        .string()
        .nullable()
        .when('canDisplay', {
          is: () => canDisplayTransactionDetail,
          then: (schema) => schema.test(...byExItemTest),
        }),
      deptId: yup
        .string()
        .nullable()
        .test(...byExItemTest),
      projectCodeId: yup
        .string()
        .nullable()
        .test(...byExItemTest),
      currency: yup.string().nullable(),
      useCustomJPYRate: yup.bool().nullable(),
      jpyRate: yup
        .number()
        .nullable()
        .transform((value, originalValue) =>
          String(originalValue).trim() === '' ? null : value
        ),
      crItemId: yup.string().required(t('required')),
      crSubItemId: yup.string().nullable(),
      rowKey: yup.string(),
    }),
    [
      byExItemAlert,
      byExItemTest,
      canDisplayTransactionDetail,
      isPermitMinusTransaction,
      isTaxIncluded,
      t,
    ]
  );

  const schema = useMemo(
    () => yup.object().shape(validationSchema),
    [validationSchema]
  );
  const handleValidation = useCallback(
    async (
      name: keyof InvoiceTransaction,
      index: number,
      value: InvoiceTransaction
    ): Promise<FieldError | true> => {
      try {
        await schema.validate(value, {
          abortEarly: false,
        });
        return true;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (e: any) {
        if (e instanceof ValidationError) {
          const errFields = parseErrorSchema(e, true);
          const err = errFields[name];
          return err || true;
        }
        return e?.message || t('system_error_input');
      }
    },
    [schema, t]
  );
  const validate = useCallback(
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    (name: keyof InvoiceTransaction, index: number) =>
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      ({
        warningOrError: {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          validate: async (value: any, form: PaymentRequestForm) => {
            const result = await handleValidation(
              name,
              index,
              form.invoiceTransactions[index]!
            );
            if (typeof result === 'boolean') return true;
            if (isWarn(result.type)) {
              return result.message;
            }
            return {
              error: true,
              message: result.message,
            };
          },
        },
      } as RegisterOptions<PaymentRequestForm>),
    [handleValidation]
  );

  return useMemo(
    () => ({
      validationSchema,
      yupSchema: schema,
      dealDateRule: (index: number) => ({
        ...validate('dealDate', index),
      }),
      nameRule: (index: number) => ({
        ...validate('name', index),
      }),
      exItemIdRule: (index: number) => ({
        ...validate('exItemId', index),
      }),
      quantityRule: (index: number) => ({
        ...validate('quantity', index),
      }),
      unitRule: (index: number) => ({
        ...validate('unit', index),
      }),
      taxIncludedUnitRule: (index: number) => ({
        ...validate('taxIncludedUnit', index),
      }),
      taxIncludedTotalValueRule: (index: number) => ({
        ...validate('taxIncludedTotalValue', index),
      }),
      totalValueRule: (index: number) => ({
        ...validate('totalValue', index),
      }),
      deptIdRule: (index: number) => ({
        ...validate('deptId', index),
      }),
      projectCodeIdRule: (index: number) => ({
        ...validate('projectCodeId', index),
      }),
      memoRule: (index: number) => ({
        ...validate('memo', index),
      }),
      hasWithholdingIncomeTaxRule: (index: number) => ({
        ...validate('hasWithholdingIncomeTax', index),
      }),
      withholdingIncomeTaxRule: (index: number) => ({
        ...validate('withholdingIncomeTax', index),
      }),
      crItemIdRule: (index: number) => ({
        ...validate('crItemId', index),
      }),
      currencyRule: (index: number) => ({
        ...validate('currency', index),
      }),
    }),
    [schema, validate, validationSchema]
  );
};
