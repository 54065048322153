import {
  InputOption,
  InstantPayee,
  InvoiceReportDetailFormInputsResponse,
  ReportFormInputType,
  UpdateReportBodyParamsFormInputValue,
  UpdateReportBodyParamsFormInputValueOptionsAccountType,
} from 'ap-openapi';
import { stringToDayjs, toISODateString } from 'date-util';
import { useCallback, useMemo } from 'react';
import { InstantPayeeForm, PayeeForm } from './type';

export const findReportFormInputType = (
  reportFormInputs: InvoiceReportDetailFormInputsResponse[],
  typeName: ReportFormInputType
) => {
  return reportFormInputs.find((item) => item.type === typeName);
};

const findDateType = (
  reportFormInputs: InvoiceReportDetailFormInputsResponse[],
  typeName: ReportFormInputType
) => {
  const input = findReportFormInputType(reportFormInputs, typeName);
  return {
    value: stringToDayjs(
      input?.input_values?.[0]?.value ?? input?.default_value
    ),
    input,
  };
};

export const useConvertApiDataToPayeeFormName = (
  reportFormInputs: InvoiceReportDetailFormInputsResponse[]
): PayeeForm => {
  // 支払種別
  const invoicePayeeType = useMemo(
    () =>
      reportFormInputs.find(
        (item) => item.type === 'ApReportFormInputInvoicePayeeType'
      ),
    [reportFormInputs]
  );
  const selectedInvoicePayeeType = useMemo(() => {
    return (
      invoicePayeeType?.input_values?.[0]?.value ??
      invoicePayeeType?.default_value
    );
  }, [invoicePayeeType?.default_value, invoicePayeeType?.input_values]);
  const isInstantPayeeType = selectedInvoicePayeeType === '1006';
  const selectedPayee: { id: string | undefined; value: string | undefined } =
    useMemo(() => {
      if (isInstantPayeeType)
        return { value: selectedInvoicePayeeType, id: undefined };
      const payee = findReportFormInputType(
        reportFormInputs,
        'ApReportFormInputInvoicePayeeSelect'
      );
      return {
        id: payee?.input_values?.[0]?.id,
        value: payee?.input_values?.[0]?.value,
      };
    }, [isInstantPayeeType, reportFormInputs, selectedInvoicePayeeType]);
  const selectedInstantPayee: InstantPayeeForm | undefined = useMemo(() => {
    if (!isInstantPayeeType) return undefined;
    const instantPayee: InstantPayee | undefined = reportFormInputs.find(
      (item) => item.type === 'ApReportFormInputInvoiceInstantPayee'
    )?.input_additional_values?.[0];
    return {
      id: instantPayee?.id,
      name: instantPayee?.name,
      bankId: instantPayee?.bank?.id,
      bankName: instantPayee?.bank?.name,
      bankBranchId: instantPayee?.bank_branch?.id,
      bankBranchName: instantPayee?.bank_branch?.name,
      accountType: instantPayee?.account_type,
      number: instantPayee?.number,
      holderNameKana: instantPayee?.holder_name_kana,
    } satisfies InstantPayeeForm;
  }, [isInstantPayeeType, reportFormInputs]);
  const { value: bookDate, input: bookDateFormInput } = useMemo(
    () =>
      findDateType(reportFormInputs, 'ApReportFormInputInvoiceBookDateField'),
    [reportFormInputs]
  );
  const { value: dueDate, input: dueDateFormInput } = useMemo(
    () =>
      findDateType(reportFormInputs, 'ApReportFormInputInvoiceDueDateField'),
    [reportFormInputs]
  );
  const payeeInput = findReportFormInputType(
    reportFormInputs,
    'ApReportFormInputInvoicePayeeSelect'
  );

  return useMemo(
    () =>
      ({
        ...selectedPayee,
        instantPayee: selectedInstantPayee,
        bookDate,
        dueDate,
        reportForm: {
          payee: {
            reportFormInputId: payeeInput?.id,
            inputValueId: payeeInput?.input_values?.[0]?.id,
          },
          bookDate: {
            reportFormInputId: bookDateFormInput?.id,
            inputValueId: bookDateFormInput?.input_values?.[0]?.id,
          },
          dueDate: {
            reportFormInputId: dueDateFormInput?.id,
            inputValueId: dueDateFormInput?.input_values?.[0]?.id,
          },
        },
      } satisfies PayeeForm),
    [
      bookDate,
      bookDateFormInput?.id,
      bookDateFormInput?.input_values,
      dueDate,
      dueDateFormInput?.id,
      dueDateFormInput?.input_values,
      payeeInput?.id,
      payeeInput?.input_values,
      selectedInstantPayee,
      selectedPayee,
    ]
  );
};

export const useConvertPayeeFormToRequestForm = () => {
  return useCallback(
    (
      input: PayeeForm,
      payeeTypeInfo: InvoiceReportDetailFormInputsResponse,
      instantPayeeInfo: InputOption | undefined
    ): UpdateReportBodyParamsFormInputValue[] => {
      const payeeType: UpdateReportBodyParamsFormInputValue = {
        report_form_input_id: payeeTypeInfo.id,
        id: payeeTypeInfo.input_values?.[0]?.id,
        value:
          input.value === '1006' && input.instantPayee !== undefined
            ? '1006'
            : '1005',
      };
      const result: UpdateReportBodyParamsFormInputValue[] = [payeeType];
      if (payeeType.value === '1005') {
        const payee: UpdateReportBodyParamsFormInputValue = {
          report_form_input_id: input.reportForm.payee.reportFormInputId,
          id: input.reportForm.payee.inputValueId,
          value: input.value,
        };
        result.push(payee);
      } else {
        const instantPayee: UpdateReportBodyParamsFormInputValue = {
          report_form_input_id: instantPayeeInfo?.key,
          options: {
            name: input.instantPayee?.name,
            bank_id: input.instantPayee?.bankId,
            bank_branch_id: input.instantPayee?.bankBranchId,
            // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
            account_type: input.instantPayee
              ?.accountType as UpdateReportBodyParamsFormInputValueOptionsAccountType,
            number: input.instantPayee?.number,
            holder_name_kana: input.instantPayee?.holderNameKana,
          },
        };
        result.push(instantPayee);
      }
      const bookDate: UpdateReportBodyParamsFormInputValue = {
        report_form_input_id: input.reportForm.bookDate.reportFormInputId,
        id: input.reportForm.bookDate.inputValueId,
        value: toISODateString(input.bookDate ?? ''),
      };
      const dueDate: UpdateReportBodyParamsFormInputValue = {
        report_form_input_id: input.reportForm.dueDate.reportFormInputId,
        id: input.reportForm.dueDate.inputValueId,
        value: toISODateString(input.dueDate ?? ''),
      };
      return result.concat([bookDate, dueDate]);
    },
    []
  );
};
