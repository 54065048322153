import { UploadFile } from '@moneyforward/ap-frontend-components';
import { createObjectURL } from 'packages-hooks';
import { useCallback } from 'react';

type FileObject = {
  value: Blob;
  fileName: string;
};

export type DownloadObject = File | FileObject | UploadFile;

const isFileObject = (value: DownloadObject): value is FileObject =>
  'value' in value && 'fileName' in value;
const isUploadFile = (value: DownloadObject): value is UploadFile =>
  'uid' in value && 'name' in value && 'originFileObj' in value;

const convert = (value: DownloadObject) => {
  if (value instanceof File) {
    const fileName = value.name;
    const blob = new Blob([value], { type: value.type });
    return { fileName, blob };
  } else if (isFileObject(value)) {
    const fileName = value.fileName;
    return { fileName, blob: value.value };
  } else if (isUploadFile(value)) {
    return {
      fileName: value.fileName ?? value.originFileObj?.name ?? value.name,
      blob: new Blob([value.originFileObj!], {
        type: value.originFileObj?.type,
      }),
    };
  }
  throw new Error(`Un support type: ${typeof value}`);
};

export const getFileName = (value: DownloadObject) => {
  const { fileName } = convert(value);
  return fileName;
};

export const useDownload = () => {
  return useCallback((value: DownloadObject) => {
    const { fileName, blob } = convert(value);
    const url = createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    link.remove();
  }, []);
};
