export type InvoiceTransactionMapping = {
  dealDate: string;
  name: string;
  exItemName: string;
  unitPriceExcludingTax: string;
  unitPriceIncludingTax: string;
  quantity: string;
  totalValueExcludingTax: string;
  totalValueIncludingTax: string;
  exciseName: string;
  tax: string;
  withholdingIncomeTax: string;
  memo: string;
  deptName: string;
  projectName: string;
  crItemName: string;
  crSubItemName: string;
  currency: string;
  jpyRate: string;
};

export type CustomInvoiceTransactionMapping = InvoiceTransactionMapping & {
  invoiceKind?: string;
};

export const invoiceTransactionMapping: InvoiceTransactionMapping = {
  dealDate: '取引日',
  name: '品目',
  exItemName: '経費科目',
  unitPriceExcludingTax: '単価（税抜）',
  unitPriceIncludingTax: '単価（税込）',
  quantity: '数量',
  totalValueExcludingTax: '金額（税抜）',
  totalValueIncludingTax: '金額（税込）',
  exciseName: '税区分',
  tax: '消費税額',
  withholdingIncomeTax: '源泉徴収額',
  memo: 'メモ',
  deptName: '費用負担部門',
  projectName: 'プロジェクト',
  crItemName: '貸方科目',
  crSubItemName: '貸方補助科目',
  currency: '通貨',
  jpyRate: 'レート',
};

// For using in /approving_reports and /admin_invoice_reports
export const customInvoiceTransactionMapping: CustomInvoiceTransactionMapping =
  {
    dealDate: '取引日',
    name: '品目',
    exItemName: '経費科目',
    unitPriceExcludingTax: '単価（税抜）',
    unitPriceIncludingTax: '単価（税込）',
    quantity: '数量',
    totalValueExcludingTax: '金額（税抜）',
    totalValueIncludingTax: '金額（税込）',
    exciseName: '税区分',
    invoiceKind: 'インボイス経過措置',
    tax: '消費税額',
    withholdingIncomeTax: '源泉徴収額',
    memo: 'メモ',
    deptName: '費用負担部門',
    projectName: 'プロジェクト',
    crItemName: '貸方科目',
    crSubItemName: '貸方補助科目',
    currency: '通貨',
    jpyRate: 'レート',
  };

export const exportMapping = invoiceTransactionMapping;
export const exportCustomMapping = customInvoiceTransactionMapping;
export const importMapping: Record<string, keyof InvoiceTransactionMapping> =
  Object.entries(invoiceTransactionMapping).reduce(
    (prev, [key, value]) => ({ ...prev, [value]: key }),
    {} satisfies Record<string, string>
  );
export const importCustomMapping: Record<
  string,
  keyof InvoiceTransactionMapping
> = Object.entries(customInvoiceTransactionMapping).reduce(
  (prev, [key, value]) => ({ ...prev, [value]: key }),
  {} satisfies Record<string, string>
);
