import { useTranslation } from '@/i18n';
import { useGlobalContainerRef } from '@/wc/helper/ref';
import {
  ActionMenu,
  ActionMenuItems,
  IconButton,
  IconMoreVert,
} from '@moneyforward/ap-frontend-components';
import classNames from 'classnames';
import { FC, memo, useMemo } from 'react';
import styles from './Actions.module.scss';

const cx = classNames.bind(styles);

export type Props = {
  onOpenDetails: VoidFunction;
  onDownload: VoidFunction;
  onUnlink: VoidFunction;
  isOpenDetails: boolean;
};

export const Actions: FC<Props> = memo(
  ({ onOpenDetails, onDownload, onUnlink, isOpenDetails }) => {
    const { t } = useTranslation();

    const items: ActionMenuItems = useMemo(
      () => [
        {
          label: isOpenDetails ? t('close_details') : t('open_details'),
          onClick: onOpenDetails,
          key: '1',
        },
        {
          label: t('download'),
          onClick: onDownload,
          key: '2',
        },
        {
          label: <span className={cx(styles['unlink'])}>{t('unlink')}</span>,
          onClick: onUnlink,
          key: '3',
        },
      ],
      [isOpenDetails, onDownload, onOpenDetails, onUnlink, t]
    );
    const container = useGlobalContainerRef();
    const memorizedDropdownProps = useMemo(
      () => ({ destroyPopupOnHide: true }),
      []
    );
    return (
      <div className={cx(styles['actionMenuContainer'])}>
        <ActionMenu
          type='custom'
          items={items}
          getPopupContainer={container}
          dropDownProps={memorizedDropdownProps}
        >
          <IconButton inVisible size='xs'>
            <IconMoreVert size={16} />
          </IconButton>
        </ActionMenu>
      </div>
    );
  }
);
Actions.displayName = 'Actions';
