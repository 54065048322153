import { TInvoiceReportDetailFormInputsResponse } from '@/context/services/reportsType/invoiceReports/type';
import { findReportFormInputType } from '@/context/services/reportsType/invoiceReports/useConvertApiDataAndPayeeForm';
import { useMemo } from 'react';

export const useFindInvoicePayeeId = (
  reportFormInputs: TInvoiceReportDetailFormInputsResponse[]
) => {
  return useMemo(
    () =>
      findReportFormInputType(
        reportFormInputs,
        'ApReportFormInputInvoicePayeeType'
      )!,
    [reportFormInputs]
  );
};

export const useFindInvoiceFileId = (
  reportFormInputs: TInvoiceReportDetailFormInputsResponse[]
) => {
  return (
    useMemo(
      () =>
        findReportFormInputType(
          reportFormInputs,
          'ApReportFormInputInvoiceFileField'
        )?.id,
      [reportFormInputs]
    ) ?? ''
  );
};

export const useFindSpecialException = (
  reportFormInputs: TInvoiceReportDetailFormInputsResponse[]
) => {
  return (
    useMemo(
      () =>
        findReportFormInputType(
          reportFormInputs,
          'ApReportFormInputSpecialExceptionStatus'
        )?.id,
      [reportFormInputs]
    ) ?? ''
  );
};
