import { isDayjs } from 'dayjs';
import * as yup from 'yup';

const toNumber = (value: string | number): number => {
  return typeof value === 'number' ? value : Number.parseFloat(value);
};

const customValidation = {
  nonZero: (value: string | number | undefined) =>
    value ? toNumber(value) !== 0 : false,
  integer: (value: string | number | undefined) =>
    value ? Number.isInteger(toNumber(value)) : false,
  positive: (value: string | number | undefined) =>
    value ? toNumber(value) > 0 : false,
  nonZeroInteger: (value: string | number | undefined) =>
    value
      ? customValidation.nonZero(value) && customValidation.integer(value)
      : false,
};

[yup.string, yup.number].forEach((typ) => {
  yup.addMethod(
    //@ts-ignore
    typ,
    'nonZero',
    function method(t: TFunction, label: string, message) {
      const msg = message || t('validation_message_nonZero', { name: label });
      return this.test('nonZero', msg, function validate(value) {
        return customValidation.nonZero(value);
      });
    }
  );
  yup.addMethod(
    //@ts-ignore
    typ,
    'integer',
    function method(t: TFunction, label: string, message) {
      const msg = message || t('validation_message_integer', { name: label });
      return this.test('integer', msg, function validate(value) {
        return customValidation.integer(value);
      });
    }
  );
  yup.addMethod(
    //@ts-ignore
    typ,
    'positive',
    function method(t: TFunction, label: string, message) {
      const msg = message || t('validation_message_positive', { name: label });
      return this.test('positive', msg, function validate(value) {
        return customValidation.positive(value);
      });
    }
  );
  yup.addMethod(
    //@ts-ignore
    typ,
    'nonZeroInteger',
    function method(t: TFunction, label: string, message) {
      const msg =
        message || t('validation_message_nonZeroInteger', { name: label });
      return this.test('nonZeroInteger', msg, function validate(value) {
        return customValidation.nonZeroInteger(value);
      });
    }
  );
});

yup.addMethod(yup.object, 'dayjs', function method(message) {
  return this.test('dayjs', message, function validate(value) {
    if (!value) {
      return true;
    }
    return isDayjs(value);
  });
});

type TFunction = (key: string, obj?: Record<string, unknown>) => string;

/* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any */
declare module 'yup' {
  interface ObjectSchema<
    TIn extends yup.Maybe<yup.AnyObject>,
    TContext = yup.AnyObject,
    TDefault = any,
    TFlags extends yup.Flags = ''
  > {
    dayjs(msg?: yup.Message): this;
  }
  interface StringSchema<TType, TContext, TDefault, TFlags> {
    nonZero(t: TFunction, label: string, msg?: yup.Message): this;
    integer(t: TFunction, label: string, msg?: yup.Message): this;
    positive(t: TFunction, label: string, msg?: yup.Message): this;
    nonZeroInteger(t: TFunction, label: string, msg?: yup.Message): this;
  }
  interface NumberSchema<TType, TContext, TDefault, TFlags> {
    nonZero(t: TFunction, label: string, msg?: yup.Message): this;
    integer(t: TFunction, label: string, msg?: yup.Message): this;
    positive(t: TFunction, label: string, msg?: yup.Message): this;
    nonZeroInteger(t: TFunction, label: string, msg?: yup.Message): this;
  }
}
/* eslint-enable */

// eslint-disable-next-line import/no-default-export
export default yup;
