import { isSuccess } from '@/context/services/type';
import { RegistrationBusinessPersonDataPopoverProps } from '@moneyforward/ap-frontend-components';
import { useGetTpmInvoicesHook } from 'ap-openapi';
import { useCallback, useState } from 'react';

export const useInvoiceRegistrationNumberHooks = () => {
  const [businessPersonData, setBusinessPersonData] =
    useState<RegistrationBusinessPersonDataPopoverProps>({ disabled: true });

  const getTpmInvoices = useGetTpmInvoicesHook();
  const fetchBusinessPersonData = useCallback(
    async (number: string) => {
      try {
        const data = await getTpmInvoices({ number });
        const body = data?.data;
        if (data && !isSuccess(data.status)) {
          return { disabled: true };
        }
        return {
          name: body?.name,
          companyType: body?.kind,
          address: body?.address,
          term: body?.qualified_period?.[0]?.period,
          disabled: false,
        };
      } catch (e) {
        if (e) throw e;
        return { disabled: true };
      }
    },
    [getTpmInvoices]
  );

  const callBackAfterInput13text = useCallback(
    async (value: string) => {
      const result = await fetchBusinessPersonData(value);
      setBusinessPersonData(result);
      return Boolean(result?.name) || '存在しない適格番号です';
    },
    [fetchBusinessPersonData, setBusinessPersonData]
  );

  return {
    callbackOnInput13Text: callBackAfterInput13text,
    businessPersonData,
  };
};
