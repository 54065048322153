import { OfficeMember } from '@/components/OfficeMember';
import { useGetPopupContainer } from '@/components/ReportForm/Container/PopupContainer';
import { InputBase, LabelProps } from '@/components/ReportForm/InputBase';
import { InputBaseType } from '@/components/ReportForm/InputBase/types';
import { useTranslation } from '@/i18n';
import { FormSelectProps } from '@moneyforward/ap-frontend-components';
import { memo, useMemo } from 'react';
import { FieldValues } from 'react-hook-form';

export type Props<TFieldValues extends FieldValues> =
  InputBaseType<TFieldValues>;

const InnerApReportFormInputOfficeMemberSelect = <
  TFieldValues extends FieldValues
>({
  control,
  name,
  inputId,
  label,
  tooltip,
  caption,
  required = false,
  hidden,
}: Props<TFieldValues>) => {
  const labelProps: LabelProps = useMemo(
    () => ({ required, children: label, tooltip }),
    [required, label, tooltip]
  );

  const containerRef = useGetPopupContainer();
  const selectProps: Omit<
    FormSelectProps<TFieldValues>['selectProps'],
    'options'
  > = useMemo(() => {
    return {
      mode: 'single',
      getPopupContainer: containerRef,
    };
  }, [containerRef]);
  const { t } = useTranslation();
  const rules: { required: string | boolean } = useMemo(
    () => ({ required: required ? t('required') : false }),
    [required, t]
  );
  const defaultOptions = useMemo(() => [], []);
  return (
    <InputBase labelProps={labelProps} description={caption} hidden={hidden}>
      <OfficeMember<TFieldValues>
        control={control}
        name={name}
        inputId={inputId}
        selectProps={selectProps}
        defaultOptions={defaultOptions}
        rules={rules}
      />
    </InputBase>
  );
};

InnerApReportFormInputOfficeMemberSelect.displayName =
  'InnerApReportFormInputOfficeMemberSelect';

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export const ApReportFormInputOfficeMemberSelect = memo((props) => (
  <InnerApReportFormInputOfficeMemberSelect {...props} />
)) as typeof InnerApReportFormInputOfficeMemberSelect;
ApReportFormInputOfficeMemberSelect.displayName =
  'ApReportFormInputOfficeMemberSelect';
