import { useBusinessPersonData } from '@/features/InvoiceReport/Edit/components/Context/BusinessPersonDataContext';
import { TGetInvoiceKind } from '@/features/InvoiceReport/Edit/components/InvoiceTransaction/services/type';
import { useCallback } from 'react';
import { useIsDutyFreeContext } from '../../Context/IsDutyFreeContext';
import {
  _getInvoiceKind,
  parseValidityDate,
} from '../services/getInvoiceKind.service';

export const useInvoiceKind = () => {
  const isDutyFree = useIsDutyFreeContext();
  const businessPersonData = useBusinessPersonData();
  const getInvoiceKind = useCallback(
    ({
      dealDate,
      isZeroPer,
      hasSpecialException,
    }: Omit<TGetInvoiceKind, 'tpmData' | 'isDutyFree'>) => {
      return _getInvoiceKind({
        dealDate,
        isZeroPer,
        tpmData: parseValidityDate(businessPersonData),
        hasSpecialException,
        isDutyFree,
      });
    },
    [isDutyFree, businessPersonData]
  );

  return { getInvoiceKind };
};
