import { createSelectItemContext } from '@/context/SelectComponent/Base';
import {
  CrItems,
  GetCrItemsResponse,
  getGetCrItemsQueryKey,
  useGetCrItemsInfinite,
} from 'ap-openapi';
import { FC, PropsWithChildren, memo } from 'react';

export const {
  useFetchPer: useCrItemPer,
  useItems: useCrItems,
  useSetItems: useSetCrItems,
  useCacheKey: useCrItemCache,
  useIsLoading: useIsLoadingCrItems,
  useIsFetching: useIsFetchingCrItems,
  useFindById: useFindCrItemById,
  useFindByName: useFindCrItemByName,
  Provider: BaseCrItemsProvider,
} = createSelectItemContext<
  CrItems,
  GetCrItemsResponse,
  'id',
  'name',
  'name',
  'id'
>(
  100,
  useGetCrItemsInfinite,
  'cr_items',
  'id',
  {
    key: 'id',
    return: 'name',
    defaultVal: '',
  },
  {
    key: 'name',
    return: 'id',
    defaultVal: '',
  },
  getGetCrItemsQueryKey
);

export const CrItemsProvider: FC<PropsWithChildren> = memo(({ children }) => {
  return <BaseCrItemsProvider>{children}</BaseCrItemsProvider>;
});
CrItemsProvider.displayName = 'CrItemsProvider';
