import { createSelectItemContext } from '@/context/SelectComponent/Base';
import {
  Dept,
  GetDeptsResponse,
  getGetDeptsQueryKey,
  useGetDeptsInfinite,
} from 'ap-openapi';
import { FC, PropsWithChildren, memo } from 'react';

export const {
  useFetchPer: useDeptPer,
  useItems: useDepts,
  useSetItems: useSetDepts,
  useCacheKey: useDeptsCacheKey,
  useIsLoading: useIsLoadingDepts,
  useIsFetching: useIsFetchingDepts,
  useFindById: useFindDeptById,
  useFindByName: useFindDeptByName,
  Provider: BaseDeptProvider,
} = createSelectItemContext<
  Dept,
  GetDeptsResponse,
  'id',
  'long_name',
  'long_name',
  'id'
>(
  100,
  useGetDeptsInfinite,
  'depts',
  'id',
  {
    key: 'id',
    return: 'long_name',
    defaultVal: '',
  },
  {
    key: 'long_name',
    return: 'id',
    defaultVal: '',
  },
  getGetDeptsQueryKey
);
export const DeptProvider: FC<PropsWithChildren> = memo(({ children }) => {
  return <BaseDeptProvider>{children}</BaseDeptProvider>;
});
DeptProvider.displayName = 'DeptProvider';
