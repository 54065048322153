import {
  InvoiceKindValues,
  TGetInvoiceKind,
} from '@/features/InvoiceReport/Edit/components/InvoiceTransaction/services/type';
import { RegistrationBusinessPersonDataPopoverProps as BusinessPersonData } from '@moneyforward/ap-frontend-components';
import { UpdateInvoiceTransactionBodyParamAttributesInvoiceKind as InvoiceKind } from 'ap-openapi';
import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);

// Don't import to react directly.
export const _getInvoiceKind = ({
  dealDate,
  isZeroPer,
  tpmData,
  hasSpecialException,
  isDutyFree,
}: TGetInvoiceKind): InvoiceKindValues | '' => {
  // 日付が未設定の場合または免税事業者の場合は空
  // Return empty string if dealDate is undefined or isDutyFree is true
  if (!dealDate || isDutyFree) return '';

  const INVOICE_SYSTEM_START_DATE = '2023/10/01';
  const DEFAULT_VALIDITY_END_DATE = '9999/12/31';
  const invoicingSystemEffectiveDate = dayjs(INVOICE_SYSTEM_START_DATE);
  // 取引日が2023/10/01より前の場合
  // If the dealDate is before 01/10/2023
  if (dealDate.isBefore(invoicingSystemEffectiveDate)) return '';

  // 税区分ゼロ税率対象の場合
  // return not_target if excises has isZeroPer = true
  if (isZeroPer) return InvoiceKind.not_target;

  // デフォルトの有効期限終了日を設定
  // Set default expiry end date because default returning tpmData's validityEndDate is empty
  const defaultValidityTo =
    tpmData.validityEndDate || DEFAULT_VALIDITY_END_DATE;
  const validityStartDateObj = dayjs(tpmData.validityStartDate);
  const validityEndDateObj = dayjs(defaultValidityTo);

  // 適格判定：dealDateが2023/10/01以降、適番の有効期限が有効期間内
  // Qualified decision: dealDate is on or after 2023/10/01 and the expiry date of the InvoiceRegistrationNumber is within the validity period.
  const isWithinValidityPeriod =
    dealDate.isSameOrAfter(validityStartDateObj) &&
    dealDate.isSameOrBefore(validityEndDateObj);

  if (
    dealDate.isSameOrAfter(invoicingSystemEffectiveDate) &&
    isWithinValidityPeriod
  ) {
    return InvoiceKind.qualified;
  }

  // 特例区分が適用可能な場合
  // Return qualified if it has specialException = true
  if (
    dealDate.isSameOrAfter(invoicingSystemEffectiveDate) &&
    hasSpecialException
  ) {
    return InvoiceKind.qualified;
  }

  // その他は非適格 80%
  // All the other is unqualified_eighty
  return InvoiceKind.unqualified_eighty;
};

// TODO: Change BusinessPersonData type Probably BusinessPersonData.term is wrong
// type QualifiedPeriod = {
//   label: string;
//   period: string;
// };

export const parseValidityDate = (data: BusinessPersonData) => {
  if (!data.term)
    return {
      validityStartDate: '',
      validityEndDate: '',
    };
  const splittedValidityPeriod: string[] = data.term
    ?.toLocaleString()
    .split('〜');
  return {
    validityStartDate: splittedValidityPeriod[0],
    validityEndDate: splittedValidityPeriod[1],
  };
};
