import { useCellWatch } from '@/features/InvoiceReport/Edit/components/InvoiceTransaction/Columns/Cells/hooks/useCellWatch';
import {
  FormInputNumber,
  FormInputNumberProps,
} from '@moneyforward/ap-frontend-components';
import { memo } from 'react';
import { FieldValues } from 'react-hook-form';
import { CellProps } from './type';

export type Props<TFieldValues extends FieldValues> =
  {} & FormInputNumberProps<TFieldValues> & CellProps;

const InnerFormInputNumberCell = <TFieldValues extends FieldValues>({
  control,
  name,
  row,
  index,
  ...rest
}: Props<TFieldValues>) => {
  const { key, componentProps, onBlur } = useCellWatch(
    control,
    name,
    row.id,
    index,
    rest.onBlur
  );
  return (
    <FormInputNumber<TFieldValues>
      key={key}
      control={control}
      name={name}
      inputProps={componentProps}
      onBlur={onBlur}
      {...rest}
    />
  );
};
InnerFormInputNumberCell.displayName = 'FormInputNumberCell';

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export const FormInputNumberCell = memo(
  InnerFormInputNumberCell
) as typeof InnerFormInputNumberCell;
