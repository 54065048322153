import { InputBase } from '@/components/ReportForm/InputBase';
import { InputBaseType } from '@/components/ReportForm/InputBase/types';
import { useTranslation } from '@/i18n';
import {
  FormInput,
  FormInputProps,
  LabelProps,
} from '@moneyforward/ap-frontend-components';
import { memo, useMemo } from 'react';
import { FieldValues } from 'react-hook-form';

export type Props<TFieldValues extends FieldValues> =
  InputBaseType<TFieldValues> & FormInputProps<TFieldValues>;

const InnerApReportFormInputTextField = <TFieldValues extends FieldValues>({
  control,
  name,
  inputId,
  label,
  hidden,
  caption,
  required = false,
  ...rest
}: Props<TFieldValues>) => {
  const { t } = useTranslation();
  const labelProps: LabelProps = useMemo(
    () => ({ required, children: label }),
    [required, label]
  );

  const rules: { required: string | boolean } = useMemo(
    () => ({ required: required ? t('required') : false }),
    [required, t]
  );
  return (
    <InputBase labelProps={labelProps} description={caption} hidden={hidden}>
      <FormInput<TFieldValues>
        control={control}
        name={name}
        inputId={inputId}
        rules={rules}
        {...rest}
      />
    </InputBase>
  );
};

InnerApReportFormInputTextField.displayName = 'InnerApReportFormInputTextField';

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export const ApReportFormInputTextField = memo((props) => (
  <InnerApReportFormInputTextField {...props} />
)) as typeof InnerApReportFormInputTextField;
ApReportFormInputTextField.displayName = 'ApReportFormInputTextField';
