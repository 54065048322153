import { useApPayeeSelectedValue } from '@/components/ReportForm/Modals/ChangeXXX';
import { stringToDayjs } from 'date-util';
import { useEffect } from 'react';
import {
  FieldPath,
  FieldValues,
  Path,
  PathValue,
  useFormContext,
} from 'react-hook-form';

type ChangePayeeValueArgs<TFieldValues extends FieldValues> = {
  bookDateName: FieldPath<TFieldValues>;
  dueDateName: FieldPath<TFieldValues>;
};

export const useChangePayeeValue = <TFieldValues extends FieldValues>({
  bookDateName,
  dueDateName,
}: ChangePayeeValueArgs<TFieldValues>) => {
  const selectedValue = useApPayeeSelectedValue();
  const { setValue } = useFormContext<TFieldValues>();
  useEffect(() => {
    if (selectedValue === undefined) return;
    const { type, args: payee } = selectedValue;
    switch (type) {
      case 'all': {
        const bookDate = stringToDayjs(payee?.book_date);
        if (bookDate)
          setValue(
            bookDateName,
            // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
            bookDate as PathValue<TFieldValues, Path<TFieldValues>>
          );
        const paymentDate = stringToDayjs(payee?.payment_date);
        if (paymentDate)
          setValue(
            dueDateName,
            // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
            paymentDate as PathValue<TFieldValues, Path<TFieldValues>>
          );
        break;
      }
      case 'only':
        break;
      default:
        break;
    }
  }, [bookDateName, dueDateName, selectedValue, setValue]);
};
