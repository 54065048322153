import { PopupContainerProvider } from '@/components/ReportForm/Container/PopupContainer';
import { useGetMfFile } from '@/context/services/mf_file/MfFile.service';
import {
  useGetInvoiceReport,
  useGetInvoiceTransaction,
} from '@/context/services/reportsType/invoiceReports/Edit.service';
import { InvoiceReportEdit } from '@/features/InvoiceReport/Edit/components/InvoiceReportEdit';
import { FC, memo } from 'react';
import { ApiProvider, ComponentAPI } from '../components/Context';

export type Props = {
  invoiceReportsId: string;
};

export const EditTemplate: FC<Props> = memo(({ invoiceReportsId }) => {
  const apiData = useGetInvoiceReport(invoiceReportsId);
  const transactionData = useGetInvoiceTransaction(invoiceReportsId);
  const invoiceFile = useGetMfFile(
    apiData.invoice_file?.id,
    Boolean(apiData.invoice_file?.id)
  );
  return (
    <PopupContainerProvider>
      <ApiProvider
        formValue={apiData}
        detailValue={transactionData}
        invoiceFile={apiData.invoice_file ? invoiceFile : undefined}
      >
        <ComponentAPI>
          <InvoiceReportEdit invoiceReportsId={invoiceReportsId} />
        </ComponentAPI>
      </ApiProvider>
    </PopupContainerProvider>
  );
});
EditTemplate.displayName = 'Edit';
