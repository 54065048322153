import { useModelsToOptionsWithData } from '@/hooks/useTypeToOptions';
import { useTranslation } from '@/i18n';
import {
  FormSelect,
  FormSelectProps,
  SelectProps,
} from '@moneyforward/ap-frontend-components';
import { Bank as BankModel, useGetBanks } from 'ap-openapi';
import { memo, startTransition, useCallback, useMemo, useState } from 'react';
import { FieldValues } from 'react-hook-form';

type Options = {
  label: string;
  value: string;
};

type Props<TFieldValues extends FieldValues> = {
  selectProps?: Omit<FormSelectProps<TFieldValues>['selectProps'], 'options'>;
  defaultOptions?: Options[];
} & Omit<FormSelectProps<TFieldValues>, 'selectProps' | 'label'>;

export const _BanksSelect = <TFieldValues extends FieldValues>({
  selectProps: _selectProps,
  defaultOptions: _defaultOptions,
  ...rest
}: Props<TFieldValues>) => {
  const defaultOptions = useMemo(
    () => _defaultOptions ?? [],
    [_defaultOptions]
  );
  const { t } = useTranslation();
  const [query, setQuery] = useState<string | undefined>(undefined);
  const { data: apiData, error, isLoading } = useGetBanks();
  if (error) throw error;
  const data = useMemo(() => apiData?.data.banks ?? [], [apiData?.data.banks]);
  const options = useModelsToOptionsWithData<BankModel>(
    data,
    query,
    defaultOptions,
    {
      labelKeys: ['name'],
      value: 'id',
    }
  );
  const onSearch = useCallback((value: string) => {
    startTransition(() => {
      setQuery(value);
    });
  }, []);
  const selectProps: SelectProps = useMemo(
    () => ({
      comboBox: true,
      placeholder: t('bank_select_placeholder'),
      inputPlaceholder: t('bank_input_placeholder'),
      ..._selectProps,
      options: options,
      onSearch: onSearch,
      loading: isLoading,
      mode: 'single',
      selectAll: undefined,
      selectAllLabel: undefined,
    }),
    [_selectProps, isLoading, options, t, onSearch]
  );

  return <FormSelect selectProps={selectProps} {...rest} />;
};
_BanksSelect.displayName = '_BanksSelect';

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export const BanksSelect = memo((props) => (
  <_BanksSelect {...props} />
)) as typeof _BanksSelect;
BanksSelect.displayName = 'BanksSelect';
