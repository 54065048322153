import { useFloorOnAbsolute } from '@/features/InvoiceReport/Edit/components/InvoiceTransaction/hooks';
import { useReportForm } from '@/features/InvoiceReport/Edit/components/hooks/hooks';
import { Big } from 'big.js';
import { useCallback } from 'react';
import type { InvoiceTransaction } from '../../../../type';
import { useGetExItem } from '../../hooks/useGetExItem';
import { useHasWithHoldingIncomeTax } from '../../hooks/useHasWithHoldingIncomeTax';
import { useSelectorExcise } from '../../hooks/useSelectorExcise';

type UpdateInvoiceTransaction = (
  invoiceTransaction: InvoiceTransaction
) => InvoiceTransaction;
export type InvoiceTransactionHelper = {
  getIsZeroPer: (exciseId: string | undefined) => boolean;
  dealDateHelper: UpdateInvoiceTransaction;
  drExciseIdHelper: (
    invoice: InvoiceTransaction,
    value?: string
  ) => InvoiceTransaction;
  exciseValueHelper: UpdateInvoiceTransaction;
  exItemIdHelper: UpdateInvoiceTransaction;
  quantityHelper: UpdateInvoiceTransaction;
  totalValueHelper: UpdateInvoiceTransaction;
  unitHelper: UpdateInvoiceTransaction;
  withholdingIncomeTaxHelper: UpdateInvoiceTransaction;
};

export const useInvoiceTransactionHelper = (
  isTaxIncluded: boolean,
  canDisplayTransactionDetail: boolean,
  canDisplayDrExcise: boolean,
  hasSpecialException: boolean
): InvoiceTransactionHelper => {
  const floorOnAbsolute = useFloorOnAbsolute();
  // SEE: https://github.com/moneyforward/ex_web/blob/release/client/stores/invoice_reports/InvoiceTransactionInputSupporter/supporter/module.ts
  const { updateInvoiceKind } = useReportForm();
  const { getExciseRate, getIsZeroPer } = useSelectorExcise();
  const updateExciseValue = useCallback(
    (invoice: InvoiceTransaction, value: string | undefined = undefined) => {
      const drExciseId = value ?? invoice.drExciseId;
      const exciseRate = getExciseRate(drExciseId);
      let exciseValue: number;
      if (exciseRate === undefined || !canDisplayDrExcise) {
        exciseValue = 0;
      } else {
        if (isTaxIncluded) {
          const taxIncludedTotalValue = invoice.taxIncludedTotalValue ?? 0;
          // taxIncludedTotalValue - taxIncludedTotalValue / (1 + exciseRate)
          exciseValue = Number(
            Big(taxIncludedTotalValue).minus(
              Big(taxIncludedTotalValue).div(Big(1).add(exciseRate))
            )
          );
        } else {
          const totalValue = invoice.totalValue ?? 0;
          exciseValue = Number(Big(totalValue).mul(exciseRate));
        }
      }
      invoice.exciseValue = floorOnAbsolute(exciseValue);
      return invoice;
    },
    [canDisplayDrExcise, floorOnAbsolute, getExciseRate, isTaxIncluded]
  );
  const { getDrExciseIdFromExItem } = useGetExItem();
  const updateDrExciseId = useCallback(
    (invoice: InvoiceTransaction) => {
      const exItemsId = invoice.exItemId;
      const drExciseId = getDrExciseIdFromExItem(exItemsId);
      invoice.drExciseId = drExciseId;
      invoice = updateExciseValue(invoice);

      // Update InvoiceKind because exciseId is changed
      invoice = updateInvoiceKind(invoice, hasSpecialException);
      return invoice;
    },
    [
      getDrExciseIdFromExItem,
      updateExciseValue,
      updateInvoiceKind,
      hasSpecialException,
    ]
  );
  const { getWithholdingIncomeTax } = useHasWithHoldingIncomeTax();
  const updateTotalValue = useCallback(
    (invoice: InvoiceTransaction) => {
      const quantity = invoice.quantity ?? 0;
      if (isTaxIncluded) {
        const unit = invoice.taxIncludedUnit ?? 0;
        invoice.taxIncludedTotalValue = Math.round(
          Number(Big(unit).mul(quantity))
        );
      } else {
        const unit = invoice.unit ?? 0;
        invoice.totalValue = Math.round(Number(Big(unit).mul(quantity)));
      }
      return invoice;
    },
    [isTaxIncluded]
  );
  const updateAnotherUnitAndTotalValue = useCallback(
    (invoice: InvoiceTransaction) => {
      const zero = Big(0);
      const quantity = invoice.quantity ?? 0;

      if (isTaxIncluded) {
        const taxIncludedTotalValue = invoice.taxIncludedTotalValue ?? 0;
        const exciseValue = invoice.exciseValue ?? 0;
        const totalValue = Number(
          Big(taxIncludedTotalValue).minus(exciseValue)
        );
        const unit = Number(
          (quantity > 0 && totalValue > 0 && Big(totalValue).div(quantity)) ||
            zero
        );
        invoice.totalValue = totalValue;
        invoice.unit = unit;
      } else {
        const taxIncludedTotalValue = Number(
          Big(invoice.totalValue ?? 0).add(invoice.exciseValue ?? 0)
        );
        const taxIncludedUnit = Number(
          (quantity > 0 &&
            taxIncludedTotalValue > 0 &&
            Big(taxIncludedTotalValue).div(quantity)) ||
            zero
        );
        invoice.taxIncludedTotalValue = taxIncludedTotalValue;
        invoice.taxIncludedUnit = floorOnAbsolute(taxIncludedUnit);
      }
      return invoice;
    },
    [floorOnAbsolute, isTaxIncluded]
  );
  const equalizeTotalAndUnit = useCallback(
    (invoice: InvoiceTransaction) => {
      if (canDisplayTransactionDetail) {
        return invoice;
      }
      if (isTaxIncluded) {
        const taxIncludedTotalValue = invoice.taxIncludedTotalValue ?? 0;
        invoice.quantity = 1;
        invoice.taxIncludedUnit = taxIncludedTotalValue;
      } else {
        const totalValue = invoice.totalValue ?? 0;
        invoice.quantity = 1;
        invoice.unit = totalValue;
      }
      return invoice;
    },
    [canDisplayTransactionDetail, isTaxIncluded]
  );

  const withholdingIncomeTaxHelper = useCallback(
    (invoice: InvoiceTransaction): InvoiceTransaction => {
      const hasWithholdingIncomeTax = invoice.hasWithholdingIncomeTax;
      if (!hasWithholdingIncomeTax) return invoice;
      const totalValue =
        (isTaxIncluded ? invoice.taxIncludedTotalValue : invoice.totalValue) ??
        0;
      invoice.withholdingIncomeTax = getWithholdingIncomeTax(totalValue);
      return invoice;
    },
    [isTaxIncluded, getWithholdingIncomeTax]
  );

  const dealDateHelper = useCallback(
    (invoice: InvoiceTransaction) => {
      return updateInvoiceKind(invoice, hasSpecialException);
    },
    [updateInvoiceKind, hasSpecialException]
  );
  const drExciseIdHelper = useCallback(
    (invoice: InvoiceTransaction, value: string | undefined = undefined) => {
      invoice = updateExciseValue(invoice, value);
      invoice = updateAnotherUnitAndTotalValue(invoice);
      invoice = updateInvoiceKind(invoice, hasSpecialException);
      return invoice;
    },
    [
      updateAnotherUnitAndTotalValue,
      updateExciseValue,
      updateInvoiceKind,
      hasSpecialException,
    ]
  );
  const exciseValueHelper = useCallback(
    (invoice: InvoiceTransaction) => {
      return updateAnotherUnitAndTotalValue(invoice);
    },
    [updateAnotherUnitAndTotalValue]
  );
  const exItemIdHelper = useCallback(
    (invoice: InvoiceTransaction) => {
      invoice = updateDrExciseId(invoice);
      invoice = updateExciseValue(invoice);
      invoice = withholdingIncomeTaxHelper(invoice);
      return updateInvoiceKind(invoice, hasSpecialException);
    },
    [
      updateDrExciseId,
      updateExciseValue,
      updateInvoiceKind,
      withholdingIncomeTaxHelper,
      hasSpecialException,
    ]
  );
  const quantityHelper = useCallback(
    (invoice: InvoiceTransaction) => {
      invoice = updateTotalValue(invoice);
      invoice = updateExciseValue(invoice);
      invoice = withholdingIncomeTaxHelper(invoice);
      return updateAnotherUnitAndTotalValue(invoice);
    },
    [
      updateAnotherUnitAndTotalValue,
      updateExciseValue,
      updateTotalValue,
      withholdingIncomeTaxHelper,
    ]
  );
  const totalValueHelper = useCallback(
    (invoice: InvoiceTransaction) => {
      invoice = updateExciseValue(invoice);
      invoice = withholdingIncomeTaxHelper(invoice);
      invoice = updateAnotherUnitAndTotalValue(invoice);
      return equalizeTotalAndUnit(invoice);
    },
    [
      equalizeTotalAndUnit,
      updateAnotherUnitAndTotalValue,
      updateExciseValue,
      withholdingIncomeTaxHelper,
    ]
  );
  const unitHelper = useCallback(
    (invoice: InvoiceTransaction) => {
      invoice = updateTotalValue(invoice);
      invoice = updateExciseValue(invoice);
      invoice = withholdingIncomeTaxHelper(invoice);
      return updateAnotherUnitAndTotalValue(invoice);
    },
    [
      updateAnotherUnitAndTotalValue,
      updateExciseValue,
      updateTotalValue,
      withholdingIncomeTaxHelper,
    ]
  );

  return {
    getIsZeroPer,
    dealDateHelper,
    drExciseIdHelper,
    exciseValueHelper,
    exItemIdHelper,
    quantityHelper,
    totalValueHelper,
    unitHelper,
    withholdingIncomeTaxHelper,
  };
};
