import { FileList } from '@/components/ReportForm/InputBase/FileList';
import { DownloadObject, getFileName } from '@/hooks/useDownload';
import { Caption } from '@moneyforward/ap-frontend-components';
import classnames from 'classnames/bind';
import { FC, ReactNode, memo, useMemo } from 'react';
import styles from './InputBase.module.scss';
import { Label, Props as LabelProps } from './Label/Label';

const cx = classnames.bind(styles);

type FileListProps = {
  fileList?: never;
  files: DownloadObject[];
  onDelete: (idx: number) => VoidFunction;
  visibleDeleteButton: boolean;
};

type NoFileListProps = {
  fileList?: ReactNode;
  files?: never;
  onDelete?: never;
  visibleDeleteButton?: never;
};

export type Props = {
  labelProps?: LabelProps;
  description?: ReactNode;
  children?: ReactNode;
  hidden?: boolean;
} & (FileListProps | NoFileListProps);

export const InputBase: FC<Props> = memo(
  ({
    children,
    labelProps,
    description,
    fileList,
    files,
    visibleDeleteButton,
    onDelete,
    hidden,
  }) => {
    const label = useMemo(
      () => labelProps && <Label {...labelProps} />,
      [labelProps]
    );
    const caption = useMemo(
      () =>
        description && (
          <Caption icon={false} type='helper'>
            {description}
          </Caption>
        ),
      [description]
    );

    return (
      <div
        className={cx(styles['container'], {
          [styles['hidden']]: hidden,
        })}
      >
        <div className={cx(styles['inputContainer'])}>
          {label}
          {children}
        </div>
        <div className={cx(styles['fileContainer'])}>
          {fileList}
          {files?.map((file, idx) => (
            <FileList
              key={`${getFileName(file)}-${idx}`}
              file={file}
              onDelete={onDelete(idx)}
              visibleDeleteButton={visibleDeleteButton}
            />
          ))}

          {caption}
        </div>
      </div>
    );
  }
);
InputBase.displayName = 'InputBase';
