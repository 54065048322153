import { ApReportFormInputBusinessDocumentsField } from './ApReportFormInputBusinessDocumentsField';
import { ApReportFormInputCheckBox } from './ApReportFormInputCheckBox';
import { ApReportFormInputContractNumberField } from './ApReportFormInputContractNumberField';
import { ApReportFormInputDateField } from './ApReportFormInputDateField';
import { ApReportFormInputDeptSelect } from './ApReportFormInputDeptSelect';
import { ApReportFormInputExItemSelect } from './ApReportFormInputExItemSelect';
import { ApReportFormInputFileField } from './ApReportFormInputFileField';
import { ApReportFormInputInvoiceBookDateField } from './ApReportFormInputInvoiceBookDateField';
import { ApReportFormInputInvoiceDueDateField } from './ApReportFormInputInvoiceDueDateField';
import { ApReportFormInputInvoiceFileField } from './ApReportFormInputInvoiceFileField';
import { ApReportFormInputInvoiceInstantPayee } from './ApReportFormInputInvoiceInstantPayee';
import { ApReportFormInputInvoiceRegistrationNumberField } from './ApReportFormInputInvoiceRegistrationNumberField';
import { ApReportFormInputNumberField } from './ApReportFormInputNumberField';
import { ApReportFormInputNumberFieldForBranch } from './ApReportFormInputNumberFieldForBranch';
import { ApReportFormInputOfficeMemberSelect } from './ApReportFormInputOfficeMemberSelect';
import { ApReportFormInputPayeeSelect } from './ApReportFormInputPayeeSelect';
import { ApReportFormInputProjectSelect } from './ApReportFormInputProjectSelect';
import { ApReportFormInputRadioButton } from './ApReportFormInputRadioButton';
import { ApReportFormInputReceiptType } from './ApReportFormInputReceiptType';
import { ApReportFormInputReportNumberForExpenseField } from './ApReportFormInputReportNumberForExpenseField';
import { ApReportFormInputSelect } from './ApReportFormInputSelect';
import { ApReportFormInputSpecialExceptionStatusSelect } from './ApReportFormInputSpecialExceptionStatusSelect';
import { ApReportFormInputTextArea } from './ApReportFormInputTextArea';
import { ApReportFormInputTextField } from './ApReportFormInputTextField';
import { ApReportFormInputTimeField } from './ApReportFormInputTimeField';
import { InstantPayeeSwitcher } from './InstantPayeeSwitcher';
export {
  AmountTablesMenu,
  type AmountTablesMenuProps,
} from './AmountTablesMenu';
export { Loading } from './Loading';
export * from './Modals/ChangeXXX';
export * from './ReportForm';
export {
  TotalAmountPaid,
  type Props as TotalAmountPaidProps,
} from './TotalAmountPaid/TotalAmountPaid';
export {
  ApReportFormInputBusinessDocumentsField,
  ApReportFormInputCheckBox,
  ApReportFormInputContractNumberField,
  ApReportFormInputDateField,
  ApReportFormInputDeptSelect,
  ApReportFormInputExItemSelect,
  ApReportFormInputFileField,
  ApReportFormInputInvoiceBookDateField,
  ApReportFormInputInvoiceDueDateField,
  ApReportFormInputInvoiceFileField,
  ApReportFormInputInvoiceInstantPayee,
  ApReportFormInputInvoiceRegistrationNumberField,
  ApReportFormInputNumberField,
  ApReportFormInputNumberFieldForBranch,
  ApReportFormInputOfficeMemberSelect,
  ApReportFormInputPayeeSelect,
  ApReportFormInputProjectSelect,
  ApReportFormInputRadioButton,
  ApReportFormInputReceiptType,
  ApReportFormInputReportNumberForExpenseField,
  ApReportFormInputSelect,
  ApReportFormInputSpecialExceptionStatusSelect,
  ApReportFormInputTextArea,
  ApReportFormInputTextField,
  ApReportFormInputTimeField,
};

const InnerMapping = {
  ApReportFormInputCheckBox: ApReportFormInputCheckBox,
  ApReportFormInputContractNumberField: ApReportFormInputContractNumberField,
  ApReportFormInputDateField: ApReportFormInputDateField,
  ApReportFormInputDeptSelect: ApReportFormInputDeptSelect,
  ApReportFormInputExItemSelect: ApReportFormInputExItemSelect,
  ApReportFormInputFileField: ApReportFormInputFileField,
  ApReportFormInputInvoiceBookDateField: ApReportFormInputInvoiceBookDateField,
  ApReportFormInputInvoiceDueDateField: ApReportFormInputInvoiceDueDateField,
  ApReportFormInputInvoiceRegistrationNumberField:
    ApReportFormInputInvoiceRegistrationNumberField,
  ApReportFormInputNumberField: ApReportFormInputNumberField,
  ApReportFormInputNumberFieldForBranch: ApReportFormInputNumberFieldForBranch,
  ApReportFormInputPayeeSelect: ApReportFormInputPayeeSelect,
  ApReportFormInputProjectSelect: ApReportFormInputProjectSelect,
  ApReportFormInputRadioButton: ApReportFormInputRadioButton,
  ApReportFormInputReceiptType: ApReportFormInputReceiptType,
  ApReportFormInputReportNumberForExpenseField:
    ApReportFormInputReportNumberForExpenseField,
  ApReportFormInputSelect: ApReportFormInputSelect,
  ApReportFormInputSpecialExceptionStatus:
    ApReportFormInputSpecialExceptionStatusSelect,
  ApReportFormInputTextArea: ApReportFormInputTextArea,
  ApReportFormInputTextField: ApReportFormInputTextField,
  ApReportFormInputTimeField: ApReportFormInputTimeField,
  ApReportFormInputInvoiceFileField: ApReportFormInputInvoiceFileField,
  ApReportFormInputInvoicePayeeSelect: InstantPayeeSwitcher,
  ApReportFormInputBusinessDocumentsField:
    ApReportFormInputBusinessDocumentsField,
  ApReportFormInputOfficeMemberSelect: ApReportFormInputOfficeMemberSelect,
} as const;

type UnionToRecord<U extends string> = {
  [K in U]: K;
};

type MappingKey = keyof typeof InnerMapping;

export type MapFormControlComponent = typeof InnerMapping & {
  names: UnionToRecord<MappingKey>;
};

export const Mapping: MapFormControlComponent = {
  ...InnerMapping,
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  names: Object.keys(InnerMapping).reduce(
    (prev, cur) => ({ ...prev, [cur]: cur }),
    {}
  ) as UnionToRecord<MappingKey>,
};
