import { useGetEDocPlanSuspense } from 'ap-openapi';
import { useMemo } from 'react';
import { EDocPlan } from './type';

export const useGetEDocPlan = (): EDocPlan => {
  const { data, error } = useGetEDocPlanSuspense();
  if (error) throw error;
  return useMemo(() => {
    if (data.status === 400)
      return {
        apReceiptTypeAlertType: undefined,
        isInvoiceReportDocuments: undefined,
      };
    const alertType = data?.data?.ap_receipt_type_alert_type;
    return {
      apReceiptTypeAlertType:
        alertType && alertType === 'none' ? undefined : alertType,
      isInvoiceReportDocuments: data?.data?.is_invoice_report_documents,
    };
  }, [
    data?.data?.ap_receipt_type_alert_type,
    data?.data?.is_invoice_report_documents,
    data.status,
  ]);
};
