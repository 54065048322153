import { useFile } from '@/components/ReportForm/ApReportFormInputFileField/hooks';
import { InputBase, LabelProps } from '@/components/ReportForm/InputBase';
import { InputBaseType } from '@/components/ReportForm/InputBase/types';
import { DownloadObject } from '@/hooks/useDownload';
import { useDragAndDrop } from '@/hooks/useDragAndDrop';
import { useFileDrop } from '@/hooks/useFileDrop';
import { useTranslation } from '@/i18n';
import {
  ButtonV2Props,
  FormUploadButton,
  IconTrailing,
} from '@moneyforward/ap-frontend-components';
import { InvoiceReportMfFile } from 'ap-openapi';
import classnames from 'classnames/bind';
import { memo, useCallback, useMemo } from 'react';
import { FieldValues } from 'react-hook-form';
import styles from './ApReportFormInputFileField.module.scss';

const cx = classnames.bind(styles);

export type Props<TFieldValues extends FieldValues> =
  InputBaseType<TFieldValues> & {
    mfFile: InvoiceReportMfFile | undefined;
    canDelete?: boolean;
  };

const InnerApReportFormInputFileField = <TFieldValues extends FieldValues>({
  control,
  name,
  label,
  tooltip,
  caption,
  mfFile,
  canDelete = true,
  required = false,
  hidden,
}: Props<TFieldValues>) => {
  const { t } = useTranslation();
  const { isDrag, setIsDrag, onDragEnter, onDragLeave, onDragOver } =
    useDragAndDrop();

  const labelProps: LabelProps = useMemo(
    () => ({ required, children: label, tooltip }),
    [required, label, tooltip]
  );
  const rules: { required: string | boolean } = useMemo(
    () => ({ required: required ? t('required') : false }),
    [required, t]
  );
  const memorizedButton: ButtonV2Props = useMemo(
    () => ({
      size: 'xs',
      isSecondary: true,
      children: t('select_button'),
      rightIcon: <IconTrailing size={16} />,
      onDragEnter: onDragEnter,
      onDragLeave: onDragLeave,
      onDragOver: onDragOver,
      className: cx({ [styles['half-opacity']]: isDrag }),
    }),
    [isDrag, onDragEnter, onDragLeave, onDragOver, t]
  );

  const { file, onSelected, onDelete } = useFile<TFieldValues>({
    control,
    name,
    canDelete,
    mfFile,
  });
  const files: DownloadObject[] = useMemo(() => {
    if (!file) return [];
    if (file instanceof File) return [file];
    if (!file.file) return [];
    return [file.file];
  }, [file]);
  const onWrapSelected = useCallback(
    (files: File[]) => {
      onSelected({ file: files?.[0] ?? null });
    },
    [onSelected]
  );
  const onDrop = useFileDrop(setIsDrag, onWrapSelected);
  const onWrapDelete = useCallback(() => onDelete, [onDelete]);
  return (
    <div onDrop={onDrop} className={cx(styles['container'])}>
      <InputBase
        labelProps={labelProps}
        description={caption}
        files={files}
        onDelete={onWrapDelete}
        visibleDeleteButton
        hidden={hidden}
      >
        <FormUploadButton
          control={control}
          name={name}
          rules={rules}
          buttonProps={memorizedButton}
          visibleCaption
          uploadLimitAtOnce={1}
        />
      </InputBase>
    </div>
  );
};

InnerApReportFormInputFileField.displayName = 'InnerApReportFormInputFileField';

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export const ApReportFormInputFileField = memo((props) => (
  <InnerApReportFormInputFileField {...props} />
)) as typeof InnerApReportFormInputFileField;
ApReportFormInputFileField.displayName = 'ApReportFormInputFileField';
