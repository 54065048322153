import { useSelectedApPayee } from '@/components/ApPayee/useChangeApPayee';
import { INVOICE_NUMBER_UNSAVED } from '@/context/services/reportsType/invoiceReports/type';
import { useIsLoadingWithAllApi } from '@/features/InvoiceReport/Edit/components/Context/ComponentAPI';
import { CsvImportDrawer } from '@/features/InvoiceReport/Edit/components/Drawer/CsvImportDrawer';
import { ActionsProvider } from '@/features/InvoiceReport/Edit/components/InvoiceTransaction/Columns/Actions/Context';
import {
  useInvoiceTransactionHelper,
  useUpdateInvoiceTransaction,
} from '@/features/InvoiceReport/Edit/components/InvoiceTransaction/Columns/hooks';
import {
  useExport,
  useIsFetchingWithTransactions,
} from '@/features/InvoiceReport/Edit/components/InvoiceTransaction/hooks/useExport';
import { useInvoiceReportNavigation } from '@/features/InvoiceReport/Edit/components/hooks/useGetInvoiceReportPath';
import { useTranslation } from '@/i18n';
import {
  ActionMenu,
  ActionMenuItems,
  ButtonGroup,
  ButtonV2,
  ButtonV2Props,
  Checkbox,
  IconAdd,
  IconTrailing,
  Skeleton,
  Table,
  TableEmptyType,
  randomId,
} from '@moneyforward/ap-frontend-components';
import { Virtualizer, notUndefined } from '@tanstack/react-virtual';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import classnames from 'classnames/bind';
import dayjs, { type Dayjs } from 'dayjs';
import {
  ComponentProps,
  FC,
  MutableRefObject,
  ReactNode,
  memo,
  useCallback,
  useMemo,
} from 'react';
import {
  Control,
  FieldArrayWithId,
  UseFieldArrayAppend,
  UseFieldArrayInsert,
  UseFieldArrayRemove,
  UseFieldArrayReplace,
  get,
  useFieldArray,
  useFormContext,
} from 'react-hook-form';
import type { InvoiceTransaction, PaymentRequestForm } from '../../type.d';
import { UseColumnsOption, useColumns } from './Columns';
import styles from './InvoiceTransaction.module.scss';

export { Header as InvoiceTransactionHeader } from './Header/Header';

const cx = classnames.bind(styles);

export type Props = {
  control: Control<PaymentRequestForm, unknown>;
  fields: FieldArrayWithId<PaymentRequestForm, 'invoiceTransactions', 'id'>[];
  append: UseFieldArrayAppend<PaymentRequestForm, 'invoiceTransactions'>;
  remove: UseFieldArrayRemove;
  replace: UseFieldArrayReplace<PaymentRequestForm, 'invoiceTransactions'>;
  insert: UseFieldArrayInsert<PaymentRequestForm, 'invoiceTransactions'>;
  bookDate: Dayjs | null;
  isPermitMinusTransaction: boolean;
  includeTax: boolean;
  onChangeIncludeTax: (e: CheckboxChangeEvent) => void;
  showColumnDetails: boolean;
  onChangeShowColumnDetails: (e: CheckboxChangeEvent) => void;
  canDisplayDrExcise: boolean;
  virtualContainerRef: MutableRefObject<HTMLDivElement | null>;
  rowVirtualizer: Virtualizer<HTMLDivElement, Element>;
  yScroll?: boolean;
  maxHeight?: number;
};

export const InvoiceTransactionTable: FC<Props> = memo(
  ({
    control,
    fields,
    append,
    remove,
    replace,
    insert,
    yScroll,
    maxHeight,
    bookDate,
    isPermitMinusTransaction,
    includeTax,
    onChangeIncludeTax,
    showColumnDetails,
    onChangeShowColumnDetails,
    canDisplayDrExcise,
    virtualContainerRef,
    rowVirtualizer,
  }) => {
    const {
      watch,
      resetField,
      formState: { errors },
    } = useFormContext<PaymentRequestForm>();
    const { append: addDeleteRecord } = useFieldArray<
      PaymentRequestForm,
      'deleteInvoiceTransactions'
    >({
      control: control,
      name: 'deleteInvoiceTransactions',
    });
    const watchFields = watch('invoiceTransactions');
    const { isApproverRoleFromPath } = useInvoiceReportNavigation();
    const { t } = useTranslation();
    const isLoading = useIsLoadingWithAllApi();
    const selectedPayee = useSelectedApPayee();

    const columnOptions: UseColumnsOption = useMemo(
      () => ({
        isTaxIncluded: includeTax,
        canDisplayTransactionDetail: showColumnDetails,
        canDisplayDrExcise: canDisplayDrExcise,
        isPermitMinusTransaction,
        hasWithholdingIncomeTax:
          selectedPayee?.is_withholding_tax ||
          watchFields.some((field) => field.hasWithholdingIncomeTax),
      }),
      [
        includeTax,
        showColumnDetails,
        canDisplayDrExcise,
        isPermitMinusTransaction,
        selectedPayee?.is_withholding_tax,
        watchFields,
      ]
    );

    const columns = useColumns(control, fields, columnOptions);

    const defaultInvoiceTransaction = useMemo(
      () =>
        Object.assign({}, {
          ...defaultValue,
          dealDate: selectedPayee?.book_date
            ? dayjs(selectedPayee.book_date)
            : bookDate,
          name: selectedPayee?.default_name ?? '',
          crItemId: selectedPayee?.default_cr_item_id ?? undefined,
          crSubItemId: selectedPayee?.default_cr_sub_item_id ?? undefined,
          deptId: selectedPayee?.default_dept_id ?? undefined,
          exItemId: selectedPayee?.default_ex_item_id ?? undefined,
          projectCodeId: selectedPayee?.default_project_code_id ?? undefined,
          rowKey: randomId('invoice-transaction', 8),
        } satisfies InvoiceTransaction),
      [
        bookDate,
        selectedPayee?.book_date,
        selectedPayee?.default_cr_item_id,
        selectedPayee?.default_cr_sub_item_id,
        selectedPayee?.default_dept_id,
        selectedPayee?.default_ex_item_id,
        selectedPayee?.default_name,
        selectedPayee?.default_project_code_id,
      ]
    );

    const helper = useInvoiceTransactionHelper(
      columnOptions.isTaxIncluded,
      columnOptions.canDisplayTransactionDetail,
      columnOptions.canDisplayDrExcise,
      false
    );
    const invoiceTransactionsSupporter = useUpdateInvoiceTransaction(helper);

    const onAdd = useCallback(() => {
      append({
        ...defaultInvoiceTransaction,
        rowKey: randomId('invoice-transaction', 8),
      });
    }, [append, defaultInvoiceTransaction]);
    const onCopy = useCallback(
      (index: number) => {
        const value: InvoiceTransaction = {
          ...watchFields[index]!,
          id: '',
          number: INVOICE_NUMBER_UNSAVED,
          rowKey: randomId('invoice-transaction', 8),
        };
        insert(index, { ...value });
      },
      [watchFields, insert]
    );
    const onAddWithTop = useCallback(
      (index: number) => {
        insert(index, {
          ...defaultInvoiceTransaction,
          rowKey: randomId('invoice-transaction', 8),
        });
      },
      [insert, defaultInvoiceTransaction]
    );
    const onAddWithBottom = useCallback(
      (index: number) => {
        insert(index + 1, {
          ...defaultInvoiceTransaction,
          rowKey: randomId('invoice-transaction', 8),
        });
      },
      [insert, defaultInvoiceTransaction]
    );
    const onDelete = useCallback(
      (index: number) => {
        const id = watchFields[index]?.id;
        if (window.confirm('選択した明細を削除してもよろしいですか？')) {
          remove(index);
          if (id) {
            addDeleteRecord({ id });
          }
        }
      },
      [addDeleteRecord, remove, watchFields]
    );

    const {
      onExportsSjisCsv,
      onExportsSjisCsvOnlyHeader,
      onExportsUtf8Csv,
      onExportsUtf8CsvOnlyHeader,
      onExportsXlsx,
      onExportsXlsxWithOnlyHeader,
    } = useExport(watchFields, isApproverRoleFromPath);

    const onImport = useCallback(
      async (items: InvoiceTransaction[]) => {
        return new Promise<void>((resolve) => {
          watchFields.forEach((item) => {
            const id = item.id;
            if (id) addDeleteRecord({ id });
          });
          invoiceTransactionsSupporter(items);
          resetField('invoiceTransactions', { defaultValue: [] });
          replace(items);
          resolve();
        });
      },
      [
        addDeleteRecord,
        invoiceTransactionsSupporter,
        replace,
        resetField,
        watchFields,
      ]
    );

    const exportsItems: ActionMenuItems = useMemo(() => {
      return [
        {
          key: 'sjis_export',
          label: 'Shift_JIS（Windows向け）',
          onClick: onExportsSjisCsv,
        },
        {
          key: 'utf-8_export',
          label: 'UTF-8（macOS向け）',
          onClick: onExportsUtf8Csv,
        },
        {
          key: 'excel_export',
          label: 'Excel形式',
          onClick: onExportsXlsx,
        },
      ];
    }, [onExportsSjisCsv, onExportsUtf8Csv, onExportsXlsx]);
    const isFetchingWithTransactions = useIsFetchingWithTransactions();
    const actionButtonProps: ButtonV2Props = useMemo(
      () => ({
        children: 'エクスポート',
        color: 'primary',
        isSecondary: true,
        isTertiary: true,
        rightIcon: <IconTrailing size={16} />,
        isLoading: isFetchingWithTransactions,
      }),
      [isFetchingWithTransactions]
    );
    const pagination = useMemo(() => ({ maxPage: 1 }), []);
    const TableAction = useMemo(
      () => (
        <div className={cx(styles['table-actions'])}>
          <div className={cx(styles['checkbox-group'])}>
            <Checkbox
              checked={selectedPayee?.is_tax_included ?? includeTax}
              onChange={onChangeIncludeTax}
              label='税込金額で入力'
            />
            <Checkbox
              checked={showColumnDetails}
              onChange={onChangeShowColumnDetails}
              label='詳細表示(単価、数量、メモ欄)'
            />
          </div>
          <ButtonGroup>
            <ActionMenu
              type='button'
              items={exportsItems}
              buttonProps={actionButtonProps}
            />
            <CsvImportDrawer
              exportSjisCsv={onExportsSjisCsvOnlyHeader}
              exportUtf8Csv={onExportsUtf8CsvOnlyHeader}
              exportXlsx={onExportsXlsxWithOnlyHeader}
              onImport={onImport}
            />
            <ButtonV2
              isSecondary
              onClick={onAdd}
              leftIcon={<IconAdd size={16} />}
            >
              {t('payment_request_transaction_add')}
            </ButtonV2>
          </ButtonGroup>
        </div>
      ),
      [
        selectedPayee?.is_tax_included,
        includeTax,
        onChangeIncludeTax,
        showColumnDetails,
        onChangeShowColumnDetails,
        exportsItems,
        actionButtonProps,
        onExportsSjisCsvOnlyHeader,
        onExportsUtf8CsvOnlyHeader,
        onExportsXlsxWithOnlyHeader,
        onImport,
        onAdd,
        t,
      ]
    );
    const memorizedParagraph = useMemo(() => ({ rows: 4, width: '100%' }), []);
    const loadingProps:
      | { emptyText: JSX.Element; emptyType: TableEmptyType }
      | {} = useMemo(
      () =>
        isLoading
          ? {
              emptyText: (
                <Skeleton title={false} active paragraph={memorizedParagraph} />
              ),
              emptyType: 'loading' satisfies TableEmptyType,
            }
          : {
              emptyType: null,
              emptyText: t('payment_request_transaction_empty_text'),
            },
      [isLoading, memorizedParagraph, t]
    );
    const rowHeight = 45;
    const virtualItems = rowVirtualizer.getVirtualItems();
    const items = virtualItems.map((item) => fields[item.index]!);
    const [before, after] =
      items.length > 0
        ? [
            notUndefined(virtualItems[0]).start -
              rowVirtualizer.options.scrollMargin,
            rowVirtualizer.getTotalSize() -
              notUndefined(virtualItems[virtualItems.length - 1]).end,
          ]
        : [0, 0];
    const components: ComponentProps<
      typeof Table<InvoiceTransaction>
    >['components'] = useMemo(
      () => ({
        body: {
          wrapper: ({
            className,
            children,
          }: {
            className: string;
            children: ReactNode;
          }) => {
            const beforeStyle = { height: before };
            const afterStyle = { height: after };
            // see: https://github.com/TanStack/virtual/issues/585#issuecomment-1716173260
            return (
              <tbody className={`${className} virtual-body`}>
                {before > 0 && (
                  <tr>
                    <td colSpan={columns.length} style={beforeStyle} />
                  </tr>
                )}
                {children}
                {after > 0 && (
                  <tr>
                    <td colSpan={columns.length} style={afterStyle} />
                  </tr>
                )}
              </tbody>
            );
          },
          row: ({
            children,
            ...props
          }: {
            children: ReactNode;
            'data-row-key': string;
            className: string;
          }) => {
            const field = fields.find(
              (item) => item.rowKey === props['data-row-key']
            );
            if (!field) return undefined;
            const index = fields.indexOf(field);
            const virtualItem = virtualItems.find(
              (item) => item.index === index
            );
            if (!virtualItem) {
              return null;
            }
            const error = get(errors, `invoiceTransactions.${index}.rowKey`);
            const isError = Boolean(error);
            return (
              <tr
                {...props}
                className={cx(props.className, 'virtual-row')}
                key={`${field?.rowKey}_${isError}`}
                data-row-key={`${field?.rowKey}_${isError}`}
                aria-invalid={isError}
              >
                {children}
              </tr>
            );
          },
        },
      }),
      [after, before, columns.length, errors, fields, virtualItems]
    );
    const visibleRowCount = 3;
    const scrollY = yScroll
      ? rowHeight * visibleRowCount
      : (maxHeight || window.innerHeight) - 48;
    const tableHeaderHeight = 31;
    return (
      <div className={cx(styles['container'])}>
        <ActionsProvider
          onCopy={onCopy}
          onAddWithTop={onAddWithTop}
          onAddWithBottom={onAddWithBottom}
          onDelete={onDelete}
        >
          <div>{TableAction}</div>
          <div
            ref={virtualContainerRef}
            // eslint-disable-next-line @moneyforward/account-payable/literals-in-props
            style={{
              height: `${scrollY + tableHeaderHeight}px`,
              overflowY: 'auto',
            }}
          >
            <div
              // eslint-disable-next-line @moneyforward/account-payable/literals-in-props
              style={{
                height:
                  watchFields.length > 0
                    ? `${Math.max(rowVirtualizer.getTotalSize(), scrollY)}px`
                    : undefined,
              }}
            >
              <Table<InvoiceTransaction>
                legacy={false}
                rowKey='rowKey'
                columns={columns}
                data={items}
                pagination={pagination}
                resizable
                tableLayout='fixed'
                components={components}
                {...loadingProps}
              />
            </div>
          </div>
        </ActionsProvider>
      </div>
    );
  }
);
InvoiceTransactionTable.displayName = 'InvoiceTransactionTable';

const defaultValue: Omit<InvoiceTransaction, 'rowKey'> = {
  id: '',
  number: INVOICE_NUMBER_UNSAVED,
  dealDate: null,
  name: undefined,
  exItemId: undefined,
  unit: undefined,
  quantity: 1,
  totalValue: undefined,
  drExciseId: undefined,
  invoiceKind: undefined,
  exciseValue: undefined,
  hasWithholdingIncomeTax: undefined,
  withholdingIncomeTax: undefined,
  memo: undefined,
  deptId: undefined,
  projectCodeId: undefined,
  currency: 'JPY',
  useCustomJPYRate: undefined,
  jpyRate: 1,
  crItemId: undefined,
  crSubItemId: undefined,
  taxIncludedTotalValue: 0,
  taxIncludedUnit: 0,
};
