import { useTranslation } from '@/i18n';
import {
  FormSelect,
  FormSelectProps,
  SelectProps,
} from '@moneyforward/ap-frontend-components';
import classnames from 'classnames/bind';
import { ReactNode, memo, useMemo } from 'react';
import { FieldValues } from 'react-hook-form';
import styles from './inputSelect.module.scss';

const cx = classnames.bind(styles);

type Options = {
  label: string;
  value: string;
};

export type TSelectProps<TFieldValues extends FieldValues> = Omit<
  FormSelectProps<TFieldValues>['selectProps'],
  'options'
>;

export type Props<TFieldValues extends FieldValues> = {
  selectProps?: Omit<FormSelectProps<TFieldValues>['selectProps'], 'options'>;
  options?: Options[];
  otherInput?: ReactNode;
  required?: boolean;
  isOtherInputChecked?: boolean;
} & Omit<FormSelectProps<TFieldValues>, 'selectProps' | 'label'>;

const _InputSelect = <TFieldValues extends FieldValues>({
  selectProps: _selectProps,
  options,
  otherInput,
  required,
  ...rest
}: Props<TFieldValues>) => {
  const { t } = useTranslation();
  const selectProps: SelectProps = useMemo(() => {
    return {
      onSearchDebounceWait: 600,
      comboBox: false,
      placeholder: '',
      inputPlaceholder: '',
      ..._selectProps,
      options: options || [],
      mode: 'single',
      selectAll: undefined,
      selectAllLabel: undefined,
    };
  }, [_selectProps, options]);
  const rules = useMemo(() => {
    return {
      required: !selectProps.disabled && required ? t('required') : false,
    };
  }, [required, selectProps.disabled, t]);

  return (
    <div
      className={cx(styles['any-select-wrapper'])}
      data-testid='input-select-wrapper'
    >
      <FormSelect<TFieldValues>
        {...rest}
        selectProps={selectProps}
        rules={rules}
      />
      {otherInput}
    </div>
  );
};
_InputSelect.displayName = '_InputSelect';

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export const InputSelect = memo((props) => (
  <_InputSelect {...props} />
)) as typeof _InputSelect;
InputSelect.displayName = 'InputSelect';
