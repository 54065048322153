import {
  useDeptPer,
  useDepts,
  useDeptsCacheKey,
  useSetDepts,
} from '@/components/DeptSelect/Provider';
import { useModelsToOptionsWithData } from '@/hooks/useTypeToOptions';
import { useTranslation } from '@/i18n';
import { getPageData } from '@/utils/getPageData';
import {
  FormSelect,
  FormSelectProps,
  SelectProps,
} from '@moneyforward/ap-frontend-components';
import { Dept as DeptModel, useGetDeptsSuspenseInfinite } from 'ap-openapi';
import { memo, startTransition, useCallback, useMemo, useState } from 'react';
import { FieldValues } from 'react-hook-form';

type Options = {
  label: string;
  value: string;
};

export type Props<TFieldValues extends FieldValues> = {
  selectProps?: Omit<
    FormSelectProps<TFieldValues>['selectProps'],
    'options' | 'comboBox'
  >;
  query?: string;
  per?: number;
  validateMaxCount?: number;
  defaultOptions?: Options[];
  comboBox?: boolean;
} & Omit<FormSelectProps<TFieldValues>, 'selectProps' | 'label'>;

const _DeptSelect = <TFieldValues extends FieldValues>({
  selectProps: _selectProps,
  defaultOptions: _defaultOptions,
  per = 25,
  comboBox = true,
  ...rest
}: Props<TFieldValues>) => {
  const defaultOptions = useMemo(
    () => _defaultOptions ?? [],
    [_defaultOptions]
  );
  const { t } = useTranslation();
  const [query, setQuery] = useState<string | undefined>(undefined);
  const ctxPer = useDeptPer();
  const setCtxItem = useSetDepts();
  const ctxData = useDepts();
  const ctxCacheKey = useDeptsCacheKey(query);
  const {
    data: apiData,
    error,
    isLoading,
    hasNextPage,
    fetchNextPage,
  } = useGetDeptsSuspenseInfinite(
    {
      query,
      per: ctxPer ?? per,
    },
    {
      query: {
        getNextPageParam: (page) => {
          const url = new URL(page.data.next ?? '/', location.href);
          if (!url.searchParams.has('page')) return undefined;
          return Number(url.searchParams.get('page'));
        },
        queryKey: ctxCacheKey,
      },
    }
  );
  if (error) throw error;
  const data = useMemo(
    () => [...(!query ? ctxData : []), ...getPageData(apiData, 'depts')],
    [apiData, ctxData, query]
  );
  const options = useModelsToOptionsWithData<DeptModel>(
    data,
    query,
    defaultOptions
  );
  const fetchMore = useCallback(
    async (callback: () => void) => {
      if (hasNextPage) {
        const { data } = await fetchNextPage();
        const value = getPageData(data, 'depts');
        setCtxItem(value);
      }
      callback();
    },
    [fetchNextPage, hasNextPage, setCtxItem]
  );
  const onSearch = useCallback((value: string) => {
    startTransition(() => {
      setQuery(value);
    });
  }, []);
  const selectProps: SelectProps = useMemo(
    () => ({
      onSearchDebounceWait: 600,
      comboBox: comboBox,
      placeholder: t('dept_placeholder'),
      inputPlaceholder: t('dept_input_placeholder'),
      ..._selectProps,
      options: options,
      onSearch: onSearch,
      loading: isLoading,
      mode: 'single',
      fetchMore: fetchMore,
      selectAll: undefined,
      selectAllLabel: undefined,
    }),
    [_selectProps, comboBox, fetchMore, isLoading, onSearch, options, t]
  );

  return <FormSelect<TFieldValues> selectProps={selectProps} {...rest} />;
};
_DeptSelect.displayName = '_DeptSelect';

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export const DeptSelect = memo((props) => (
  <_DeptSelect {...props} />
)) as typeof _DeptSelect;
DeptSelect.displayName = 'DeptSelect';
