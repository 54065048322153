import { useConvertToModernPagination } from '@/context/services/hooks/usePagination';
import { isSuccess } from '@/context/services/type';
import { CurrencyRateFormType } from '@/features/foreign_currency_settings/components/Drawer/Drawer';
import { isCommonError } from '@/libs/typeguard/isError';
import { ModernPaginationProps } from '@moneyforward/ap-frontend-components';
import {
  CommonError,
  UpdateApCurrencyRateErrorResponse,
  useGetApCurrencyRateByCurrency,
  useUpdateApCurrencyRate,
} from 'ap-openapi';
import { toISODateString } from 'date-util';
import { useCallback } from 'react';

type Props = {
  currency: string | undefined;
  page?: number;
  perPage?: number;
  onPageChange: (value: number) => void;
};
type UpdateApCurrencyRateResults =
  | UpdateApCurrencyRateSuccessError
  | UpdateApCurrencyRateCommonError
  | UpdateApCurrencyRateInvalidError;

type UpdateApCurrencyRateSuccessError = {
  status: 'success';
};
type UpdateApCurrencyRateCommonError = {
  status: 'error';
  errors: CommonError;
};
type UpdateApCurrencyRateInvalidError = {
  status: 'invalid';
  errors: UpdateApCurrencyRateErrorResponse;
};

export const useGetForeignCurrencyRates = (args: Props) => {
  const { page = 1, perPage = 25, onPageChange, currency } = args;
  const { data, error, refetch, isLoading } = useGetApCurrencyRateByCurrency(
    currency ?? '',
    {
      page: page,
      per: perPage,
    }
  );

  if (error) throw error;

  const pagination: ModernPaginationProps = useConvertToModernPagination(
    data?.data?.pagination,
    onPageChange
  );

  return {
    data: data?.data.ap_currency_rates,
    pagination,
    refetch,
    error,
    isLoading,
  };
};

type UpdateProps = {
  id: string;
};

export const useUpdateCurrencyRate = ({ id }: UpdateProps) => {
  const { mutateAsync: _mutateAsync } = useUpdateApCurrencyRate();

  const mutateAsync = useCallback(
    async (
      data: CurrencyRateFormType
    ): Promise<UpdateApCurrencyRateResults> => {
      const resp = await _mutateAsync({
        id,
        data: {
          currency: data.currency.toLocaleUpperCase(),
          value: data.value,
          start_date: data.date[0] ? toISODateString(data.date[0]) : undefined,
          end_date: data.date[1] ? toISODateString(data.date[1]) : undefined,
        },
      });
      if (isSuccess(resp.status)) {
        return {
          status: 'success',
        };
      } else if (isCommonError(resp.data)) {
        return {
          status: 'error',
          // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
          errors: (resp.data ?? {}) as CommonError,
        };
      } else {
        return {
          status: 'invalid',
          // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
          errors: (resp.data ?? {}) as UpdateApCurrencyRateErrorResponse,
        };
      }
    },
    [_mutateAsync, id]
  );
  return { mutateAsync };
};
