import { useVirtualizer } from '@tanstack/react-virtual';
import { useCallback, useRef } from 'react';

type Args<Item> = {
  fields: Item[];
  getIndex: (fieldName: string) => number | undefined;
  rowHeight?: number;
  overscan?: number;
};

// TODO: もうちょい良い感じに汎用的にしたい

export const useVirtualItem = <Item,>({
  fields,
  getIndex,
  rowHeight = 45,
  overscan = 10,
}: Args<Item>) => {
  const virtualParentRef = useRef<HTMLDivElement | null>(null);
  const rowVirtualizer = useVirtualizer({
    count: fields.length,
    estimateSize: useCallback(() => rowHeight, [rowHeight]),
    overscan: overscan,
    getScrollElement: useCallback(() => virtualParentRef.current, []),
  });
  const handleInvalidScroll = useCallback(
    (ele: Element | undefined, name: string) => {
      const index = getIndex(name);
      if (index) {
        rowVirtualizer.scrollToIndex(index, {
          align: 'start',
          behavior: 'smooth',
        });
      }
    },
    [getIndex, rowVirtualizer]
  );
  return {
    virtualParentRef,
    rowVirtualizer,
    handleInvalidScroll,
  };
};
