import yup from '@/libs/validation';
import { FieldError, appendErrors } from 'react-hook-form';

export class IgnoreErrorTracking extends Error {}

export class InvalidError extends Error {}
export class InvalidErrorWithIgnoreErrorTracking extends IgnoreErrorTracking {}

export class SendErrorTracking extends Error {
  constructor(err: string | Error | undefined) {
    const isErr = err instanceof Error;
    super(isErr ? err.message : err, isErr ? { cause: err.cause } : undefined);
    if (isErr) {
      this.stack = err.stack;
    }
  }
}

export class UnknownError extends SendErrorTracking {
  constructor(
    err: string | Error | undefined,
    details?: Record<string, unknown>
  ) {
    super(err);

    this.message = `${this.message}${
      details ? `: ${JSON.stringify(details)}` : ''
    }`;
  }
}

export const traverseErrors = (
  obj: Record<string, unknown>,
  prefix: string[] = []
): string[] => {
  const errors: string[] = [];
  for (const key in obj) {
    if (Object.hasOwn(obj, key)) {
      const value = obj[key];
      if (typeof value === 'object' && value !== null) {
        if ('type' in value && 'message' in value) {
          errors.push([...prefix, key].join('.'));
        } else {
          const nestedErrors = traverseErrors(
            // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
            value as Record<string, unknown>,
            [...prefix, key]
          );
          errors.push(...nestedErrors);
        }
      }
    }
  }

  return errors;
};

export const parseErrorSchema = (
  error: yup.ValidationError,
  validateAllFieldCriteria: boolean
) => {
  return (error.inner || []).reduce<Record<string, FieldError>>(
    (previous, error) => {
      if (error.type === undefined) {
        error.type = 'error';
      }
      if (!previous[error.path!]) {
        previous[error.path!] = { message: error.message, type: error.type! };
      }

      if (
        validateAllFieldCriteria &&
        previous !== undefined &&
        error &&
        error.path !== undefined
      ) {
        const types = previous[error.path!]?.types;
        const messages = types && types[error.type!];

        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        previous[error.path!] = appendErrors(
          error.path!,
          validateAllFieldCriteria,
          previous,
          error.type!,
          messages
            ? // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
              ([] as string[]).concat(messages as string[], error.message)
            : error.message
        ) as FieldError;
      }

      return previous;
    },
    {}
  );
};

export const isWarn = (type: string): boolean => {
  return (
    ['warn', 'warning'].includes(type) ||
    type.startsWith('warn') ||
    type.startsWith('warning')
  );
};
