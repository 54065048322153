import { Caption } from '@moneyforward/ap-frontend-components';
import classnames from 'classnames/bind';
import { ReactNode, useMemo } from 'react';
import { Control, FieldValues } from 'react-hook-form';
import styles from './InstantPayeeForm.module.scss';
import { InstantPayeeBankAccountForm } from './Parts/InstantPayeeBankAccountForm/InstantPayeeBankAccountForm';
import { InstantPayeeBankAccountNameForm } from './Parts/InstantPayeeBankAccountNameForm/InstantPayeeBankAccountNameForm';
import { InstantPayeeBankNumberForm } from './Parts/InstantPayeeBankNumberForm/InstantPayeeBankNumberForm';
import { InstantPayeeBankTypeForm } from './Parts/InstantPayeeBankTypeForm/InstantPayeeBankTypeForm';
import { InstantPayeeLabel } from './Parts/InstantPayeeLabel/InstantPayeeLabel';
import { InstantPayeeNameHolderKanaForm } from './Parts/InstantPayeeNameHolderKanaForm/InstantPayeeNameHolderKanaForm';
import { InstantPayeeFieldNamesType } from './type';

const cx = classnames.bind(styles);

export type Props<TFieldValues extends FieldValues> = {
  required?: boolean;
  description?: ReactNode;
  control: Control<TFieldValues>;
} & InstantPayeeFieldNamesType<TFieldValues>;

export const InnerInstantPayeeForm = <TFieldValues extends FieldValues>({
  control,
  description,
  required,
  instantPayeeFieldNames,
}: Props<TFieldValues>) => {
  const caption = useMemo(
    () =>
      description && (
        <Caption icon={false} type='helper'>
          {description}
        </Caption>
      ),
    [description]
  );

  return (
    <div className={cx(styles['container'])}>
      <InstantPayeeLabel required={required} />
      {instantPayeeFieldNames && (
        <>
          <InstantPayeeBankAccountNameForm<TFieldValues>
            required={required}
            bankAccountNameInputId={instantPayeeFieldNames.bankAccountName}
            bankAccountNameName={instantPayeeFieldNames.bankAccountName}
            control={control}
          />

          <InstantPayeeBankAccountForm<TFieldValues>
            required={required}
            bankAccountNameInputId={instantPayeeFieldNames.bankAccount}
            bankAccountName={instantPayeeFieldNames.bankAccount}
            bankAccountBranchInputId={instantPayeeFieldNames.bankAccountBranch}
            bankAccountBranchName={instantPayeeFieldNames.bankAccountBranch}
            control={control}
          />
          <InstantPayeeBankTypeForm<TFieldValues>
            required={required}
            inputId={instantPayeeFieldNames.bankType}
            name={instantPayeeFieldNames.bankType}
            control={control}
          />
          <InstantPayeeBankNumberForm<TFieldValues>
            required={required}
            inputId={instantPayeeFieldNames.bankNumber}
            name={instantPayeeFieldNames.bankNumber}
            control={control}
          />
          <InstantPayeeNameHolderKanaForm<TFieldValues>
            required={required}
            inputId={instantPayeeFieldNames.bankNameHolder}
            name={instantPayeeFieldNames.bankNameHolder}
            control={control}
          />
        </>
      )}
      {caption}
    </div>
  );
};
InnerInstantPayeeForm.displayName = 'InnerInstantPayeeForm';
