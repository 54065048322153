import { CrItemsSelect } from '@/components/CrItemsSelect';
import { CrSubItemsSelect } from '@/components/CrSubItemsSelect';
import { CurrenciesSelect } from '@/components/CurrenciesSelect';
import { DeptSelect } from '@/components/DeptSelect';
import { ExItemSelect } from '@/components/ExItemSelect';
import { ExciseSelect } from '@/components/ExciseSelect';
import { ProjectSelect } from '@/components/ProjectSelect';
import { INVOICE_NUMBER_UNSAVED } from '@/context/services/reportsType/invoiceReports/type';
import { DealDate } from '@/features/InvoiceReport/Edit/components/InvoiceTransaction/Columns/Cells/DealDate';
import { FormInputCell } from '@/features/InvoiceReport/Edit/components/InvoiceTransaction/Columns/Cells/FormInputCell';
import { FormInputNumberCell } from '@/features/InvoiceReport/Edit/components/InvoiceTransaction/Columns/Cells/FormInputNumberCell';
import { useInvoiceReportNavigation } from '@/features/InvoiceReport/Edit/components/hooks/useGetInvoiceReportPath';
import { useTranslation } from '@/i18n';
import { useGlobalContainerRef } from '@/wc/helper/ref';
import {
  InputNumberProps,
  TableProps,
} from '@moneyforward/ap-frontend-components';
import { useCallback, useMemo } from 'react';
import { Control } from 'react-hook-form';
import type { InvoiceTransaction, PaymentRequestForm } from '../../../type';
import { Actions } from './Actions/Actions';
import { HasWithHoldingIncomeTaxCheckBox } from './Cells/HasWithHoldingIncomeTaxCheckBox';
import { InvoiceKind } from './Cells/InvoiceKind';
import {
  useInvoiceTransactionHelper,
  useInvoiceTransactionSupporters,
  useValidationRules,
} from './hooks';

export type UseColumnsOption = {
  isTaxIncluded: boolean;
  canDisplayTransactionDetail: boolean;
  canDisplayDrExcise: boolean;
  isPermitMinusTransaction: boolean;
  hasWithholdingIncomeTax: boolean;
};

type Column = TableProps<InvoiceTransaction>['columns'][number];
export type Columns = Column[];

export const useColumns = (
  control: Control<PaymentRequestForm, unknown>,
  fields: InvoiceTransaction[],
  option: UseColumnsOption | undefined = undefined
): Columns => {
  const {
    isTaxIncluded,
    canDisplayTransactionDetail,
    canDisplayDrExcise,
    isPermitMinusTransaction,
    hasWithholdingIncomeTax,
  } = useMemo(
    () =>
      option ?? {
        isTaxIncluded: false,
        canDisplayTransactionDetail: true,
        canDisplayDrExcise: true,
        isPermitMinusTransaction: false,
        hasWithholdingIncomeTax: false,
      },
    [option]
  );
  const { isApproverRoleFromPath } = useInvoiceReportNavigation();
  const { t } = useTranslation();
  const containerRef = useGlobalContainerRef();
  const {
    dealDateRule,
    nameRule,
    exItemIdRule,
    quantityRule,
    unitRule,
    taxIncludedUnitRule,
    taxIncludedTotalValueRule,
    totalValueRule,
    deptIdRule,
    projectCodeIdRule,
    memoRule,
    hasWithholdingIncomeTaxRule,
    withholdingIncomeTaxRule,
    crItemIdRule,
    currencyRule,
  } = useValidationRules(
    isTaxIncluded,
    isPermitMinusTransaction,
    canDisplayTransactionDetail
  );
  const helpers = useInvoiceTransactionHelper(
    isTaxIncluded,
    canDisplayTransactionDetail,
    canDisplayDrExcise,
    false
  );
  const { getIsZeroPer } = helpers;
  const {
    dealDateSupporter,
    drExciseIdSupporter,
    exItemIdSupporter,
    exciseValueSupporter,
    quantitySupporter,
    totalValueSupporter,
    unitSupporter,
    withholdingIncomeTaxSupporter,
  } = useInvoiceTransactionSupporters(control, helpers);

  const unitFormName: { label: string; name: 'taxIncludedUnit' | 'unit' } =
    useMemo(
      () => ({
        name: isTaxIncluded ? 'taxIncludedUnit' : 'unit',
        label: isTaxIncluded
          ? t('invoice_transaction_unit_tax_include')
          : t('invoice_transaction_unit'),
      }),
      [isTaxIncluded, t]
    );
  const totalValueFormName: {
    name: 'taxIncludedTotalValue' | 'totalValue';
    label: string;
  } = useMemo(
    () => ({
      name: isTaxIncluded ? 'taxIncludedTotalValue' : 'totalValue',
      label: isTaxIncluded
        ? t('invoice_transaction_total_value_tax_include')
        : t('invoice_transaction_total_value'),
    }),
    [isTaxIncluded, t]
  );

  const rateInputProps = useMemo(
    () => ({ format: 'unit' } satisfies InputNumberProps),
    []
  );
  const baseSelectProps = useMemo(
    () => ({ getPopupContainer: containerRef }),
    [containerRef]
  );
  const getIndex = useCallback(
    (row: InvoiceTransaction) =>
      fields.findIndex((field) => field.rowKey === row.rowKey),
    [fields]
  );
  const columns = useMemo(
    () =>
      [
        {
          title: t('invoice_transaction_number'),
          dataIndex: 'number',
          render(value: string) {
            return value === INVOICE_NUMBER_UNSAVED ? '' : value;
          },
          width: 65,
          fixed: 'left',
        } satisfies Column,
        {
          title: t('invoice_transaction_action'),
          render(v, r: InvoiceTransaction) {
            const index = getIndex(r);
            return <Actions index={index} />;
          },
          width: 50,
          fixed: 'left',
        } satisfies Column,
        {
          title: t('invoice_transaction_deal_date'),
          dataIndex: 'dealDate',
          render(v, r: InvoiceTransaction) {
            const index = getIndex(r);
            return (
              <DealDate<PaymentRequestForm>
                row={r}
                index={index}
                control={control}
                inputId={`dealDate${index}`}
                name={`invoiceTransactions.${index}.dealDate`}
                rules={dealDateRule(index)}
                onChange={() => dealDateSupporter(index)}
                containerRef={containerRef}
              />
            );
          },
          resizable: true,
          width: 150,
        } satisfies Column,
        {
          title: t('invoice_transaction_name'),
          dataIndex: 'name',
          render(v, r: InvoiceTransaction) {
            const index = getIndex(r);
            return (
              <FormInputCell<PaymentRequestForm>
                control={control}
                inputId={`name${index}`}
                name={`invoiceTransactions.${index}.name`}
                rules={nameRule(index)}
                row={r}
                index={index}
              />
            );
          },
          resizable: true,
          width: 220,
        } satisfies Column,
        {
          title: t('invoice_transaction_department'),
          dataIndex: 'deptId',
          render(v, r: InvoiceTransaction) {
            const index = getIndex(r);
            return (
              <DeptSelect<PaymentRequestForm>
                key={`${r.id}_${v}_${index}`}
                control={control}
                inputId={`deptId${index}`}
                name={`invoiceTransactions.${index}.deptId`}
                rules={deptIdRule(index)}
                selectProps={baseSelectProps}
              />
            );
          },
          resizable: true,
          width: 220,
        } satisfies Column,
        {
          title: t('invoice_transaction_expense_account'),
          dataIndex: 'exItem',
          render(v, r: InvoiceTransaction) {
            const index = getIndex(r);
            return (
              <ExItemSelect<PaymentRequestForm>
                key={`${r.id}_${v}_${index}`}
                control={control}
                inputId={`exItem${index}`}
                name={`invoiceTransactions.${index}.exItemId`}
                rules={exItemIdRule(index)}
                deptId={r.deptId}
                onBlur={() => exItemIdSupporter(index)}
                selectProps={baseSelectProps}
              />
            );
          },
          resizable: true,
          width: 220,
        } satisfies Column,
        canDisplayTransactionDetail
          ? ({
              title: unitFormName.label,
              dataIndex: unitFormName.name,
              render(v, r: InvoiceTransaction) {
                const index = getIndex(r);
                return (
                  <FormInputNumberCell<PaymentRequestForm>
                    control={control}
                    row={r}
                    index={index}
                    inputId={`${unitFormName.name}${index}`}
                    rules={
                      isTaxIncluded
                        ? taxIncludedUnitRule(index)
                        : unitRule(index)
                    }
                    name={`invoiceTransactions.${index}.${unitFormName.name}`}
                    onBlur={() => unitSupporter(index)}
                  />
                );
              },
              width: 110,
            } satisfies Column)
          : {},
        // 数量 // amount
        canDisplayTransactionDetail
          ? ({
              title: t('invoice_transaction_quantity'),
              dataIndex: 'quantity',
              render(v, r: InvoiceTransaction) {
                const index = getIndex(r);
                return (
                  <FormInputNumberCell<PaymentRequestForm>
                    control={control}
                    row={r}
                    index={index}
                    inputId={`quantity${index}`}
                    name={`invoiceTransactions.${index}.quantity`}
                    rules={quantityRule(index)}
                    onBlur={() => quantitySupporter(index)}
                  />
                );
              },
              width: 110,
            } satisfies Column)
          : {},
        {
          title: totalValueFormName.label,
          dataIndex: totalValueFormName.name,
          render(v, r: InvoiceTransaction) {
            const index = getIndex(r);
            return (
              <FormInputNumberCell<PaymentRequestForm>
                control={control}
                row={r}
                index={index}
                inputId={`${totalValueFormName.name}${index}`}
                rules={
                  isTaxIncluded
                    ? taxIncludedTotalValueRule(index)
                    : totalValueRule(index)
                }
                name={`invoiceTransactions.${index}.${totalValueFormName.name}`}
                onBlur={() => totalValueSupporter(index)}
              />
            );
          },
          width: 120,
        } satisfies Column,
        {
          // 税区分 // Excise
          title: t('invoice_transaction_dr_excise_id'),
          dataIndex: 'drExciseId',
          render(v: string, r: InvoiceTransaction) {
            const index = getIndex(r);
            return (
              <ExciseSelect<PaymentRequestForm>
                key={`${r.id}_${v}_${index}`}
                control={control}
                inputId={`drExciseId${index}`}
                name={`invoiceTransactions.${index}.drExciseId`}
                onBlur={() => {
                  drExciseIdSupporter(index);
                }}
                selectProps={baseSelectProps}
              />
            );
          },
          width: 152,
        } satisfies Column,
        isApproverRoleFromPath
          ? ({
              // インボイス経過措置
              title: t('invoice_transaction_invoice_kind'),
              dataIndex: 'invoiceKind',
              render(v, r, index) {
                return (
                  <InvoiceKind
                    key={`${r.id}_${v}_${index}`}
                    control={control}
                    inputId={`invoiceKind${index}`}
                    name={`invoiceTransactions.${index}.invoiceKind`}
                    isNotTarget={getIsZeroPer(r.drExciseId)}
                    selectProps={baseSelectProps}
                    index={index}
                  />
                );
              },
              width: 152,
            } satisfies Column)
          : {},
        {
          // 消費税額 // excise_value
          title: t('invoice_transaction_excise_value'),
          dataIndex: 'exciseValue',
          render(v, r: InvoiceTransaction) {
            const index = getIndex(r);
            return (
              <FormInputNumberCell<PaymentRequestForm>
                control={control}
                row={r}
                index={index}
                inputId={`exciseValue${index}`}
                name={`invoiceTransactions.${index}.exciseValue`}
                onBlur={() => exciseValueSupporter(index)}
              />
            );
          },
          width: 80,
        } satisfies Column,
        hasWithholdingIncomeTax
          ? ({
              title: t('invoice_transaction_has_withholding_income_tax'),
              dataIndex: 'hasWithholdingIncomeTax',
              render(v, r: InvoiceTransaction) {
                const index = getIndex(r);
                return (
                  <HasWithHoldingIncomeTaxCheckBox
                    onBlur={() => withholdingIncomeTaxSupporter(index)}
                    key={`${r.id}_${v}_${index}`}
                    control={control}
                    inputId={`hasWithholdingIncomeTax${index}`}
                    name={`invoiceTransactions.${index}.hasWithholdingIncomeTax`}
                    label={t(
                      'invoice_transaction_withholding_income_tax_label'
                    )}
                    rules={hasWithholdingIncomeTaxRule(index)}
                  />
                );
              },
              width: 80,
            } satisfies Column)
          : {},
        hasWithholdingIncomeTax
          ? ({
              title: t('invoice_transaction_withholding_income_tax_amount'),
              dataIndex: 'withholdingIncomeTax',
              render(v, r: InvoiceTransaction) {
                const index = getIndex(r);
                return (
                  <FormInputNumberCell<PaymentRequestForm>
                    control={control}
                    row={r}
                    index={index}
                    inputId={`withholdingIncomeTax${index}`}
                    name={`invoiceTransactions.${index}.withholdingIncomeTax`}
                    // eslint-disable-next-line @moneyforward/account-payable/literals-in-props
                    inputProps={{
                      disabled: !r.hasWithholdingIncomeTax,
                    }}
                    rules={withholdingIncomeTaxRule(index)}
                  />
                );
              },
              resizable: true,
              width: 120,
            } satisfies Column)
          : {},
        canDisplayTransactionDetail
          ? ({
              title: t('invoice_transaction_memo'),
              dataIndex: 'memo',
              render(v, r: InvoiceTransaction) {
                const index = getIndex(r);
                return (
                  <FormInputCell<PaymentRequestForm>
                    control={control}
                    row={r}
                    index={index}
                    inputId={`memo${index}`}
                    name={`invoiceTransactions.${index}.memo`}
                    rules={memoRule(index)}
                  />
                );
              },
              resizable: true,
              width: 152,
            } satisfies Column)
          : {},
        {
          title: t('invoice_transaction_project'),
          dataIndex: 'projectCodeId',
          render(v, r: InvoiceTransaction) {
            const index = getIndex(r);
            return (
              <ProjectSelect<PaymentRequestForm>
                key={`${r.id}_${v}_${index}`}
                control={control}
                inputId={`projectCodeId${index}`}
                name={`invoiceTransactions.${index}.projectCodeId`}
                rules={projectCodeIdRule(index)}
                selectProps={baseSelectProps}
              />
            );
          },
          resizable: true,
          width: 220,
        } satisfies Column,
        {
          title: t('invoice_transaction_credit_account'),
          dataIndex: 'crItemId',
          render(v, r: InvoiceTransaction) {
            const index = getIndex(r);
            return (
              <CrItemsSelect<PaymentRequestForm>
                key={`${r.id}_${v}_${index}`}
                control={control}
                inputId={`crItemId${index}`}
                name={`invoiceTransactions.${index}.crItemId`}
                rules={crItemIdRule(index)}
                selectProps={baseSelectProps}
              />
            );
          },
          resizable: true,
          width: 150,
        } satisfies Column,
        {
          title: t('invoice_transaction_credit_sub_account'),
          dataIndex: 'crSubItemId',
          render(v, r: InvoiceTransaction) {
            const index = getIndex(r);
            return (
              <CrSubItemsSelect<PaymentRequestForm>
                key={`${r.id}_${v}_${index}`}
                control={control}
                inputId={`crSubItemId${index}`}
                name={`invoiceTransactions.${index}.crSubItemId`}
                crItemId={r.crItemId}
                selectProps={baseSelectProps}
              />
            );
          },
          resizable: true,
          width: 150,
        } satisfies Column,
        {
          title: t('invoice_transaction_currency'),
          dataIndex: 'currency',
          render(v, r: InvoiceTransaction) {
            const index = getIndex(r);
            return (
              <CurrenciesSelect<PaymentRequestForm>
                key={`${r.id}_${v}_${index}`}
                control={control}
                inputId={`currency${index}`}
                name={`invoiceTransactions.${index}.currency`}
                rules={currencyRule(index)}
                selectProps={baseSelectProps}
              />
            );
          },
          resizable: true,
          width: 120,
        } satisfies Column,
        {
          // レード // jpyRate
          title: t('invoice_transaction_jpyRate'),
          render(v, r: InvoiceTransaction) {
            const index = getIndex(r);
            return (
              <FormInputNumberCell<PaymentRequestForm>
                control={control}
                row={r}
                index={index}
                inputId={`jpyRate${index}`}
                name={`invoiceTransactions.${index}.jpyRate`}
                inputProps={rateInputProps}
              />
            );
          },
          width: 128,
        } satisfies Column,
      ].filter((item: Column | {}): item is Column => 'title' in item),
    [
      baseSelectProps,
      canDisplayTransactionDetail,
      containerRef,
      control,
      crItemIdRule,
      currencyRule,
      dealDateRule,
      dealDateSupporter,
      deptIdRule,
      drExciseIdSupporter,
      exItemIdRule,
      exItemIdSupporter,
      exciseValueSupporter,
      getIndex,
      getIsZeroPer,
      hasWithholdingIncomeTax,
      hasWithholdingIncomeTaxRule,
      isTaxIncluded,
      memoRule,
      nameRule,
      projectCodeIdRule,
      quantityRule,
      quantitySupporter,
      rateInputProps,
      t,
      taxIncludedTotalValueRule,
      taxIncludedUnitRule,
      totalValueFormName.label,
      totalValueFormName.name,
      totalValueRule,
      totalValueSupporter,
      unitFormName.label,
      unitFormName.name,
      unitRule,
      unitSupporter,
      withholdingIncomeTaxSupporter,
      withholdingIncomeTaxRule,
      isApproverRoleFromPath,
    ]
  );

  return columns;
};
