import { useInvoiceReportNavigation } from '@/features/InvoiceReport/Edit/components/hooks/useGetInvoiceReportPath';
import { useTranslation } from '@/i18n';
import {
  ButtonGroup,
  ButtonV2,
  Form,
  InlineNotification,
  UploadDrawerLeadingFiles,
  UploadFiles,
  useToastAPI,
} from '@moneyforward/ap-frontend-components';
import classNames from 'classnames';
import { FC, memo, useCallback, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import type { InvoiceTransaction } from '../../../type';
import { useImport } from '../../InvoiceTransaction/hooks/useImport';
import styles from './CsvImportDrawer.module.scss';
import { Details, DetailsProps } from './Details';

const cx = classNames.bind(styles);

export type ImportCsvForm = {
  file: UploadFiles;
};

export type Props = {
  onImport: (items: InvoiceTransaction[]) => void;
} & Omit<DetailsProps, 'isApproverRoleFromPath'>;

export const CsvImportDrawer: FC<Props> = memo(
  ({ exportXlsx, exportSjisCsv, exportUtf8Csv, onImport }) => {
    const { t } = useTranslation();
    const [errorMsg, setErrorMsg] = useState<string>('');
    const [open, setOpen] = useState(false);
    const onOpen = useCallback(() => {
      setOpen(true);
    }, []);

    const methods = useForm<ImportCsvForm>({
      defaultValues: {
        file: [],
      },
    });

    const { reset, getValues } = methods;

    const uploader = useMemo(
      () => ({
        append: false,
        description: t('invoice_reports.csv_import_drawer.description'),
        accept: '.csv, .xlsx',
        name: 'file' as const,
        control: methods.control,
      }),
      [t, methods]
    );

    const notify = useToastAPI();
    const { isApproverRoleFromPath } = useInvoiceReportNavigation();
    const { importInvoiceTransaction } = useImport(isApproverRoleFromPath);
    const onReplace = useCallback(async () => {
      const fileWatch = getValues('file');
      const importData = await importInvoiceTransaction({ file: fileWatch[0] });
      await onImport(importData);
    }, [onImport, importInvoiceTransaction, getValues]);

    const onClose = useCallback(() => {
      setOpen(false);
      reset();
      setErrorMsg('');
    }, [reset]);
    const onSubmit = useCallback(async () => {
      await onReplace();
      notify.success({
        title: t('invoice_reports.csv_import_drawer.import_success'),
        duration: 5,
      });
      reset();
      setOpen(false);
    }, [reset, notify, onReplace, t]);

    return (
      <div>
        <ButtonV2
          onClick={onOpen}
          isSecondary
          isTertiary
          testId='import-button'
        >
          {t('import_label')}
        </ButtonV2>
        <Form<ImportCsvForm> formMethod={methods}>
          <div className={cx(styles['uploader-container'])}>
            <UploadDrawerLeadingFiles<ImportCsvForm>
              buttons={
                <div>
                  <ButtonGroup>
                    <ButtonV2
                      color='primary'
                      isSecondary
                      onClick={onClose}
                      type='button'
                    >
                      {t('cancel')}
                    </ButtonV2>
                    <ButtonV2
                      color='primary'
                      type='button'
                      isDisabled={methods.getValues('file').length === 0}
                      testId='submit-button'
                      onClick={onSubmit}
                    >
                      {t('import_label')}
                    </ButtonV2>
                  </ButtonGroup>
                </div>
              }
              title={t('invoice_reports.csv_import_drawer.title')}
              open={open}
              size='slim'
              onClose={onClose}
              control={methods.control}
              name='file'
              arrayName='file'
              uploader={uploader}
              uploadFileLimitTotal={1}
              header={
                <>
                  <InlineNotification
                    message={errorMsg}
                    type='error'
                    visible={errorMsg ? true : false}
                    onClick={() => setErrorMsg('')}
                  />
                </>
              }
              details={
                <Details
                  exportSjisCsv={exportSjisCsv}
                  exportUtf8Csv={exportUtf8Csv}
                  exportXlsx={exportXlsx}
                  isApproverRoleFromPath={isApproverRoleFromPath}
                />
              }
            />
          </div>
        </Form>
      </div>
    );
  }
);
CsvImportDrawer.displayName = 'CsvImportDrawer';
