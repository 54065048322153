import { CurrenciesSelect } from '@/components/CurrenciesSelect';
import { CurrencyRateFormType } from '@/features/foreign_currency_settings/components/Drawer/Drawer';
import { useTranslation } from '@/i18n';
import { Label, Left, Right } from '@moneyforward/ap-frontend-components';
import classNames from 'classnames';
import { FC, memo, useMemo } from 'react';
import { Control } from 'react-hook-form';
import styles from './DrawerForm.module.scss';

const cx = classNames.bind(styles);

type Props = {
  control: Control<CurrencyRateFormType>;
  value: string;
};

export const CurrencyUnit: FC<Props> = memo(({ value, control }) => {
  const { t } = useTranslation();

  const rules = useMemo(() => {
    return {
      required: t('required'),
    };
  }, [t]);

  return (
    <>
      <Left>
        <Label
          labelClassName={cx(styles['bold'])}
          required={value ? false : true}
        >
          <div className={cx(styles['bold'])}>
            {t('foreign_currency.drawer.currency')}
          </div>
        </Label>
      </Left>
      <Right>
        {value ? (
          <Label>{value.toLocaleUpperCase()}</Label>
        ) : (
          <CurrenciesSelect<CurrencyRateFormType>
            key='currency'
            control={control}
            inputId='currency'
            name='currency'
            rules={rules}
            excludeJpy
          />
        )}
      </Right>
    </>
  );
});
CurrencyUnit.displayName = 'CurrencyUnit';
