import { InvoiceReportDetailFormInputsResponse } from 'ap-openapi';
import { useCallback } from 'react';

const excludeFormType = [
  'ApReportFormInputInvoicePayeeType',
  'ApReportFormInputInvoicePayeeSelect',
  'ApReportFormInputInvoiceInstantPayee',
  'ApReportFormInputInvoiceBookDateField',
  'ApReportFormInputInvoiceDueDateField',
  'ApReportFormInputSpecialExceptionStatus',
];

export const useConvertApiDataToReportForm = () => {
  return useCallback(
    (reportFormInputs: InvoiceReportDetailFormInputsResponse[]) => {
      return reportFormInputs.filter(
        (item) => !excludeFormType.includes(item.type)
      );
    },
    []
  );
};
