/**
 * Generated by orval v6.23.0 🍺
 * Do not edit manually.
 * APIs for RCI FE
 * This document describes the APIs provided by NeoAP BE for RCI FE, designed to be integrated into NeoAP FE using `iframe`.\
These APIs utilize cookie-based authentication. All API requests must include the cookie `_ap_session`.

 * OpenAPI spec version: 1
 */
import { useQuery, useSuspenseQuery } from '@tanstack/react-query';
import type {
  QueryFunction,
  QueryKey,
  UseQueryOptions,
  UseQueryResult,
  UseSuspenseQueryOptions,
  UseSuspenseQueryResult,
} from '@tanstack/react-query';
import type { ForbiddenResponse } from '../model/forbiddenResponse';
import type { GetEDocPlanResponse } from '../model/getEDocPlanResponse';
import type { NotFoundResponse } from '../model/notFoundResponse';
import type { UnauthorizedResponse } from '../model/unauthorizedResponse';
import { useCustomClient } from '../../mutator/custom-instance';
import type { ErrorType } from '../../mutator/custom-instance';
import { customFomDataFn } from '../../api/formData/custom-function';

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

// eslint-disable-next-line
type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P
) => any
  ? P
  : never;

/**
 * @summary Get a e_doc_plan
 */
export const useGetEDocPlanHook = () => {
  const getEDocPlan = useCustomClient<GetEDocPlanResponse>();

  return (
    options?: SecondParameter<ReturnType<typeof useCustomClient>>,
    signal?: AbortSignal
  ) => {
    return getEDocPlan(
      { url: `/api/js/e_doc_plan`, method: 'GET', signal },
      options
    );
  };
};

export const getGetEDocPlanQueryKey = () => {
  return [`/api/js/e_doc_plan`] as const;
};

export const useGetEDocPlanQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetEDocPlanHook>>>,
  TError = ErrorType<
    UnauthorizedResponse | ForbiddenResponse | NotFoundResponse
  >
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useGetEDocPlanHook>>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<ReturnType<typeof useCustomClient>>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetEDocPlanQueryKey();

  const getEDocPlan = useGetEDocPlanHook();

  const queryFn: QueryFunction<
    Awaited<ReturnType<ReturnType<typeof useGetEDocPlanHook>>>
  > = ({ signal }) => getEDocPlan(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useGetEDocPlanHook>>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetEDocPlanQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetEDocPlanHook>>>
>;
export type GetEDocPlanQueryError = ErrorType<
  UnauthorizedResponse | ForbiddenResponse | NotFoundResponse
>;

/**
 * @summary Get a e_doc_plan
 */
export const useGetEDocPlan = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetEDocPlanHook>>>,
  TError = ErrorType<
    UnauthorizedResponse | ForbiddenResponse | NotFoundResponse
  >
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useGetEDocPlanHook>>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<ReturnType<typeof useCustomClient>>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetEDocPlanQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const useGetEDocPlanSuspenseQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetEDocPlanHook>>>,
  TError = ErrorType<
    UnauthorizedResponse | ForbiddenResponse | NotFoundResponse
  >
>(options?: {
  query?: Partial<
    UseSuspenseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useGetEDocPlanHook>>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<ReturnType<typeof useCustomClient>>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetEDocPlanQueryKey();

  const getEDocPlan = useGetEDocPlanHook();

  const queryFn: QueryFunction<
    Awaited<ReturnType<ReturnType<typeof useGetEDocPlanHook>>>
  > = ({ signal }) => getEDocPlan(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseSuspenseQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useGetEDocPlanHook>>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetEDocPlanSuspenseQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetEDocPlanHook>>>
>;
export type GetEDocPlanSuspenseQueryError = ErrorType<
  UnauthorizedResponse | ForbiddenResponse | NotFoundResponse
>;

/**
 * @summary Get a e_doc_plan
 */
export const useGetEDocPlanSuspense = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetEDocPlanHook>>>,
  TError = ErrorType<
    UnauthorizedResponse | ForbiddenResponse | NotFoundResponse
  >
>(options?: {
  query?: Partial<
    UseSuspenseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useGetEDocPlanHook>>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<ReturnType<typeof useCustomClient>>;
}): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetEDocPlanSuspenseQueryOptions(options);

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};
