import { useGetPopupContainer } from '@/components/ReportForm/Container/PopupContainer';
import { InputBase, LabelProps } from '@/components/ReportForm/InputBase';
import { InputBaseType } from '@/components/ReportForm/InputBase/types';
import {
  SpecialExceptionStatusAddressInput,
  SpecialExceptionStatusSelect,
} from '@/components/SpecialExceptionStatusSelect';
import { useTranslation } from '@/i18n';
import { FormSelectProps } from '@moneyforward/ap-frontend-components';
import classnames from 'classnames/bind';
import { memo, useMemo } from 'react';
import { FieldPath, FieldValues, useWatch } from 'react-hook-form';
import { Options } from '../../SpecialExceptionStatusSelect/mock';
import styles from './apReportFormInputSpecialExceptionStatusSelect.module.scss';

const cx = classnames.bind(styles);

export type Props<TFieldValues extends FieldValues> =
  InputBaseType<TFieldValues> & {
    options: Options[];
    subInputName: FieldPath<TFieldValues>;
  };

const InnerApReportFormInputSpecialExceptionStatusSelect = <
  TFieldValues extends FieldValues
>({
  control,
  name,
  inputId,
  label,
  tooltip,
  hidden,
  caption,
  required = false,
  options,
  subInputName,
}: Props<TFieldValues>) => {
  const labelProps: LabelProps = useMemo(
    () => ({ required, children: label, tooltip }),
    [required, label, tooltip]
  );
  const specialExceptionStatusId = useWatch({ name, control });
  const hasSpecialExceptionStatusId = useMemo(() => {
    return !!specialExceptionStatusId && specialExceptionStatusId !== '0';
  }, [specialExceptionStatusId]);
  const { t } = useTranslation();
  const rules: { required: string | boolean } = useMemo(
    () => ({ required: required ? t('required') : false }),
    [required, t]
  );
  const containerRef = useGetPopupContainer();
  const memorizedSelectProps = useMemo(
    (): Omit<
      FormSelectProps<TFieldValues>['selectProps'],
      'options' | 'comboBox'
    > => ({
      getPopupContainer: containerRef,
    }),
    [containerRef]
  );

  return (
    <InputBase labelProps={labelProps} description={caption} hidden={hidden}>
      <div className={cx(styles['container'])}>
        <SpecialExceptionStatusSelect<TFieldValues>
          defaultOptions={options}
          control={control}
          name={name}
          inputId={inputId}
          rules={rules}
          selectProps={memorizedSelectProps}
        />
        {hasSpecialExceptionStatusId && (
          <SpecialExceptionStatusAddressInput<TFieldValues>
            control={control}
            inputId={`${inputId}_address`}
            name={subInputName}
          />
        )}
      </div>
    </InputBase>
  );
};

InnerApReportFormInputSpecialExceptionStatusSelect.displayName =
  'InnerApReportFormInputSpecialExceptionStatusSelect';

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export const ApReportFormInputSpecialExceptionStatusSelect = memo((props) => (
  <InnerApReportFormInputSpecialExceptionStatusSelect {...props} />
)) as typeof InnerApReportFormInputSpecialExceptionStatusSelect;

ApReportFormInputSpecialExceptionStatusSelect.displayName =
  'ApReportFormInputSpecialExceptionStatusSelect';
