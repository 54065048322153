import { typeGuardReportSpecialExceptionStatus } from '@/context/services/reportsType/invoiceReports/type-guard';
import { InvoiceReportDetailFormInputsResponse } from 'ap-openapi';
import { stringToDayjs } from 'date-util';
import { Dayjs } from 'dayjs';

export const valueOrValues = <T>(
  item: (T | undefined)[] | T | undefined
): T | T[] | undefined => {
  if (item === undefined) return undefined;
  if (Array.isArray(item)) {
    const v = item.filter((i): i is Exclude<T, undefined> => i !== undefined);
    if (v.length === 0) return undefined;
    if (v.length === 1) return v[0];
    return v;
  }
  return item;
};

const multipleValue = (cur: InvoiceReportDetailFormInputsResponse) => {
  const v = valueOrValues(cur.input_values?.map((item) => item.value));
  if (v === undefined) return [];
  if (!Array.isArray(v)) return [v];
  return v;
};

export const mappingTypeValue: Record<
  string,
  (
    cur: InvoiceReportDetailFormInputsResponse
  ) => string | Dayjs | string[] | Dayjs[] | undefined
> = {
  ApReportFormInputSpecialExceptionStatus: (
    cur: InvoiceReportDetailFormInputsResponse
  ): string | string[] | undefined => {
    if (
      typeGuardReportSpecialExceptionStatus(cur.input_additional_values?.[0]) &&
      cur.input_additional_values?.[0].ap_special_exception_status === null
    ) {
      return '0';
    }
    return valueOrValues(cur.input_values?.map((item) => item.value));
  },
  ApReportFormInputSelect: multipleValue,
  ApReportFormInputCheckBox: multipleValue,
  ApReportFormInputDateField: (cur: InvoiceReportDetailFormInputsResponse) =>
    valueOrValues(cur.input_values?.map((item) => stringToDayjs(item.value))) ||
    stringToDayjs(cur.default_value),
  ApReportFormInputTimeField: (cur: InvoiceReportDetailFormInputsResponse) =>
    valueOrValues(cur.input_values?.map((item) => stringToDayjs(item.value))) ||
    stringToDayjs(cur.default_value),
};
