import dayjs, { Dayjs, isDayjs } from 'dayjs';

// NOTICE: 表で縦に並んだ時に幅が揃って比較しやすいため0パディングを付けたフォーマットにする
export const DATE_FORMAT = 'YYYY/MM/DD';
export const DATE_FORMAT_JA = 'YYYY年MM月DD日';
export const DATE_FORMAT_HYPHEN = 'YYYY-MM-DD';
export const DATE_FORMAT_WITH_WEEK_JA = `${DATE_FORMAT_JA} (dd)`;
const TIME_FORMAT = 'HH:mm';
export const DATE_FORMAT_WITH_TIME = `${DATE_FORMAT} ${TIME_FORMAT}`;
export const DATE_FORMAT_JA_WITH_TIME = `${DATE_FORMAT_JA} ${TIME_FORMAT}`;
export const DATE_FORMAT_WITH_NO_SLASH = 'YYYYMMDD';
export const DATE_FORMAT_WITH_TIME_AND_NO_SLASH = 'YYYYMMDDHHmm';

type TDateParam = Dayjs | string | undefined | null;

const _formatDate = (date: TDateParam, format: string) => {
  if (!date) return '';

  // Invalid Date防止 (パース可能であれば存在しない日付もvalidになる)
  const _date = isDayjs(date) ? date : dayjs(date);
  if (!_date.isValid()) return '';

  return _date.format(format);
};

export const formatDateWithWeek = (date: TDateParam): string =>
  _formatDate(date, DATE_FORMAT_WITH_WEEK_JA);

export const formatDateWithTime = (date: TDateParam): string =>
  _formatDate(date, DATE_FORMAT_WITH_TIME);

export const getCurrentDate = (): Dayjs => dayjs();

export const getDayjsDate = (date: string): Dayjs => dayjs(date, DATE_FORMAT);

export const getPrevMonth = (day: Dayjs): Dayjs =>
  dayjs(day).subtract(1, 'month');

export const getNextMonth = (day: Dayjs): Dayjs => dayjs(day).add(1, 'month');

export const getStartDateInMonth = (day: Dayjs): Dayjs =>
  dayjs(day).startOf('month');

export const getEndDateInMonth = (day: Dayjs): Dayjs =>
  dayjs(day).endOf('month');

// convert to ISO_8601 date string
// @refs: https://ja.wikipedia.org/wiki/ISO_8601
export const toISODateString = (date: Dayjs | string): string =>
  isDayjs(date) ? date.format('YYYY-MM-DD') : '';

export const dateToDayjs = (date: Date) => dayjs(date);

export const format = (date: TDateParam, format: string): string =>
  _formatDate(date, format);

export const toISODateStrings = (dates: (Dayjs | string)[]): string[] => {
  return dates.map((date) => toISODateString(date));
};

export const stringToDayjs = (
  date: string | null | undefined
): Dayjs | undefined => {
  if (date === undefined) return undefined;
  if (date === null) return undefined;
  for (const format of [
    DATE_FORMAT,
    DATE_FORMAT_HYPHEN,
    DATE_FORMAT_WITH_NO_SLASH,
    DATE_FORMAT_WITH_TIME,
  ]) {
    const v = dayjs(date, format);
    if (isDayjs(v) && v.isValid()) {
      return v;
    }
  }
  const convertValue = dayjs(date);
  return convertValue.isValid() ? convertValue : undefined;
};
