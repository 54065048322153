import { FileNameSeparatorWithExtension } from '@/components/FileNameSeparatorWithExtension';
import { Card, IconClose } from '@moneyforward/ap-frontend-components';
import classnames from 'classnames/bind';
import { FC, memo } from 'react';
import styles from './CardFileList.module.scss';
const cx = classnames.bind(styles);

export type Props = {
  size: 'xs' | 'sm' | 'md' | 'lg';
  tag: string;
  title: string;
  selected: boolean;
  onButtonClick: VoidFunction;
  onCardClick: VoidFunction;
};

export const CardFileList: FC<Props> = memo(
  ({ size, tag, title, selected, onButtonClick, onCardClick }) => {
    return (
      <div className={cx(styles['container'])}>
        <Card
          size={size}
          tag={tag}
          title={
            <FileNameSeparatorWithExtension>
              {title}
            </FileNameSeparatorWithExtension>
          }
          selected={selected}
          icon={<IconClose size={18} />}
          onButtonClick={onButtonClick}
          onCardClick={onCardClick}
        />
      </div>
    );
  }
);
CardFileList.displayName = 'CardFileList';
