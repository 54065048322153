import { createSelectItemContext } from '@/context/SelectComponent/Base';
import {
  CrSubItems,
  GetCrSubItemsByCrItemResponse,
  getGetCrSubItemsByCrItemQueryKey,
  useGetCrSubItemsByCrItemInfinite,
} from 'ap-openapi';
import { FC, PropsWithChildren, memo } from 'react';

export const {
  useFetchPer: useCrSubItemPer,
  useItems: useCrSubItems,
  useSetItems: useSetCrSubItems,
  useCacheKey: useCrSubItemCacheKey,
  useIsLoading: useIsLoadingCrSubItems,
  useIsFetching: useIsFetchingCrSubItems,
  useFindById: useFindCrSubItemById,
  useFindByName: useFindCrSubItemByName,
  Provider: BaseCrSubItemsProvider,
} = createSelectItemContext<
  CrSubItems,
  GetCrSubItemsByCrItemResponse,
  'id',
  'name',
  'name',
  'id'
>(
  100,
  useGetCrSubItemsByCrItemInfinite,
  'cr_sub_items',
  'id',
  {
    key: 'id',
    return: 'name',
    defaultVal: '',
  },
  {
    key: 'name',
    return: 'id',
    defaultVal: '',
  },
  getGetCrSubItemsByCrItemQueryKey
);

export const CrSubItemsProvider: FC<PropsWithChildren> = memo(
  ({ children }) => {
    return <BaseCrSubItemsProvider>{children}</BaseCrSubItemsProvider>;
  }
);
CrSubItemsProvider.displayName = 'CrSubItemsProvider';
