import { createSelectItemContext } from '@/context/SelectComponent/Base';
import {
  ExItem,
  GetExItemsResponse,
  getGetExItemsQueryKey,
  useGetExItemsInfinite,
} from 'ap-openapi';
import { FC, PropsWithChildren, memo } from 'react';

export const {
  useFetchPer: useExItemPer,
  useItems: useExItems,
  useSetItems: useSetExItems,
  useCacheKey: useExItemCacheKey,
  useIsLoading: useIsLoadingExItems,
  useIsFetching: useIsFetchingExItems,
  useFindById: useFindExItemById,
  useFindByName: useFindExItemByName,
  Provider: BaseExItemProvider,
} = createSelectItemContext<
  ExItem,
  GetExItemsResponse,
  'id',
  'name',
  'name',
  'id'
>(
  100,
  useGetExItemsInfinite,
  'ex_items',
  'id',
  {
    key: 'id',
    return: 'name',
    defaultVal: '',
  },
  {
    key: 'name',
    return: 'id',
    defaultVal: '',
  },
  getGetExItemsQueryKey
);

export const ExItemProvider: FC<PropsWithChildren> = memo(({ children }) => {
  return <BaseExItemProvider>{children}</BaseExItemProvider>;
});

ExItemProvider.displayName = 'ExItemProvider';

// const exItemsContext = createContext<ExItem[]>([]);
// const isLoadingExItemsContext = createContext<boolean>(true);
// const setExItemsContext = createContext<(items: ExItem[]) => void>(() => {});
// const bookDateContext = createContext<Dayjs | undefined>(undefined);
// const setBookDateContext = createContext<(bookDate: Dayjs | undefined) => void>(
//   () => {}
// );

// export const ExItemProvider: FC<PropsWithChildren> = memo(({ children }) => {
//   const [items, setItems] = useState<ExItem[]>([]);

//   // TODO: Set bookDate if ApReportFormInputInvoiceBookDateField's value updated
//   const [bookDate, setBookDate] = useState<Dayjs | undefined>(undefined);
//   const { data, error, isLoading } = useGetExItems({});
//   const mappings = useReversedMappingContext();
//   useEffect(() => {
//     if (!isLoading) {
//       setItems(
//         getUpdatedDrExciseIdInExItems({
//           exItems: data?.data?.ex_items ?? [],
//           mappings,
//           bookDate,
//         })
//       );
//     }
//   }, [data, isLoading, mappings, bookDate]);
//   if (error) throw error;
//   return <BaseProvider>{children}</BaseProvider>;
// });
