import { createSelectItemContext } from '@/context/SelectComponent/Base';
import {
  GetProjectCodesResponse,
  ProjectCode,
  getGetProjectCodesQueryKey,
  useGetProjectCodesInfinite,
} from 'ap-openapi';
import { FC, PropsWithChildren, memo } from 'react';

export const {
  useFetchPer: useProjectPer,
  useItems: useProject,
  useSetItems: useSetProject,
  useCacheKey: useProjectCacheKey,
  useIsLoading: useIsLoadingProject,
  useIsFetching: useIsFetchingProject,
  useFindById: useFindProjectById,
  useFindByName: useFindProjectByName,
  Provider: BaseProjectProvider,
} = createSelectItemContext<
  ProjectCode,
  GetProjectCodesResponse,
  'id',
  'name',
  'name',
  'id'
>(
  100,
  useGetProjectCodesInfinite,
  'project_codes',
  'id',
  {
    key: 'id',
    return: 'name',
    defaultVal: '',
  },
  {
    key: 'name',
    return: 'id',
    defaultVal: '',
  },
  getGetProjectCodesQueryKey
);

export const ProjectProvider: FC<PropsWithChildren> = memo(({ children }) => {
  return <BaseProjectProvider>{children}</BaseProjectProvider>;
});

ProjectProvider.displayName = 'ProjectProvider';
