import { CHARACTERS_FOR_ACCOUNT_NAME } from '@/consts/validation';
import { useTranslation } from '@/i18n';
import {
  FormInput,
  FormInputProps,
} from '@moneyforward/ap-frontend-components';
import { ReactNode, memo, useMemo } from 'react';
import { FieldValues } from 'react-hook-form';
import { InstantPayeeInputBase } from '../InstantPayeeInputBase/InstantPayeeInputBase';

export type Props<TFieldValues extends FieldValues> = {
  required?: boolean;
  className?: string;
  caption?: ReactNode;
  tooltip?: ReactNode;
} & FormInputProps<TFieldValues>;

const InnerInstantPayeeNameHolderKanaForm = <TFieldValues extends FieldValues>({
  control,
  name,
  inputId,
  required = false,
  ...rest
}: Props<TFieldValues>) => {
  const { t } = useTranslation();

  const rules: { required: string | boolean } = useMemo(
    () => ({
      required: required ? t('required') : false,
      pattern: {
        value: CHARACTERS_FOR_ACCOUNT_NAME.pattern,
        message: CHARACTERS_FOR_ACCOUNT_NAME.message,
      },
    }),
    [required, t]
  );
  const inputProps = useMemo(
    () => ({
      ...rest.inputProps,
      placeholder: t('payee_name_kana_placeholder'),
    }),
    [rest.inputProps, t]
  );
  const labelProps = useMemo(() => ({ children: '名義人カナ' }), []);

  return (
    <InstantPayeeInputBase labelProps={labelProps}>
      <FormInput<TFieldValues>
        inputId={inputId}
        rules={rules}
        name={name}
        control={control}
        inputProps={inputProps}
        {...rest}
      />
    </InstantPayeeInputBase>
  );
};

InnerInstantPayeeNameHolderKanaForm.displayName =
  'InnerInstantPayeeNameHolderKanaForm';

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export const InstantPayeeNameHolderKanaForm = memo((props) => (
  <InnerInstantPayeeNameHolderKanaForm {...props} />
)) as typeof InnerInstantPayeeNameHolderKanaForm;
InstantPayeeNameHolderKanaForm.displayName = 'InstantPayeeNameHolderKanaForm';
