import { ImportApCurrencyRatesBody } from '../../openapi/model';
import { BulkUploadBusinessDocumentsRequest } from '../../openapi/model/bulkUploadBusinessDocumentsRequest';
import {
  CustomUpdateReportRequest,
  convertRequestBodyToFormData,
} from './requests/update_report_form_request';

const isBulkUploadFormRequest = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  body: any
): body is BulkUploadBusinessDocumentsRequest => {
  return (
    'files' in body &&
    'do_ai_ocr' in body &&
    'document_type' in body &&
    'receipt_type' in body
  );
};

const isUpdateReportFormRequest = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  body: any
): body is CustomUpdateReportRequest => {
  return 'report' in body;
};
const isSingleFileFormRequest = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  body: any
): body is ImportApCurrencyRatesBody => {
  return 'file' in body;
};

export const customFomDataFn = <Body>(body: Body): FormData => {
  const formData = new FormData();

  if (isBulkUploadFormRequest(body)) {
    if (body.files.length > 0) {
      body.files.forEach((value, idx) =>
        formData.append(`files[${idx}]`, value)
      );
    }

    formData.append('do_ai_ocr', body.do_ai_ocr.toString());
    formData.append('document_type', body.document_type);
    formData.append('receipt_type', body.receipt_type);
  } else if (isSingleFileFormRequest(body)) {
    if (body.file) {
      formData.append('file', body.file);
    }
  }

  if (isUpdateReportFormRequest(body)) {
    convertRequestBodyToFormData(body, formData);
  }

  return formData;
};
